import React, { useEffect, useState, useRef } from "react";
import classNames from 'classnames';
import useOnScreen from "../../../hooks/useOnScreen";
import styles from './WellbeingEcosystem.module.scss';
export default function WellbeingEcosystem() {

    const ref = useRef(null);

    const [reveal, setReveal] = useState(false);
    const onScreen = useOnScreen(ref);

    useEffect(() => {
        if (onScreen) setReveal(onScreen);
    }, [onScreen]);
    return (
        <div data-scroll-section>
            <section className={styles.wellbeingEcosystemBanner}>
                <div className='container'>
                    <div className={styles.grid}>
                        <div className={styles.gridItems}>
                            <h6 data-scroll data-scroll-speed="1">wellbeing</h6>
                            <h1 className="c-header_title o-h1" data-scroll data-scroll-speed="2" data-scroll-repeat>
                                <span className={classNames("c-header_title_line", { "is-reveal": reveal })}>
                                    <span data-scroll data-scroll-speed="3" data-scroll-position="top" data-scroll-repeat>Creating a </span>
                                    <span className="italic-class" data-scroll data-scroll-speed="1" data-scroll-position="top" data-scroll-repeat>Wellbeing Ecosystem</span>
                                </span>
                            </h1>
                            <p data-scroll data-scroll-speed="1">The types of wellbeing elements within LRC’s properties are varied.</p>
                            <h3 data-scroll data-scroll-speed="1">wellbeing</h3>
                        </div>
                        <div className={styles.gridItems}>
                            <div className={styles.subGrid}>
                                <div className={styles.subGridItems}>
                                    <h2 className="c-header_title o-h1" data-scroll data-scroll-speed="2" data-scroll-repeat>
                                        <span className={classNames("c-header_title_line", { "is-reveal": reveal })}>
                                            <span data-scroll data-scroll-speed="3" data-scroll-position="top" data-scroll-repeat>Events</span>
                                        </span>
                                    </h2>

                                    <p data-scroll data-scroll-speed="2">
                                        Social gatherings, shared meals, fitness classes,
                                        artistic workshops and seasonal events.
                                    </p>
                                </div>
                                <div className={styles.subGridItems}>
                                    <h2 className="c-header_title o-h1" data-scroll data-scroll-speed="2" data-scroll-repeat>
                                        <span className={classNames("c-header_title_line", { "is-reveal": reveal })}>
                                            <span data-scroll data-scroll-speed="3" data-scroll-position="top" data-scroll-repeat>Stay Well™ by Delos</span>
                                        </span>
                                    </h2>
                                    <p data-scroll data-scroll-speed="2">
                                        Air purification, aromatherapy and other wellness technologies in communal spaces and flats.
                                    </p>
                                </div>
                                <div className={styles.subGridItems}>
                                    <h2 className="c-header_title o-h1" data-scroll data-scroll-speed="2" data-scroll-repeat>
                                        <span className={classNames("c-header_title_line", { "is-reveal": reveal })}>
                                            <span data-scroll data-scroll-speed="3" data-scroll-position="top" data-scroll-repeat>App</span>
                                        </span>
                                    </h2>
                                    <p data-scroll data-scroll-speed="2">
                                        The Vonder App allows tenants to consume wellbeing content and lifestyles.
                                    </p>
                                </div>
                                <div className={styles.subGridItems}>
                                    <h2 className="c-header_title o-h1" data-scroll data-scroll-speed="2" data-scroll-repeat>
                                        <span className={classNames("c-header_title_line", { "is-reveal": reveal })}>
                                            <span data-scroll data-scroll-speed="3" data-scroll-position="top" data-scroll-repeat>Rewards</span>
                                        </span>
                                    </h2>
                                    <p data-scroll data-scroll-speed="2">
                                        Vonder’s rewards scheme presents exclusive wellbeing deals and offerings.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
