import React, { useEffect, useState, useRef } from "react";
import classNames from "classnames";
import useOnScreen from "../../../hooks/useOnScreen";
import styles from './NewsInshights.module.scss';
import CardImage from '../../../Assets/Images/mipim-image.png';
import CardImage1 from '../../../Assets/Images/news0.png';
import CardImage2 from '../../../Assets/Images/news1.png';
import { NavLink } from "react-router-dom";
export default function NewsInshights() {
    const ref = useRef(null);

    const [reveal, setReveal] = useState(false);
    const onScreen = useOnScreen(ref);

    useEffect(() => {
        if (onScreen) setReveal(onScreen);
    }, [onScreen]);
    return (
        <div data-scroll-section>
            <section className={styles.NewsInshightsBottomAlignment}>
                <div className='container'>
                    <div className={styles.pageTitle}>
                        <a>News & insights</a>
                        <h1
                            className="c-header_title o-h1"
                            data-scroll data-scroll-speed="0.5"
                        >
                            <span
                                className={classNames("c-header_title_line", {
                                    "is-reveal": reveal,
                                })}
                            >Latest N<span className='italic-class'>ews & Insights</span>
                            </span>
                        </h1>
                    </div>
                    <div className={styles.grid}>
                        <div className={styles.gridItems}>
                            <div className={styles.cardImage}>
                                <img src={CardImage} alt="CardImage" className="flipImage" data-scroll data-scroll-repeat />
                            </div>
                            <div className={styles.cardDetails}>
                                {/* <NavLink to="/newsdetails"> */}
                                <h3
                                    className="c-header_title o-h1"
                                    data-scroll data-scroll-speed="0.5" data-scroll-repeat
                                >
                                    <span
                                        className={classNames("c-header_title_line", {
                                            "is-reveal": reveal,
                                        })}
                                    >
                                        <span
                                            data-scroll
                                            data-scroll-speed="1"
                                            data-scroll-repeat
                                            data-scroll-position="top"
                                        >
                                            LRC will participate in MIPIM
                                        </span>
                                    </span>
                                </h3>
                                {/* </NavLink> */}
                                <p data-scroll data-scroll-speed="1">By <a>LRC Group</a> 02 March 2022</p>
                            </div>
                        </div>
                        <div className={styles.gridItems}>
                            <div className={styles.cardImage}>
                                <img src={CardImage1} alt="CardImage" className="flipImage" data-scroll data-scroll-repeat />
                            </div>
                            <div className={styles.cardDetails}>
                                {/* <NavLink to="/newsdetails"> */}
                                <h3
                                    className="c-header_title o-h1"
                                    data-scroll data-scroll-speed="0.5" data-scroll-repeat
                                >
                                    <span
                                        className={classNames("c-header_title_line", {
                                            "is-reveal": reveal,
                                        })}
                                    >
                                        <span
                                            data-scroll
                                            data-scroll-speed="1"
                                            data-scroll-repeat
                                            data-scroll-position="top"
                                        >
                                            LRC has acquired Chapel House in Leeds
                                        </span>
                                    </span>
                                </h3>
                                {/* </NavLink> */}
                                <p data-scroll data-scroll-speed="1">By <a>LRC Group</a> 02 March 2022</p>
                            </div>
                        </div>
                        <div className={styles.gridItems}>
                            <div className={styles.cardImage}>
                                <img src={CardImage2} alt="CardImage" className="flipImage" data-scroll data-scroll-repeat />
                            </div>
                            <div className={styles.cardDetails}>
                                {/* <NavLink to="/newsdetails"> */}
                                <h3
                                    className="c-header_title o-h1"
                                    data-scroll data-scroll-speed="0.5" data-scroll-repeat
                                >
                                    <span
                                        className={classNames("c-header_title_line", {
                                            "is-reveal": reveal,
                                        })}
                                    >
                                        <span
                                            data-scroll
                                            data-scroll-speed="1"
                                            data-scroll-repeat
                                            data-scroll-position="top"
                                        >
                                            LRC has acquired the Dome portfolio in London
                                        </span>
                                    </span>
                                </h3>
                                {/* </NavLink> */}
                                <p data-scroll data-scroll-speed="1">By <a>LRC Group</a> 02 March 2022</p>
                            </div>
                        </div>
                    </div>
                    {/* <div className={styles.viewMoreButton} data-scroll data-scroll-speed="1">
                        <NavLink to="/news-insights">
                            <button>
                                View All
                                <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.24473 0.412046C7.57016 0.0866095 8.0978 0.0866095 8.42324 0.412046L13.4232 5.41205C13.5795 5.56833 13.6673 5.78029 13.6673 6.0013C13.6673 6.22232 13.5795 6.43428 13.4232 6.59056L8.42324 11.5906C8.0978 11.916 7.57016 11.916 7.24473 11.5906C6.91929 11.2651 6.91929 10.7375 7.24473 10.412L10.8221 6.83464L1.16732 6.83464C0.70708 6.83464 0.333984 6.46154 0.333984 6.0013C0.333984 5.54107 0.70708 5.16797 1.16732 5.16797L10.8221 5.16797L7.24473 1.59056C6.91929 1.26512 6.91929 0.737483 7.24473 0.412046Z" fill="white" />
                                </svg>
                            </button>
                        </NavLink>
                    </div> */}
                </div>
            </section>
        </div>
    )
}
