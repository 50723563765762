import React from "react";
import Logo from "../../../Assets/Logo/footer_svg.svg";
import styles from "./Footer.module.scss";
import { NavLink } from "react-router-dom";
export default function Footer() {
  return (
    <div data-scroll-section>
      <footer>
        <div className="container">
          <div className={styles.footerGrid}>
            <div className={styles.footerGridItems}>
              <img src={Logo} alt="Logo" />
            </div>
            <div className={styles.footerGridItems}>
              <div className={styles.footerSubGrid}>
                <div className={styles.footerSubGridItems}>
                  <p>Menu</p>

                  <ul>
                    <li>
                      <NavLink to="/aboutus">About</NavLink>
                    </li>
                    <li>
                      <NavLink to="/expertise">Expertise</NavLink>
                    </li>
                    <li>
                      <NavLink to="/urbanregeneration">
                        Urban Regeneration
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/contact">Contact us</NavLink>
                    </li>
                  </ul>
                </div>
                <div className={styles.footerSubGridItems}>
                  <NavLink to="/urbanregeneration">
                    <p>Urban regeneration</p>
                  </NavLink>
                  <ul>
                    <li>
                      <NavLink to="/technology">Technology</NavLink>
                    </li>
                    <li>
                      {" "}
                      <NavLink to="/sustainability">Sustainability</NavLink>
                    </li>
                    <li>
                      {" "}
                      <NavLink to="/scale">Scale</NavLink>
                    </li>
                    <li>
                      <NavLink to="/community">Communities</NavLink>
                    </li>
                    <li>
                      {" "}
                      <NavLink to="/wellbeing">Wellbeing</NavLink>
                    </li>
                  </ul>
                </div>
                <div className={styles.footerSubGridItems}>
                  <p>social media</p>
                  <ul>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/lrcgroup/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        LinkedIn
                      </a>
                    </li>
                    {/* <li>Facebook</li>
                    <li>Instagram</li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.footerChild}>
            <ul>
              <li>
                <a
                  href="/disclaimer-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy & Cookie Policy
                </a>
              </li>
              <li>
                <a
                  href="/sfdr-disclosure"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  SFDR Disclosure
                </a>
              </li>
              <li>
                <a
                  href="/pai-statement"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  PAI Statement
                </a>
              </li>
              <li>© 2022 LRC Group.</li>
            </ul>
          </div>
        </div>
      </footer>
    </div>
  );
}
