import React, { useEffect, useState, useRef } from "react";
import classNames from "classnames";
import useOnScreen from "../../../hooks/useOnScreen";
import styles from './MaurisMaximus.module.scss';
export default function MaurisMaximus() {
    const ref = useRef(null);

    const [reveal, setReveal] = useState(false);
    const onScreen = useOnScreen(ref);

    useEffect(() => {
        if (onScreen) setReveal(onScreen);
    }, [onScreen]);
    return (
        <div data-scroll-section>
            <section className={styles.MaurisMaximusAlignment}>
                <div className='container'>
                    <div className={styles.grid}>
                        <div className={styles.gridItems}>
                            <div data-scroll data-scroll-speed="1">
                                <a>Corporate Diversity</a>
                            </div>
                            <h1
                                className="c-header_title o-h1"
                                data-scroll data-scroll-speed="1" data-scroll-repeat
                            >
                                <span
                                    className={classNames("c-header_title_line", {
                                        "is-reveal": reveal,
                                    })}
                                >
                                    <span
                                        data-scroll
                                        data-scroll-speed="1"
                                        data-scroll-repeat
                                        data-scroll-position="top"
                                    >
                                        An inclusive and diverse mindset leads to <span className="italic-class">sustainable action</span>
                                    </span>
                                </span>
                            </h1>
                            <p data-scroll data-scroll-speed="1">
                                For the LRC Group, a large part of corporate responsibility includes embracing diversity through the company’s workforce and community.
                            </p>

                        </div>
                        <div className={styles.gridItems}>
                            <div className={styles.childGrid}>
                                <div className={styles.childGridItems}>
                                    <h2
                                        className="c-header_title o-h1"
                                        data-scroll data-scroll-speed="1" data-scroll-repeat
                                    >
                                        <span
                                            className={classNames("c-header_title_line", {
                                                "is-reveal": reveal,
                                            })}
                                        >
                                            <span
                                                data-scroll
                                                data-scroll-speed="1"
                                                data-scroll-repeat
                                                data-scroll-position="top"
                                            >
                                                Diverse Team
                                            </span>
                                        </span>
                                    </h2>
                                    <p data-scroll data-scroll-speed="1">
                                        As a global company, LRC has a multicultural, international workforce.
                                    </p>
                                </div>
                                <div className={styles.childGridItems}>
                                    <h2
                                        className="c-header_title o-h1"
                                        data-scroll data-scroll-speed="1" data-scroll-repeat
                                    >
                                        <span
                                            className={classNames("c-header_title_line", {
                                                "is-reveal": reveal,
                                            })}
                                        >
                                            <span
                                                data-scroll
                                                data-scroll-speed="1"
                                                data-scroll-repeat
                                                data-scroll-position="top"
                                            >
                                                Company Culture
                                            </span>
                                        </span>
                                    </h2>
                                    <p data-scroll data-scroll-speed="1">
                                        LRC’s culture ensures the inclusion and empowerment of each employee.
                                    </p>
                                </div>
                                <div className={styles.childGridItems}>
                                    <h2
                                        className="c-header_title o-h1"
                                        data-scroll data-scroll-speed="1" data-scroll-repeat
                                    >
                                        <span
                                            className={classNames("c-header_title_line", {
                                                "is-reveal": reveal,
                                            })}
                                        >
                                            <span
                                                data-scroll
                                                data-scroll-speed="1"
                                                data-scroll-repeat
                                                data-scroll-position="top"
                                            >
                                                Initiatives
                                            </span>
                                        </span>
                                    </h2>
                                    <p data-scroll data-scroll-speed="1">
                                        Each department and office globally set quarterly KPIs which include sustainable actions.
                                    </p>
                                </div>
                                <div className={styles.childGridItems}>
                                    <h2
                                        className="c-header_title o-h1"
                                        data-scroll data-scroll-speed="1" data-scroll-repeat
                                    >
                                        <span
                                            className={classNames("c-header_title_line", {
                                                "is-reveal": reveal,
                                            })}
                                        >
                                            <span
                                                data-scroll
                                                data-scroll-speed="1"
                                                data-scroll-repeat
                                                data-scroll-position="top"
                                            >
                                                Impact
                                            </span>
                                        </span>
                                    </h2>
                                    <p data-scroll data-scroll-speed="1">
                                        LRC aims to impact not only its workforce, but its community as a whole.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
