import React, { useEffect, useState, useRef } from "react";
import styles from './MissionAndVision.module.scss';
import MissionImage from '../../../Assets/Images/new-image.png';
import VissionImage from '../../../Assets/Images/child-image.png';
import classNames from 'classnames';
import useOnScreen from "../../../hooks/useOnScreen";
export default function MissionAndVision() {
    const ref = useRef(null);

    const [reveal, setReveal] = useState(false);
    const onScreen = useOnScreen(ref);

    useEffect(() => {
        if (onScreen) setReveal(onScreen);
    }, [onScreen]);
    return (

        <div data-scroll-section>
            <section className={styles.missinNewAlignment}>
                <div className="container">
                    <div className={styles.newTextGrid}>
                        <div className={styles.newTextGridItems}>
                            <h1>The Group’s <span className="italic-class">Mission & Vision</span></h1>

                        </div>
                        <div className={styles.newTextGridItems}>
                            <a>Introduction</a>
                        </div>
                    </div>
                </div>
                <div className={styles.leftAlignmentContent}>
                    <div className={styles.secGrid}>
                        <div className={styles.secGridItems}>
                            <p>
                                With over 25 years of experience as real estate investment managers, LRC Group provides a fresh take on the concept of real estate
                                by maximising the benefits of scale and technology to remove friction for both landlords and tenants.
                            </p>
                            <div className={styles.mobileTextShow}>
                                <a>Introduction</a>
                            </div>
                            <div className={styles.fullImageDesign}>
                                <img src={MissionImage} alt="MissionImage" className="flipImage" data-scroll data-scroll-repeat />
                            </div>
                        </div>
                        <div className={styles.secGridItems}>
                            <div className={styles.childImageDesign}>
                                <img src={VissionImage} alt="VissionImage" className="flipImage" data-scroll data-scroll-repeat />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className={styles.mobileviewSectionShow}>
                <div className={styles.MobileViewImageFirst}>
                    <img src={VissionImage} alt="VissionImage" className="flipImage" data-scroll data-scroll-repeat />
                </div>
                <div className="container">
                    <div className={styles.fullImageBoxAlign}>
                        <img src={MissionImage} alt="MissionImage" className="flipImage" data-scroll data-scroll-repeat />
                    </div>
                </div>
            </section>
            {/* <section className={styles.missionAndVisonAlignment}>
                <div className={classNames(styles.containerSpaceRemove, "container")}>
                    <div className={styles.grid}>
                        <div className={styles.gridItems}>
                            <div className={styles.mobileViewAlignSpace}>
                                <h1
                                    className="c-header_title o-h1"
                                    data-scroll data-scroll-speed="1" data-scroll-position="top" data-scroll-repeat
                                >
                                    <span
                                        className={classNames("c-header_title_line", {
                                            "is-reveal": reveal,
                                        })}
                                    >
                                        <span
                                            data-scroll
                                            data-scroll-speed="1"
                                            data-scroll-repeat
                                            data-scroll-position="top"
                                        >
                                            The Group’s{" "}
                                        </span>
                                        <span className="italic-class" data-scroll data-scroll-speed="1" data-scroll-position="top" data-scroll-repeat>Mission & Vision</span>
                                    </span>
                                </h1>
                                <p data-scroll data-scroll-speed="1">
                                    Real estate is one of the last remaining industries of the old-world economy - and yet, it is still one of the biggest. With over 25 years of experience,
                                </p>
                                <p data-scroll data-scroll-speed="1">
                                    we provide a fresh take on the concept of real estate by maximising the benefits of scale and technology to remove frictions for both landlords and tenants.

                                </p>
                            </div>
                            <div className={classNames(styles.missionMobileNone, styles.childImage)}>
                                <img src={VissionImage} className="flipImage" alt="VissionImage" data-scroll
                                    data-scroll-repeat />
                            </div>
                        </div>
                        <div className={styles.gridItems}>
                            <a data-scroll data-scroll-direction="horizontal" data-scroll-speed="1" data-scroll-position="top">Introduction</a>
                            <div className={styles.missionImage}>
                                <img src={MissionImage} className="flipImage" alt="MissionImage" data-scroll data-scroll-repeat />
                            </div>
                        </div>
                    </div>
                    <div className={classNames(styles.missionMobileShowImage, styles.childImage)}>
                        <img src={VissionImage} alt="VissionImage" data-scroll
                            data-scroll-repeat />
                    </div>
                </div>
            </section> */}
            <section className={styles.LogoBanner}>
                <div className={styles.Marquee} >
                    <div className={styles.AlignmentContent}>
                        <div>
                            <h1>London</h1>
                        </div>
                        <div>
                            <h1>Berlin</h1>
                        </div>
                        <div>
                            <h1>Dubai</h1>
                        </div>
                        <div>
                            <h1>Dublin</h1>
                        </div>
                        <div>
                            <h1>Warsaw</h1>
                        </div>
                        <div>
                            <h1>Luxembourg</h1>
                        </div>
                        <div>
                            <h1>Malta</h1>
                        </div>
                        <div>
                            <h1>Cyprus</h1>
                        </div>
                        <div>
                            <h1>London</h1>
                        </div>
                        <div>
                            <h1>Berlin</h1>
                        </div>
                        <div>
                            <h1>Dubai</h1>
                        </div>
                        <div>
                            <h1>Dublin</h1>
                        </div>
                        <div>
                            <h1>Warsaw</h1>
                        </div>
                        <div>
                            <h1>Luxembourg</h1>
                        </div>
                        <div>
                            <h1>Malta</h1>
                        </div>
                        <div>
                            <h1>Cyprus</h1>
                        </div>

                        <div>
                            <h1>London</h1>
                        </div>
                        <div>
                            <h1>Berlin</h1>
                        </div>
                        <div>
                            <h1>Dubai</h1>
                        </div>
                        <div>
                            <h1>Dublin</h1>
                        </div>
                        <div>
                            <h1>Warsaw</h1>
                        </div>
                        <div>
                            <h1>Luxembourg</h1>
                        </div>
                        <div>
                            <h1>Malta</h1>
                        </div>
                        <div>
                            <h1>Cyprus</h1>
                        </div>

                        <div>
                            <h1>London</h1>
                        </div>
                        <div>
                            <h1>Berlin</h1>
                        </div>
                        <div>
                            <h1>Dubai</h1>
                        </div>
                        <div>
                            <h1>Dublin</h1>
                        </div>
                        <div>
                            <h1>Warsaw</h1>
                        </div>
                        <div>
                            <h1>Luxembourg</h1>
                        </div>
                        <div>
                            <h1>Malta</h1>
                        </div>
                        <div>
                            <h1>Cyprus</h1>
                        </div>

                        <div>
                            <h1>London</h1>
                        </div>
                        <div>
                            <h1>Berlin</h1>
                        </div>
                        <div>
                            <h1>Dubai</h1>
                        </div>
                        <div>
                            <h1>Dublin</h1>
                        </div>
                        <div>
                            <h1>Warsaw</h1>
                        </div>
                        <div>
                            <h1>Luxembourg</h1>
                        </div>
                        <div>
                            <h1>Malta</h1>
                        </div>
                        <div>
                            <h1>Cyprus</h1>
                        </div>

                        <div>
                            <h1>London</h1>
                        </div>
                        <div>
                            <h1>Berlin</h1>
                        </div>
                        <div>
                            <h1>Dubai</h1>
                        </div>
                        <div>
                            <h1>Dublin</h1>
                        </div>
                        <div>
                            <h1>Warsaw</h1>
                        </div>
                        <div>
                            <h1>Luxembourg</h1>
                        </div>
                        <div>
                            <h1>Malta</h1>
                        </div>
                        <div>
                            <h1>Cyprus</h1>
                        </div>

                        <div>
                            <h1>London</h1>
                        </div>
                        <div>
                            <h1>Berlin</h1>
                        </div>
                        <div>
                            <h1>Dubai</h1>
                        </div>
                        <div>
                            <h1>Dublin</h1>
                        </div>
                        <div>
                            <h1>Warsaw</h1>
                        </div>
                        <div>
                            <h1>Luxembourg</h1>
                        </div>
                        <div>
                            <h1>Malta</h1>
                        </div>
                        <div>
                            <h1>Cyprus</h1>
                        </div>

                        <div>
                            <h1>London</h1>
                        </div>
                        <div>
                            <h1>Berlin</h1>
                        </div>
                        <div>
                            <h1>Dubai</h1>
                        </div>
                        <div>
                            <h1>Dublin</h1>
                        </div>
                        <div>
                            <h1>Warsaw</h1>
                        </div>
                        <div>
                            <h1>Luxembourg</h1>
                        </div>
                        <div>
                            <h1>Malta</h1>
                        </div>
                        <div>
                            <h1>Cyprus</h1>
                        </div>

                    </div>
                </div>
            </section>
        </div>


    );
}
