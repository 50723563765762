import React, { useEffect, useState, useRef } from "react";
import styles from './FamilyOfFunds.module.scss';
import CardImage from '../../../Assets/Images/new-card.png';
import CardSecImage from '../../../Assets/Images/france.png';
import CardThirdImage from '../../../Assets/Images/card-three.png';
import CardForthImage from '../../../Assets/Images/poland.png';
import classNames from 'classnames';
import useOnScreen from "../../../hooks/useOnScreen";
export default function FamilyOfFunds() {
  const ref = useRef(null);

  const [reveal, setReveal] = useState(false);
  const onScreen = useOnScreen(ref);

  useEffect(() => {
    if (onScreen) setReveal(onScreen);
  }, [onScreen]);
  return (
    <div data-scroll-section>
      <section className={styles.familyforFundsSectionAlignment}>
        <div className='container'>
          <div className={styles.pageTitle}>
            <div>

              <h1
                className="c-header_title o-h1"
                data-scroll data-scroll-speed="0.5" data-scroll-repeat
              >
                <span
                  className={classNames("c-header_title_line", {
                    "is-reveal": reveal,
                  })}
                >
                  <span
                    data-scroll
                    data-scroll-speed="1"
                    data-scroll-repeat
                    data-scroll-position="top"

                  >
                    Management of{" "}
                  </span>
                  <span data-scroll data-scroll-speed="1" data-scroll-position="top" data-scroll-repeat className="italic-class">Diversified Funds</span>
                </span>
              </h1>
            </div>

            <div data-scroll data-scroll-speed="1">
              <a>Portfolio</a>
            </div>
            <div data-scroll data-scroll-speed="1">
              <p>LRC holds a Fund Management Licence with the{" "}<a href="https://www.mfsa.mt/financial-services-register/" target="_blank" rel="noopener noreferrer">AIFM</a>.</p>
            </div>
          </div>
          <div className={styles.grid}>
            <div className={styles.gridItems}>
              <div className={styles.cardImages}>
                <img src={CardImage} alt="CardImage" className="flipImage" data-scroll data-scroll-repeat />
              </div>
              <div className={styles.cardDetails}>
                <h2
                  className="c-header_title o-h1"
                  data-scroll data-scroll-speed="0.5" data-scroll-repeat
                >
                  <span
                    className={classNames("c-header_title_line", {
                      "is-reveal": reveal,
                    })}
                  >
                    <span
                      data-scroll
                      data-scroll-speed="1"
                      data-scroll-repeat
                      data-scroll-position="top"

                    >
                      Residential
                    </span>
                  </span>
                </h2>
                {/* <h2>RE-1 <span>€700m</span></h2> */}
                <div data-scroll data-scroll-speed="1">
                  <p>
                    2,200 apartments, bringing under-utilised accommodation back onto the market.
                  </p>
                  <a>Ireland</a>
                </div>
              </div>
            </div>
            <div className={styles.gridItems}>
              <div className={styles.cardImages}>
                <img src={CardSecImage} alt="CardSecImage" className="flipImage" data-scroll data-scroll-repeat />
              </div>
              <div className={styles.cardDetails}>
                <h2
                  className="c-header_title o-h1"
                  data-scroll data-scroll-speed="0.5" data-scroll-repeat
                >
                  <span
                    className={classNames("c-header_title_line", {
                      "is-reveal": reveal,
                    })}
                  >
                    <span
                      data-scroll
                      data-scroll-speed="1"
                      data-scroll-repeat
                      data-scroll-position="top"

                    >
                      Pan-European Offices
                    </span>
                  </span>
                </h2>
                {/* <h2>RE-2 <span>€420m</span></h2> */}
                <div data-scroll data-scroll-speed="1">
                  <p>
                    80,000 sqm of high-quality office buildings leased to long-term corporate partners.
                  </p>
                  <a>Ireland & France</a>
                </div>
              </div>
            </div>
            <div className={styles.gridItems}>
              <div className={styles.cardImages}>
                <img src={CardThirdImage} alt="CardThirdImage" className="flipImage" data-scroll data-scroll-repeat />
              </div>
              <div className={styles.cardDetails}>
                {/* <h2>RE-3<span>€900m</span></h2> */}
                <h2
                  className="c-header_title o-h1"
                  data-scroll data-scroll-speed="0.5" data-scroll-repeat
                >
                  <span
                    className={classNames("c-header_title_line", {
                      "is-reveal": reveal,
                    })}
                  >
                    <span
                      data-scroll
                      data-scroll-speed="1"
                      data-scroll-repeat
                      data-scroll-position="top"

                    >
                      Residential
                    </span>
                  </span>
                </h2>
                <div data-scroll data-scroll-speed="1">
                  <p>
                    One of the largest PRS platforms in the UK. By the end of 2024, the fund will own over 4,500 units.
                  </p>
                  <a>United Kingdom</a>
                </div>
              </div>
            </div>
            <div className={styles.gridItems}>
              <div className={styles.cardImages}>
                <img src={CardForthImage} alt="CardForthImage" className="flipImage" data-scroll data-scroll-repeat />
              </div>
              <div className={styles.cardDetails}>
                <h2
                  className="c-header_title o-h1"
                  data-scroll data-scroll-speed="0.5" data-scroll-repeat
                >
                  <span
                    className={classNames("c-header_title_line", {
                      "is-reveal": reveal,
                    })}
                  >
                    <span
                      data-scroll
                      data-scroll-speed="1"
                      data-scroll-repeat
                      data-scroll-position="top"

                    >
                      Residential
                    </span>
                  </span>
                </h2>
                {/* <h2>RE-4<span>€900m</span></h2> */}
                <div data-scroll data-scroll-speed="1">
                  <p >
                    One of the largest PRS platforms in Warsaw. By 2025, the fund will own over 3,000 units.
                  </p>
                  <a >Poland</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
