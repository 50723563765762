import React, { useEffect, useState, useRef } from "react";
import classNames from 'classnames';
import useOnScreen from "../../../hooks/useOnScreen";
import styles from './SpaceEnvironments.module.scss';
import GrouppxdelosImage from '../../../Assets/Images/grouppxdelos.png';
export default function SpaceEnvironments() {
    const ref = useRef(null);

    const [reveal, setReveal] = useState(false);
    const onScreen = useOnScreen(ref);

    useEffect(() => {
        if (onScreen) setReveal(onScreen);
    }, [onScreen]);
    return (
        <div data-scroll-section>
            <section className={styles.spaceEnvironmentsAlignment}>
                <div className='container'>
                    <div className={styles.textGrid}>
                        <div className={styles.textGridItems}>
                            <h1>Creating better, healthier spaces & environments</h1>

                        </div>
                        <div className={styles.textGridItems} >
                            <p>
                                Tenant wellbeing is a priority within LRC projects. Offering spaces which support personal growth and development has been key to the company’s strategy. Integrating wellbeing platforms and technologies within the Group’s properties offer a glimpse into the power of property innovation.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className={styles.lrcGroupAlignBox}>
                <div className='container'>
                    <div className={styles.grid}>
                        <div className={styles.gridItems}>
                            <img src={GrouppxdelosImage} alt="GrouppxdelosImage" className="flipImage" data-scroll data-scroll-repeat />
                        </div>
                        <div className={styles.gridItems}>
                            <div > <a>lrc group x delos</a></div>
                            <h1>LRC partnered with Delos to promote the Stay Well™ program</h1>

                            <div >
                                <p>
                                    Delos is a wellness real estate and technology company guided by the mission to be the world’s leading catalyst for improving the health and well-being of people around the world by improving the indoor environments where they live, work, sleep and play.
                                </p>
                                <p>
                                    Stay Well™, developed by Delos, offers evidence-based health and wellness features and programming in hotels and residential projects around the world designed to help guests and tenants maintain their health and well-being while on the road.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
