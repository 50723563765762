import React, { useState } from 'react';
import classNames from "classnames";
import styles from './BlackHeader.module.scss';
import Logo from '../../../Assets/Logo/blue-logo.svg';
import { NavLink } from "react-router-dom";
import MobileMenu from '../../../Assets/Images/black-menu.svg';
import MobileLogo from '../../../Assets/Logo/mobile-logo.svg';
import MinimizeIcon from '../../../Assets/Images/final-close.svg';
export default function BlackHeader() {

    const [mobileHeader, setMobileHeader] = useState(false);
    return (
        <div data-scroll-section className=''>
            <div className='long-container'>
                <div className={styles.headerAlignment}>
                    <NavLink to="/">
                        <div className={styles.logo}>
                            <img src={Logo} alt="Logo" data-scroll data-scroll-repeat />
                        </div>
                    </NavLink>
                    <div className={styles.Menu}>
                        <nav>
                            <ul>
                                <li><span> <NavLink to="/aboutus">About</NavLink></span><span> <NavLink to="/aboutus">About</NavLink></span></li>
                                <li><span><NavLink to="/expertise">Expertise</NavLink></span><span><NavLink to="/expertise">Expertise</NavLink></span></li>
                                <li><span><NavLink to="/urbanregeneration">Urban Regeneration</NavLink></span><span><NavLink to="/urbanregeneration">Urban Regeneration</NavLink></span></li>
                                {/* <li><span><NavLink to="/news-insights">News & Insights</NavLink></span><span><NavLink to="/news-insights">News & Insights</NavLink></span></li> */}
                                <li><span><NavLink to="/contact">Contact</NavLink></span><span><NavLink to="/contact">Contact</NavLink></span></li>
                            </ul>
                        </nav>
                    </div>
                    <div className={styles.mobileMenu} onClick={() => setMobileHeader(!mobileHeader)}>
                        <img src={MobileMenu} alt="MobileMenu" />
                    </div>
                </div>
            </div>
            <div className={mobileHeader ? classNames(styles.mobileHeader, styles.mobileHeaderShow) : classNames(styles.mobileHeader, styles.mobileHeaderHidden)}>
                <div className={styles.mobileHeaderDesign}>
                    <div className={styles.logoIconAlignment}>
                        <NavLink to="/">
                            <img src={MobileLogo} alt="MobileLogo" />
                        </NavLink>
                        <div className={styles.mobileCloseIconAlignment} onClick={() => setMobileHeader(false)}>
                            <img src={MinimizeIcon} alt="MinimizeIcon" />
                        </div>
                        {/* <img className={styles.mobileCloseImageSize} src={MinimizeIcon} alt="MinimizeIcon" onClick={() => setMobileHeader(false)} /> */}
                    </div>
                    <div className={styles.mobileMenu}>
                        <ul>
                            <li><span><NavLink to="/aboutus">About</NavLink></span></li>
                            <li><span><NavLink to="/expertise">Expertise</NavLink></span></li>
                            <li><span><NavLink to="/urbanregeneration">Urban Regeneration</NavLink></span></li>
                            {/* <li><span><NavLink to="/news-insights">News & Insights</NavLink></span></li> */}
                            <li><span><NavLink to="/contact">Contact</NavLink></span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
