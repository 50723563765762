import React, { useEffect, useState, useRef } from "react";
import styles from './CommunityPage.module.scss';
import BlackHeader from '../Layout/BlackHeader/BlackHeader';
import Footer from '../Layout/Footer/Footer';
import CommunityBanner from '../../Assets/Images/community-banner.png';
import CommunityBannerMobile from '../../Assets/Images/community-banner-mobile.png';
import CommunityBlog from './CommunityBlog/CommunityBlog';
import CommunityVideo from './CommunityVideo/CommunityVideo';
import UrbenRegeneration from './UrbenRegeneration/UrbenRegeneration';
import cn from 'classnames';
import useLocoScroll from '../../hooks/useLocoScroll';
import useOnScreen from "../../hooks/useOnScreen";
import MetaDecorator from "../Util/MetaDecorator";
export default function CommunityPage() {
    useLocoScroll(true)
    const ref = useRef(null);

    const [reveal, setReveal] = useState(false);
    const onScreen = useOnScreen(ref);

    useEffect(() => {
        if (onScreen) setReveal(onScreen);
    }, [onScreen]);
    return (
        <>

            <div className="sticky-header">
                <BlackHeader />
            </div>
            <div id="main-container" data-scroll-container>
                <MetaDecorator title={"LRC Group | Communities"} description={"LRC’s products and platforms are built around communities. The Group develops healthy, resilient communities through dedication to social, economic and environmental sustainability. "} />
                <section className={styles.communityAlign} data-scroll-section>
                    <div className='container'>
                        <div className={styles.pageTitle}>
                            <h1 className="c-header_title o-h1" data-scroll data-scroll-speed="3" data-scroll-position="top" data-scroll-repeat>
                                <span className={cn("c-header_title_line", { "is-reveal": reveal })}>
                                    <span data-scroll data-scroll-speed="3" data-scroll-position="top" data-scroll-repeat>Transforming Real Estate through resilient </span>
                                    <span className="italic-class" data-scroll data-scroll-speed="3" data-scroll-position="top" data-scroll-repeat>Communities</span>
                                </span>
                            </h1>

                        </div>
                    </div>
                </section>
                <section className={styles.communityBanner} data-scroll-section>
                    <div className={cn(styles.communityBannerSpaceRemove, "container")}>
                        <img className={cn(styles.communityBannerMobileHidden, "flipImage")} src={CommunityBanner} alt="CommunityBanner" data-scroll data-scroll-repeat />
                        <img className={cn(styles.communityBannerMobileShow, "flipImage")} src={CommunityBannerMobile} alt="CommunityBannerMobile" data-scroll data-scroll-repeat />
                    </div>
                </section>
                <>
                    <CommunityBlog />
                </>
                <>
                    <CommunityVideo />
                </>
                <>
                    <UrbenRegeneration />
                </>
                <>
                    <Footer />
                </>
                <div className="footerBottomAlignment"></div>
            </div>
        </>
    )
}
