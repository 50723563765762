import React, { useEffect, useState, useRef } from "react";
import cn from "classnames";
import styles from './NewsAndInsights.module.scss';
import Footer from '../Layout/Footer/Footer';
import BlackHeader from '../Layout/BlackHeader/BlackHeader';
import NewsCard from './NewsCard/NewsCard';
import useLocoScroll from '../../hooks/useLocoScroll';
import useOnScreen from "../../hooks/useOnScreen";
import MetaDecorator from "../Util/MetaDecorator";
export default function NewsAndInsights() {
    useLocoScroll(true)
    const ref = useRef(null);

    const [reveal, setReveal] = useState(false);
    const onScreen = useOnScreen(ref);

    useEffect(() => {
        if (onScreen) setReveal(onScreen);
    }, [onScreen]);
    return (
        <div>
            <div className="sticky-header">
                <BlackHeader />
            </div>
            <div id="main-container" data-scroll-container>
                <MetaDecorator title={"LRC Group | News & Insights"} description={"Find out more about new developments, read up on news and explore insights on the LRC Group."} />
                <section className={styles.NewsAndInsightsAlignment} data-scroll-section>
                    <div className='container'>
                        <div className={styles.pageTitle}>
                            <h1 className="c-header_title o-h1" data-scroll data-scroll-speed="2" data-scroll-repeat>
                                <span className={cn("c-header_title_line", { "is-reveal": reveal })}>
                                    News & Insights
                                </span>
                            </h1>

                        </div>
                        <>
                            <NewsCard />
                        </>
                    </div>
                </section>
                <>
                    <Footer />
                </>
                <div className="footerBottomAlignment"></div>
            </div>
        </div>
    )
}
