import React, { useEffect, useState, useRef } from "react";
import classNames from "classnames";
import useOnScreen from "../../../hooks/useOnScreen";
import styles from './GeneratingInterest.module.scss';
import ArchitecutureImage from '../../../Assets/Images/architecuture.png';
import ShowImage from '../../../Assets/Images/show-imgae.png';
export default function GeneratingInterest() {
  const ref = useRef(null);

  const [reveal, setReveal] = useState(false);
  const onScreen = useOnScreen(ref);

  useEffect(() => {
    if (onScreen) setReveal(onScreen);
  }, [onScreen]);
  return (
    <div data-scroll-section>
      <section className={styles.GeneratingInterestAlignment}>
        <div className={styles.grid}>
          <div className={styles.gridItems}>
            <div className={styles.sectionTitle}>
              <div className={styles.mobileViewTextShow}>
                <h5>DESIGN & value engIneering</h5>
              </div>
              <h1>Generating interest in <span className="italic-class">under-utilised assets</span></h1>

              <p>
                Urban regeneration is a forward-thinking and sustainable city planning and architectural concept which involves revitalising urban spaces. In such projects, large-scale properties
                are utilised as mixed-used living, retail and community spaces to improve quality of life in cities.
              </p>
            </div>
            <div className={styles.fullViewImage}>
              <img src={ShowImage} alt="ShowImage" className="flipImage" data-scroll data-scroll-repeat />
            </div>
          </div>
          <div className={styles.gridItems}>
            <a>DESIGN & value engIneering</a>
            <div className={styles.imageStyle}>
              <img src={ArchitecutureImage} alt="ArchitecutureImage" className="flipImage" data-scroll data-scroll-repeat />
              {/* <h6 className="italic-class">Building</h6> */}
            </div>
            <div className={styles.imageRelatedText}>
              <span >
                LRC’s focus is on real estate impact investments in areas of strong demand and low supply. They keep ahead of the curve by transforming under-utilised assets as part of a
                conscientious urban renewal process. Alongside driving profitability, LRC make a significant and positive impact on local communities.
              </span>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
