import React, { useEffect, useState, useRef } from "react";
import classNames from "classnames";
import styles from "./ExpertiseSkill.module.scss";
import useOnScreen from "../../../hooks/useOnScreen";
export default function ExpertiseSkill() {
  const ref = useRef(null);

  const [reveal, setReveal] = useState(false);
  const onScreen = useOnScreen(ref);

  useEffect(() => {
    if (onScreen) setReveal(onScreen);
  }, [onScreen]);
  return (
    <div data-scroll-section>
      <section className="container">
        <div className={styles.ExpertiseSkillAlignment}>
          <div className={styles.grid}>
            <div className={styles.gridItems}>
              <h1
                className="c-header_title o-h1"
                data-scroll data-scroll-speed="1" data-scroll-repeat
              >
                <span
                  className={classNames("c-header_title_line", {
                    "is-reveal": reveal,
                  })}
                >
                  <span
                    data-scroll
                    data-scroll-speed="1"
                    data-scroll-repeat
                    data-scroll-position="top"
                  >
                    LRC Group provides a diverse set of skills
                  </span>
                </span>
              </h1>
            </div>
            <div className={styles.gridItems} data-scroll data-scroll-speed="1">
              <p>
                LRC demonstrates an efficient approach to residential property management through the creation of various vertically integrated operational subsidaries. In addition, LRC offers high-quality and sustainably designed office buildings as well as a hotel hospitality platform.
              </p>
              <p>
                LRC differentiates itself through its team’s expertise, access to real-time market trends
                and a disciplined approach to value creation and risk mitigation.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
