import React, { useEffect, useState, useRef } from "react";
import classNames from "classnames";
import useOnScreen from "../../../hooks/useOnScreen";
import styles from './CurabiturBanner.module.scss';
import AustinImage from '../../../Assets/Images/flower.png';
import CityImage from '../../../Assets/Images/city-image.png';
export default function CurabiturBanner() {
    const ref = useRef(null);

    const [reveal, setReveal] = useState(false);
    const onScreen = useOnScreen(ref);

    useEffect(() => {
        if (onScreen) setReveal(onScreen);
    }, [onScreen]);
    return (
        <div data-scroll-section>
            <section className={styles.CurabiturBanner}>
                <div className='container'>

                    <h1
                        className="c-header_title o-h1"
                        data-scroll data-scroll-speed="1" data-scroll-repeat
                    >
                        <span
                            className={classNames("c-header_title_line", {
                                "is-reveal": reveal,
                            })}
                        >
                            <span
                                data-scroll
                                data-scroll-speed="1"
                                data-scroll-repeat
                                data-scroll-position="top"
                            >
                                Encouraging a <span className="italic-class">healthy, sustainable and eco-friendly lifestyle</span>
                            </span>
                        </span>
                    </h1>
                </div>
            </section>
            <section className={styles.orciVariusAlignment}>
                <div className='container'>
                    <div className={styles.grid}>

                        <div className={styles.gridItems}>
                            <div className={styles.EcologicalTdeologyMobile}>
                                <h6>Ecological Ideology</h6>
                            </div>
                            <h1
                                className="c-header_title o-h1"
                                data-scroll data-scroll-speed="1" data-scroll-repeat
                            >
                                <span
                                    className={classNames("c-header_title_line", {
                                        "is-reveal": reveal,
                                    })}
                                >
                                    <span
                                        data-scroll
                                        data-scroll-speed="1"
                                        data-scroll-repeat
                                        data-scroll-position="top"
                                    >
                                        LRC Group drives{" "}
                                    </span>
                                    <span className="italic-class" data-scroll data-scroll-speed="1" data-scroll-position="top" data-scroll-repeat>sustainable operational efficiency</span>
                                </span>
                            </h1>
                            <p data-scroll data-scroll-speed="1">
                                LRC recognises the significant opportunity that exists in improving the environmental impact across its portfolio. The Group takes active measures and uses advanced and efficient equipment that reduces unneccessary consumption and operating costs.
                            </p>
                            <div className={styles.cardGrid}>
                                <div className={styles.cardGridItems}>
                                    <div className={styles.cardImage}>
                                        <img src={AustinImage} alt="AustinImage" className="flipImage" data-scroll data-scroll-repeat />
                                    </div>
                                    {/* <div className={styles.cardDetails}>
                                <p data-scroll data-scroll-speed="1">
                                    Curabitur dictum arcu sed mauris porttitor rutrum. Quisque laoreet egestas erat. Nullam dignissim, turpis eget facilisis scelerisque, sem nisl suscipit ligula, quis consequat lacus nulla vel tellus. Integer tincidunt efficitur
                                    orci, sed vulputate purus condimentum eu. Quisque imperdiet finibus massa non pretium. Ut at est tortor
                                </p>
                            </div> */}
                                </div>
                            </div>
                        </div>
                        <div className={styles.gridItems}>
                            <div>
                                <a>Ecological Ideology</a>
                            </div>
                            <div className={styles.mobileViewOrder}>
                                <div className={styles.cityTopAlignment}>
                                    <img src={CityImage} alt="CityImage" data-scroll data-scroll-repeat />
                                </div>
                                <div className={styles.cardDetailsOne}>
                                    <p >
                                        LRC manages its properties to the highest standard while promoting sustainable practices and business operations. The Group introduces and encourages a new way of consuming sustainable, environmentally friendly products to its developers, landlords and tenants.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </div>
    )
}
