import React, { useEffect, useState, useRef } from "react";
import classNames from 'classnames';
import useOnScreen from "../../../hooks/useOnScreen";
import styles from './TechnolofyIncrease.module.scss';
import EffcienciesImage from '../../../Assets/Images/effciencies.png';
import CardImage from '../../../Assets/Images/card-child.png';
export default function TechnolofyIncrease() {
    const ref = useRef(null);

    const [reveal, setReveal] = useState(false);
    const onScreen = useOnScreen(ref);

    useEffect(() => {
        if (onScreen) setReveal(onScreen);
    }, [onScreen]);
    return (
        <div data-scroll-section>
            <section className={styles.TechnolofyIncreaseAlignment}>
                <div className='container'>
                    <div className={styles.techTextGrid}>
                        <div className={styles.techTextGridItems}>
                            <h1>Massive scale as a fundamental requirement</h1>

                        </div>
                        <div className={styles.techTextGridItems} >
                            <p>
                                LRC uses the leverage of scale to allow cost-effective solutions across its real estate ecosystem. With 15k residential units under management, LRC is one of the largest PRS platforms in Europe. This kind of scale allows LRC to
                                procure goods and services at attractive rates, resulting in reduced leakage between gross rental income and net operating income.
                            </p>
                        </div>
                    </div>
                    <div className={styles.EnhancingTenanstsAlignment}>
                        <div className=''>
                            <div className={styles.textGrid}>
                                <div className={styles.textGridItems}>
                                    <h1>LRC Group uses technology to increase efficiencies</h1>

                                </div>
                                <div className={styles.textGridItems} >
                                    <p>GROWTH</p>
                                </div>
                            </div>
                            <div className={styles.grid}>
                                <div className={styles.gridItems} >
                                    <p>
                                        The Vonder app offers a fully comprehensive ecosystem to enhance efficiencies. From chatting with other tenants to exploring events, booking amenities and scheduling
                                        apartment cleans – the Vonder app allows tenants to manage all things Vonder.
                                    </p>
                                    <div className={styles.childImage}>
                                        <img src={CardImage} alt="CardImage" className="flipImage" data-scroll data-scroll-repeat />
                                    </div>
                                </div>
                                <div className={styles.gridItems}>
                                    <div className={styles.fullImage}>
                                        <img src={EffcienciesImage} alt="EffcienciesImage" className="flipImage" data-scroll data-scroll-repeat />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
