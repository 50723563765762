import React, { useEffect, useState, useRef } from "react";
import classNames from "classnames";
import useOnScreen from "../../../hooks/useOnScreen";
import styles from './LrcGroupProvide.module.scss';
import LrcGroupProivde from '../../../Assets/Images/lrc-group-proivde.png';
import { Helmet } from 'react-helmet';
export default function LrcGroupProvide() {
    const ref = useRef(null);

    const [reveal, setReveal] = useState(false);
    const onScreen = useOnScreen(ref);

    useEffect(() => {
        if (onScreen) setReveal(onScreen);
    }, [onScreen]);
    return (
        <div data-scroll-section>
            <Helmet>
                <title>LRC Group | Expertise</title>
                <meta name="LRC Group provides a diverse set of skills. LRC demonstrates an efficient approach to residential property management through the creation of various vertically integrated operational subsidaries. In addition, LRC offers high-quality and sustainably designed office buildings as well as a hotel hospitality platform." content="Helmet application" />
            </Helmet>
            <section className={styles.LrcGroupProvideAlignment}>
                <div className='long-container'>
                    <div className={styles.titleText}>
                        <h1
                            className="c-header_title o-h1"
                            data-scroll data-scroll-speed="1" data-scroll-repeat
                        >
                            <span
                                className={classNames("c-header_title_line", {
                                    "is-reveal": reveal,
                                })}
                            >
                                <span
                                    data-scroll
                                    data-scroll-speed="1"
                                    data-scroll-repeat
                                    data-scroll-position="top"
                                >
                                    The LRC Group provides{" "}
                                </span>
                                <span className='italic-class' data-scroll data-scroll-speed="1" data-scroll-position="top" data-scroll-repeat>Multidisciplinary Expertise{" "}</span>
                            </span>
                        </h1>
                    </div>
                </div>
            </section>
            <div className={classNames(styles.imageLrcMobileView, 'long-container')}>
                <div className={styles.lrcGroupProvideBanner}>
                    <img src={LrcGroupProivde} alt="LrcGroupProivde" className="flipImage" data-scroll data-scroll-repeat />
                </div>
            </div>
        </div>
    )
}
