import React, { useEffect, useState, useRef } from "react";
import classNames from "classnames";
import useOnScreen from "../../../hooks/useOnScreen";
import styles from './ChartView.module.scss';
import ChartImage from '../../../Assets/Images/chart-image.png';
import MobileChartImage from '../../../Assets/Images/mobile-chart.png';
export default function ChartView() {
    const ref = useRef(null);

    const [reveal, setReveal] = useState(false);
    const onScreen = useOnScreen(ref);

    useEffect(() => {
        if (onScreen) setReveal(onScreen);
    }, [onScreen]);
    return (
        <div data-scroll-section>
            <section className={styles.ChartViewAlignment}>
                <div className="container">
                    <div className={styles.pageTitleGrid}>
                        <div className={styles.pageTitleGridItems}>
                            <h1
                                className="c-header_title o-h1"
                                data-scroll data-scroll-speed="1" data-scroll-repeat
                            >
                                <span
                                    className={classNames("c-header_title_line", {
                                        "is-reveal": reveal,
                                    })}
                                >
                                    <span
                                        data-scroll
                                        data-scroll-speed="1"
                                        data-scroll-repeat
                                        data-scroll-position="top"
                                    >
                                        Building a <span className="italic-class">sustainable ecosystem</span>
                                    </span>
                                </span>
                            </h1>
                        </div>
                        <div className={styles.pageTitleGridItems}>
                            <p >Partnerships</p>
                        </div>
                    </div>
                    <div className={styles.chartImage}>
                        <img className={classNames(styles.mobileViewChartNone, "flipImage")} src={ChartImage} alt="ChartImage" data-scroll data-scroll-repeat />
                        <div className={styles.chartMobileViewShow}>
                            <img src={MobileChartImage} alt="MobileChartImage" />
                        </div>
                    </div>
                    <div className={styles.textGrid}>
                        <div className={styles.textGridItems} data-scroll data-scroll-speed="1">
                            <p>
                            LRC owns and manages properties to the highest standard, advancing and promoting sustainable practices in real estate and in business operations. LRC takes pride in the many channels through which it is able to promote true change in the real estate sector. 
                            </p>
                        </div>
                        <div className={styles.textGridItems} data-scroll data-scroll-speed="1">
                            <p>
                            Sustainable solutions such as renewable electricity and urban farming allow the Group to reduce each tenant’s individual carbon footprint. LRC also invests in technologies around wellbeing and sustainable homewear to encourage a healthy and eco-friendly lifestyle.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
