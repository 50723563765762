import React from 'react'
import styles from './CommunityVideo.module.scss';
import VideoImage from '../../../Assets/Images/Community-video.png';
export default function CommunityVideo() {
  return (
    <div data-scroll-section>
      <div>
        <section className={styles.CommunityVideoAlignment}>.
          <iframe src="https://www.youtube-nocookie.com/embed/QhKahIr_W30?&autoplay=1&controls=0&loop=10&modestbranding=1&showinfo=0" frameborder="0" allowfullscreen></iframe>
        </section>
      </div>
    </div>
  )
}
