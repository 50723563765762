import React, { useEffect, useState, useRef } from "react";
import cn from "classnames";
import BlackHeader from '../Layout/BlackHeader/BlackHeader';
import styles from './AboutUs.module.scss';
import AboutBanner from '../../Assets/Images/about-banner.png';
import AboutBannerMobile from '../../Assets/Images/AboutBannerMobile.png';
import OurApproach from './OurApproach/OurApproach';
import OurHistory from './OurHistory/OurHistory';
import classNames from "classnames";
import OurTeam from './OurTeam/OurTeam';
import FounderSection from './FounderSection/FounderSection';
import Malesuada from './Malesuada/Malesuada';
import Footer from '../Layout/Footer/Footer';
import useLocoScroll from '../../hooks/useLocoScroll';
import useOnScreen from "../../hooks/useOnScreen";
import Timeline from "./Timeline/Timeline";
import NewsInshights from "./NewsInshights/NewsInshights";
import { Helmet } from 'react-helmet';
import MetaDecorator from "../Util/MetaDecorator";
import NewNewsPage from "./NewNewsPage/NewNewsPage";
export default function AboutUs() {
  useLocoScroll(true)
  const ref = useRef(null);

  const [reveal, setReveal] = useState(false);
  const onScreen = useOnScreen(ref);

  useEffect(() => {
    if (onScreen) setReveal(onScreen);
  }, [onScreen]);
  return (
    <>

      <div className="sticky-header">
        <BlackHeader />
      </div>
      <div id="main-container" data-scroll-container>

        <MetaDecorator title={"LRC Group | About"} description={"The LRC Group is a privately held real estate investment and fund management firm. LRC specialises in structuring and executing value-add impact investments. The group enhances efficiencies through experienced in-house asset & property management teams."} />
        <section data-scroll-section className={styles.mobileViewLrcAboutAlignment}>
          <div className={classNames(styles.mobileViewLrcAboutAlignment, 'long-container')}>
            <div className={styles.lrcAboutTitle}>

              <h1 className="c-header_title o-h1" data-scroll data-scroll-speed="3" data-scroll-position="top" data-scroll-repeat>
                <span className={cn("c-header_title_line", { "is-reveal": reveal })}>
                  The LRC Group specialises in the management of{" "}
                  <span className="italic-class" >global portfolios, </span>
                  emphasising  <span className="italic-class" >positive impact</span> and{" "}
                  <span className="italic-class">sustainability </span>
                </span>
              </h1>
            </div>
          </div>
        </section>
        <section className={styles.aboutBanner} data-scroll-section>
          <div className={classNames(styles.mobileViewLrcAboutAlignment, 'long-container')}>
            {/* <img src={AboutBanner} alt="AboutBanner" className="flipImage" data-scroll data-scroll-repeat /> */}
            <img className={cn(styles.communityBannerMobileHidden, "flipImage")} src={AboutBanner} alt="AboutBanner" data-scroll data-scroll-repeat />
            <img className={cn(styles.communityBannerMobileShow, "flipImage")} src={AboutBannerMobile} alt="CommunityBannerMobile" data-scroll data-scroll-repeat />
          </div>
        </section>
        <>
          <OurApproach />
        </>
        <>
          <OurHistory />
        </>
        <>
          <Timeline />
        </>
        <>
          <OurTeam />
        </>
        <>
          <FounderSection />
        </>
        {/* <>  <NewNewsPage /></> */}

        <>
          <Footer />
        </>
        <div className="footerBottomAlignment"></div>
      </div>
    </>
  )
}
