import classNames from 'classnames';
import React from 'react'
import styles from './Pagination.module.scss';
import LeftArrow from '../../../Assets/Images/pagination-left.svg';
import RightArrow from '../../../Assets/Images/pagination-right.svg';
export default function Pagination() {
    return (
        <div>
            <div className={styles.paginationAlignment}>
                <div className={styles.paginationBox}>
                    <div className={styles.leftArrow}>
                        <img src={LeftArrow} alt="LeftArrow" />
                    </div>
                    <div className={classNames(styles.PageCounter, styles.activePage)}>1</div>
                    <div className={styles.PageCounter}>2</div>
                    <div className={styles.PageCounter}>3</div>
                    <div className={styles.PageCounter}>4</div>
                    <div className={styles.PageCounter}>5</div>
                    <div className={styles.RightArrow}>
                        <img src={RightArrow} alt="RightArrow" />
                    </div>
                </div>
            </div>
        </div>
    )
}
