import React, { useEffect, useState, useRef } from "react";
import styles from "./UrbenRegeneration.module.scss";
import useOnScreen from "../../../hooks/useOnScreen";
import classNames from "classnames";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
export default function UrbenRegeneration() {
  const ref = useRef(null);

  const [reveal, setReveal] = useState(false);
  const [cases, setCases] = useState([]);
  const onScreen = useOnScreen(ref);
  const data = [{ name: "Sustainability", link: "sustainability" }, { name: "Scale", link: "scale" }, { name: "Communities", link: "community" }, { name: "Wellbeing", link: "wellbeing" }, , { name: "Technology", link: "technology" }]
  useEffect(() => {
    if (onScreen) setReveal(onScreen);
    const location = window.location.pathname
    const find = location.split("/")[1]
    const otherCases = data.filter((rep) => rep.link !== find)
    setCases(otherCases)
  }, [onScreen]);

  return (
    <div data-scroll-section>
      <section className={styles.exploreCommunityAlignment}>
        <div className="container">
          <div className={styles.pageTitle}>
            <h1
              className="c-header_title o-h1"
              data-scroll data-scroll-speed="0.5" data-scroll-repeat
            >
              <span
                className={classNames("c-header_title_line", {
                  "is-reveal": reveal,
                })}
              >

                Explore <span className="italic-class" >Urban Regeneration:</span>
              </span>
            </h1>
            <a>Case studies</a>
          </div>
          <div className={styles.grid}>
            {cases?.map((rep, i) => {
              return (
                <div className={styles.gridItems}>
                  <NavLink to={`/${rep.link}`}>
                    <h2
                      className="c-header_title o-h1"
                      data-scroll data-scroll-speed="1" data-scroll-repeat
                    >
                      <span
                        className={classNames("c-header_title_line", {
                          "is-reveal": reveal,
                        })}
                      >
                        <span
                          data-scroll
                          data-scroll-speed="1"
                          data-scroll-repeat
                          data-scroll-position="top"
                        >
                          Transforming Real Estate through <span className="italic-class">{rep?.name}</span>
                        </span>
                      </span>
                    </h2>

                  </NavLink>
                  <NavLink to={`/${rep.link}`}>
                    <button>
                      <span>Learn more</span>
                      <svg
                        width="14"
                        height="12"
                        viewBox="0 0 14 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.24473 0.412046C7.57016 0.0866095 8.0978 0.0866095 8.42324 0.412046L13.4232 5.41205C13.5795 5.56833 13.6673 5.78029 13.6673 6.0013C13.6673 6.22232 13.5795 6.43428 13.4232 6.59056L8.42324 11.5906C8.0978 11.916 7.57016 11.916 7.24473 11.5906C6.91929 11.2651 6.91929 10.7375 7.24473 10.412L10.8221 6.83464L1.16732 6.83464C0.70708 6.83464 0.333984 6.46154 0.333984 6.0013C0.333984 5.54107 0.70708 5.16797 1.16732 5.16797L10.8221 5.16797L7.24473 1.59056C6.91929 1.26512 6.91929 0.737483 7.24473 0.412046Z"
                          fill="#333B49"
                        />
                      </svg>
                    </button>
                  </NavLink>
                </div>
              )
            })}

          </div>
        </div>
      </section>
    </div>
  );
}
