import React, { useEffect, useState, useRef } from "react";
import styles from './Contact.module.scss';
import classNames from "classnames";
import BlackHeader from '../Layout/BlackHeader/BlackHeader';
import Footer from '../Layout/Footer/Footer';
import ConatactBanner from '../../Assets/Images/contact-banner.png';
import OfficeInformation from './OfficeInformation/OfficeInformation';
import useLocoScroll from '../../hooks/useLocoScroll';
import useOnScreen from "../../hooks/useOnScreen";
import EnquiriesForm from '../home/EnquiriesForm/EnquiriesForm';
import MetaDecorator from "../Util/MetaDecorator";
export default function Contact() {
    useLocoScroll(true)
    const ref = useRef(null);

    const [reveal, setReveal] = useState(false);
    const onScreen = useOnScreen(ref);

    useEffect(() => {
        if (onScreen) setReveal(onScreen);
    }, [onScreen]);
    return (
        <>

            <div className={styles.contactNewBanner}>
                <BlackHeader />
            </div>
            <div id="main-container" data-scroll-container>
                <MetaDecorator title={"LRC Group | Contact Us"} description={"Contact the LRC Group today."} />
                <>
                    <EnquiriesForm />
                </>
                <>
                    <OfficeInformation />
                </>
                <>
                    <Footer />
                </>
                <div className="footerBottomAlignment"></div>
            </div>
        </>
    )
}
