import React, { useEffect, useState, useRef } from "react";
import styles from './DigitalExperience.module.scss';
import useOnScreen from "../../../hooks/useOnScreen";
import classNames from "classnames";
export default function DigitalExperience() {
    const ref = useRef(null);

    const [reveal, setReveal] = useState(false);
    const onScreen = useOnScreen(ref);

    useEffect(() => {
        if (onScreen) setReveal(onScreen);
    }, [onScreen]);
    return (
        <div data-scroll-section>
            <section className={styles.DigitalExperienceAlignment}>
                <div className='container'>
                    <div className={styles.pageTitle}>
                        <h1
                            className="c-header_title o-h1"
                            data-scroll data-scroll-speed="0.5" data-scroll-repeat
                        >
                            <span
                                className={classNames("c-header_title_line", {
                                    "is-reveal": reveal,
                                })}
                            >
                                <span
                                    data-scroll
                                    data-scroll-speed="1"
                                    data-scroll-repeat
                                    data-scroll-position="top"

                                >
                                    A{" "}
                                </span>
                                <span className='italic-class' data-scroll data-scroll-speed="1" data-scroll-position="top" data-scroll-repeat>mobile-first </span>
                                <span data-scroll data-scroll-speed="1" data-scroll-position="top" data-scroll-repeat>digital experience</span>
                            </span>
                        </h1>
                    </div>
                    <div className={styles.grid}>
                        <div className={styles.gridItems}>
                            <span>Find your home</span>
                        </div>
                        <div className={styles.gridItems}>
                            <span>Book events, value-added services & amenities</span>
                        </div>
                        <div className={styles.gridItems}>
                            <span>Dedicated chat</span>
                        </div>
                        <div className={styles.gridItems}>
                            <span>Financial services & rewards</span>
                        </div>
                        <div className={styles.gridItems}>
                            <span>Request support</span>
                        </div>
                        <div className={styles.gridItems}>
                            <span>Smart Homes & smartlocks</span>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
