import React, { useEffect, useState, useRef } from "react";
import LrcGroupProvide from './LrcGroupProvide/LrcGroupProvide';
import ImpressiveRecord from './ImpressiveRecord/ImpressiveRecord';
import FeaturedProperties from './FeaturedProperties/FeaturedProperties';
import ExpertiseBanner from './ExpertiseBanner/ExpertiseBanner';
import Footer from '../Layout/Footer/Footer';
import BlackHeader from '../Layout/BlackHeader/BlackHeader';
import useLocoScroll from '../../hooks/useLocoScroll';
import useOnScreen from "../../hooks/useOnScreen";
import EnquiriesForm from "../home/EnquiriesForm/EnquiriesForm";
import ExpertiseSkill from "./ExpertiseSkill/ExpertiseSkill";
import ResidentialSection from "./ResidentialSection/ResidentialSection";
import { Helmet } from 'react-helmet';
export default function Expertise() {
    useLocoScroll(true)
    const ref = useRef(null);

    const [reveal, setReveal] = useState(false);
    const onScreen = useOnScreen(ref);

    useEffect(() => {
        if (onScreen) setReveal(onScreen);
    }, [onScreen]);
    return (
        <>

            <div className="sticky-header">
                <BlackHeader />
            </div>
            <div id="main-container" data-scroll-container>
                <>
                    <LrcGroupProvide />
                </>
                {/* <>
                    <ImpressiveRecord />
                </> */}
                {/* <>
                    <FeaturedProperties />
                </>
                <>
                    <ExpertiseBanner />
                </> */}
                <>
                    <ExpertiseSkill />
                </>
                <>
                    <ResidentialSection />
                </>
                <>
                    <EnquiriesForm />
                </>
                <>
                    <Footer />
                </>
                <div className="footerBottomAlignment"></div>
            </div>
        </>
    )
}
