import React, { useEffect, useState, useRef } from "react";
import cn from "classnames";
import styles from './NewsDetails.module.scss';
import Header from '../Layout/Header';
import NewsDetailsText from './NewsDetailsText/NewsDetailsText';
import NewsInshights from '../AboutUs/NewsInshights/NewsInshights';
import Footer from '../Layout/Footer/Footer';
import useLocoScroll from '../../hooks/useLocoScroll';
import useOnScreen from "../../hooks/useOnScreen";
export default function NewsDetails() {
    useLocoScroll(true)
    const ref = useRef(null);

    const [reveal, setReveal] = useState(false);
    const onScreen = useOnScreen(ref);

    useEffect(() => {
        if (onScreen) setReveal(onScreen);
    }, [onScreen]);
    return (
        <div id="main-container" data-scroll-container>
            <section className={styles.NewsDetailsBanner} data-scroll-section>
                <Header />
                <div className='container'>
                    <div className={styles.textAlignment}>
                        <h1 className="c-header_title o-h1" data-scroll data-scroll-speed="2" data-scroll-repeat>
                            <span className={cn("c-header_title_line", { "is-reveal": reveal })}>
                                LRC has acquired the Dome <br /> portfolio in London
                            </span>
                        </h1>
                    </div>
                </div>
            </section>
            <>
                <NewsDetailsText />
            </>
            <>
                <NewsInshights />
            </>
            <>
                <Footer />

            </>


        </div>
    )
}
