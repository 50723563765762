import React, { useEffect, useState, useRef } from "react";
import classNames from "classnames";
import styles from './OfficeInformation.module.scss';
import RightArrow from '../../../Assets/Images/slider-right-arrow.svg';
import LeftArrow from '../../../Assets/Images/slider-left-arrow.svg';
import OfficeImage from '../../../Assets/Images/office-image.png';
import LocationImage from '../../../Assets/Images/location-image.png';
import useOnScreen from "../../../hooks/useOnScreen";
export default function OfficeInformation() {
    const ref = useRef(null);

    const [reveal, setReveal] = useState(false);
    const onScreen = useOnScreen(ref);

    useEffect(() => {
        if (onScreen) setReveal(onScreen);
    }, [onScreen]);
    return (
        <div data-scroll-section className={styles.officeInforAlignment}>
            <div className="container">
                <div className={styles.contactTextGrid}>
                    <div className={styles.contactTextGridItems}>
                        <h1>Location & Contact</h1>
                    </div>
                    <div className={styles.contactTextGridItems}>
                        <p>
                            The LRC Group
                            (“LRC”) is a privately held investment and management firm operating from affiliated offices in several locations.
                        </p>
                    </div>
                </div>
                <div className={styles.allOfficeLocationShow}>
                    <div className={styles.officeInfroGrid}>
                        <div className={styles.officeInfroGridItems}>
                            <div className={styles.textSubGrid}>
                                <div className={styles.textSubGridItems}>
                                    <h2>London</h2>
                                </div>
                                <div className={styles.textSubGridItems}>
                                    <p>60 Welbeck Street London W1G 9XB United Kingdom
                                    </p>
                                    <span>+44 20 7317 1120</span>
                                </div>
                            </div>
                        </div>
                        <div className={styles.officeInfroGridItems}>
                            <div className={styles.textSubGrid}>
                                <div className={styles.textSubGridItems}>
                                    <h2>Berlin</h2>
                                </div>
                                <div className={styles.textSubGridItems}>
                                    <p>Schlüterstraße 45 10707 Berlin Germany
                                    </p>
                                    <span>+49 30 8872 8060</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.officeInfroGrid}>
                        <div className={styles.officeInfroGridItems}>
                            <div className={styles.textSubGrid}>
                                <div className={styles.textSubGridItems}>
                                    <h2>Dubai</h2>
                                </div>
                                <div className={styles.textSubGridItems}>
                                    <p>Office 4127, Level 41, Emirates Towers,  Dubai, UAE
                                    </p>
                                    <span>+971 4319 9101</span>
                                </div>
                            </div>
                        </div>
                        <div className={styles.officeInfroGridItems}>
                            <div className={styles.textSubGrid}>
                                <div className={styles.textSubGridItems}>
                                    <h2>Dublin</h2>
                                </div>
                                <div className={styles.textSubGridItems}>
                                    <p>32 Lower Baggot Street Dublin 2
                                        Ireland
                                    </p>
                                    <span>+353 1 588 6640</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.officeInfroGrid}>
                        <div className={styles.officeInfroGridItems}>
                            <div className={styles.textSubGrid}>
                                <div className={styles.textSubGridItems}>
                                    <h2>Warsaw</h2>
                                </div>
                                <div className={styles.textSubGridItems}>
                                    <p>Pulawska 17 02-515 Warsaw
                                        Poland
                                    </p>
                                    <span>+48 22 490 34 10</span>
                                </div>
                            </div>
                        </div>
                        <div className={styles.officeInfroGridItems}>
                            <div className={styles.textSubGrid}>
                                <div className={styles.textSubGridItems}>
                                    <h2>Luxembourg</h2>
                                </div>
                                <div className={styles.textSubGridItems}>
                                    <p>39 Grand rue L-1661, Luxembourg
                                        Grand Duchy of Luxembourg
                                    </p>
                                    <span>+32 495 43 90 00</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.officeInfroGrid}>
                        <div className={styles.officeInfroGridItems}>
                            <div className={styles.textSubGrid}>
                                <div className={styles.textSubGridItems}>
                                    <h2>Malta</h2>
                                </div>
                                <div className={styles.textSubGridItems}>
                                    <p>51, Ta’ Xbiex Sea Front, Ta’ Xbiex, XBX 1020, Malta
                                    </p>
                                    <span>+356 2033 1120</span>
                                </div>
                            </div>
                        </div>
                        <div className={styles.officeInfroGridItems}>
                            <div className={styles.textSubGrid}>
                                <div className={styles.textSubGridItems}>
                                    <h2>Cyprus</h2>
                                </div>
                                <div className={styles.textSubGridItems}>
                                    <p>33 Artemidos Avenue Metropolitan House, Office 202 CY-6025 Larnaca.
                                    </p>
                                    <span>+357 24 650 120</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
