import React, { useEffect, useState, useRef } from "react";
import classNames from 'classnames';
import useOnScreen from "../../../hooks/useOnScreen";
import styles from './RapidlyExpanding.module.scss';
import BuildingImage from '../../../Assets/Images/building-image.png';
import BuildingMobileImage from '../../../Assets/Images/building-image-mobile.png';
export default function RapidlyExpanding() {
    const ref = useRef(null);

    const [reveal, setReveal] = useState(false);
    const onScreen = useOnScreen(ref);

    useEffect(() => {
        if (onScreen) setReveal(onScreen);
    }, [onScreen]);
    return (
        <div data-scroll-section>
            <section className={styles.RapidlyExpandingBanner}>
                <div className='container'>
                    <div className={styles.grid}>
                        <div className={styles.gridItems}>
                            <img className={classNames(styles.buildingImageMobileViewHidden, "flipImage")} src={BuildingImage} data-scroll data-scroll-repeat alt="BuildingImage" />
                            <img className={classNames(styles.buildingImageMobileViewShow, "flipImage")} src={BuildingMobileImage} alt="BuildingMobileImage" data-scroll data-scroll-repeat />
                        </div>
                        <div className={styles.gridItems}>
                            <div data-scroll data-scroll-speed="1">
                                <a>FUTure plans</a>
                            </div>

                            <h1 className="c-header_title o-h1" data-scroll data-scroll-speed="2" data-scroll-repeat>
                                <span className={classNames("c-header_title_line", { "is-reveal": reveal })}>
                                    <span data-scroll data-scroll-speed="3" data-scroll-position="top" data-scroll-repeat>The LRC Group is rapidly </span>
                                    <span className="italic-class" data-scroll data-scroll-speed="1" data-scroll-position="top" data-scroll-repeat>expanding globally</span>
                                </span>
                            </h1>
                            <div data-scroll data-scroll-speed="1">


                                <p>
                                    LRC is actively combining experience, innovation and
                                    skills to expand within the international property markets.
                                </p>
                                <p>
                                    The group operates within a distinct scalable strategy, acquiring new assets in both existing and new locations globally. The investment
                                    of capital in extensive assets allows the LRC Group to enable and utilise cost efficient processes.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
