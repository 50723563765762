import React, { useEffect, useState, useRef } from "react";
import styles from './LrcGroups.module.scss';
import CombinedIcon from '../../../Assets/Images/Combined.svg';
import DiamondIcon from '../../../Assets/Images/Combined Shape.svg';
import EarthIcon from '../../../Assets/Images/earth.svg';
import DeployIcon from '../../../Assets/Images/diamond.svg';
import ChartIcon from '../../../Assets/Images/line-chart.svg';
import ContentIcon from '../../../Assets/Images/content.svg';
import MipimImage from '../../../Assets/Images/newshome1.png';
import HouseImage from '../../../Assets/Images/newshome2.png';
import DomeImage from '../../../Assets/Images/newshome3.png';
import classNames from 'classnames';
import useOnScreen from "../../../hooks/useOnScreen";
import { NavLink } from "react-router-dom";
export default function LrcGroups() {
  const ref = useRef(null);

  const [reveal, setReveal] = useState(false);
  const onScreen = useOnScreen(ref);

  useEffect(() => {
    if (onScreen) setReveal(onScreen);
  }, [onScreen]);
  return (
    <div data-scroll-section>
      <div className={styles.benefitsScaleBaner}>
        <div className='container'>
          <div className={styles.bannerText}>
            <h1
              className="c-header_title o-h1"
              data-scroll data-scroll-speed="0.5" data-scroll-repeat
            >
              <span
                className={classNames("c-header_title_line", {
                  "is-reveal": reveal,
                })}
              >
                <span
                  data-scroll
                  data-scroll-speed="1"
                  data-scroll-repeat
                  data-scroll-position="top"

                >
                  Maximising the{" "}
                </span>
                <span className="italic-class" data-scroll data-scroll-speed="1" data-scroll-position="top" data-scroll-repeat>benefits of scale and technology to remove
                  friction{" "}</span>
                <span data-scroll data-scroll-speed="1" data-scroll-position="top" data-scroll-repeat>for both landlords and tenants.{" "}</span>
              </span>
            </h1>

          </div>
        </div>
      </div>
      {/* <div className={styles.newsSectionAlignment}>
        <div className="container">
          <div className={styles.pageTitle}>
            <div data-scroll data-scroll-speed="1">
              <a>News & insights</a>
            </div>
            <h1
              className="c-header_title o-h1"
              data-scroll data-scroll-speed="0.5"
            >
              <span
                className={classNames("c-header_title_line", {
                  "is-reveal": reveal,
                })}
              >Latest N<span className="italic-class">ews & Insights</span>
              </span>
            </h1>
          </div>
          <div className={styles.cardGrid}>
            <div className={styles.cardGridItems}>
              <div className={styles.cardImage}>
                <img src={MipimImage} alt="MipimImage" className="flipImage" data-scroll data-scroll-repeat />
              </div>
              <div className={styles.cardDetails}>
                <NavLink to="/newsdetails">
                <h3
                  className="c-header_title o-h1"
                  data-scroll data-scroll-speed="0.5" data-scroll-repeat
                >
                  <span
                    className={classNames("c-header_title_line", {
                      "is-reveal": reveal,
                    })}
                  >
                    <span
                      data-scroll
                      data-scroll-speed="1"
                      data-scroll-repeat
                      data-scroll-position="top"

                    >
                      LRC has exchanged contracts on Atelier in Salford, UK
                    </span>
                  </span>
                </h3>
                </NavLink>
                <p data-scroll data-scroll-speed="1">By <a>LRC Group</a> 02 March 2022</p>
              </div>
            </div>
            <div className={styles.cardGridItems}>
              <div className={styles.cardImage}>
                <img src={HouseImage} alt="HouseImage" className="flipImage" data-scroll data-scroll-repeat />
              </div>
              <div className={styles.cardDetails}>
                <NavLink to="/newsdetails">
                <h3
                  className="c-header_title o-h1"
                  data-scroll data-scroll-speed="0.5" data-scroll-repeat
                >
                  <span
                    className={classNames("c-header_title_line", {
                      "is-reveal": reveal,
                    })}
                  >
                    <span
                      data-scroll
                      data-scroll-speed="1"
                      data-scroll-repeat
                      data-scroll-position="top"

                    >
                      LRC has acquired Victoria Riverside in Leeds
                    </span>
                  </span>
                </h3>
                </NavLink>
                <p data-scroll data-scroll-speed="1">By <a>LRC Group</a> 02 March 2022</p>
              </div>
            </div>
            <div className={styles.cardGridItems}>
              <div className={styles.cardImage}>
                <img src={DomeImage} alt="DomeImage" className="flipImage" data-scroll data-scroll-repeat />
              </div>
              <div className={styles.cardDetails}>
                <NavLink to="/newsdetails">
                <h3
                  className="c-header_title o-h1"
                  data-scroll data-scroll-speed="0.5" data-scroll-repeat
                >
                  <span
                    className={classNames("c-header_title_line", {
                      "is-reveal": reveal,
                    })}
                  >
                    <span
                      data-scroll
                      data-scroll-speed="1"
                      data-scroll-repeat
                      data-scroll-position="top"

                    >
                      LRC has acquired 3 new properties in London
                    </span>
                  </span>
                </h3>
                </NavLink>
                <p data-scroll data-scroll-speed="1">By <a>LRC Group</a> 02 March 2022</p>
              </div>
            </div>
          </div>
          <div>
            <div className={styles.viewMoreButton} data-scroll data-scroll-speed="1">
              <NavLink to="/news-insights">
                <button>
                  View All
                  <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.24473 0.412046C7.57016 0.0866095 8.0978 0.0866095 8.42324 0.412046L13.4232 5.41205C13.5795 5.56833 13.6673 5.78029 13.6673 6.0013C13.6673 6.22232 13.5795 6.43428 13.4232 6.59056L8.42324 11.5906C8.0978 11.916 7.57016 11.916 7.24473 11.5906C6.91929 11.2651 6.91929 10.7375 7.24473 10.412L10.8221 6.83464L1.16732 6.83464C0.70708 6.83464 0.333984 6.46154 0.333984 6.0013C0.333984 5.54107 0.70708 5.16797 1.16732 5.16797L10.8221 5.16797L7.24473 1.59056C6.91929 1.26512 6.91929 0.737483 7.24473 0.412046Z" fill="white" />
                  </svg>
                </button>
              </NavLink>
            </div>

          </div>

        </div>
      </div> */}
      <section className={styles.LrcGroupsAlignment}>
        <div className='container'>
          <div className={styles.grid}>
            <div className={styles.gridItems}>
              <div>
                <h1
                  className="c-header_title o-h1"
                  data-scroll data-scroll-speed="0.5" data-scroll-repeat
                >
                  <span
                    className={classNames("c-header_title_line", {
                      "is-reveal": reveal,
                    })}
                  >
                    <span
                      data-scroll
                      data-scroll-speed="1"
                      data-scroll-repeat
                      data-scroll-position="top"

                    >
                      The LRC Group's<br />
                    </span>
                    <span className="italic-class" data-scroll data-scroll-speed="1" data-scroll-position="top" data-scroll-repeat>Core Principles & Values{" "}</span>
                  </span>
                </h1>
                <div data-scroll data-scroll-speed="1">
                  <p>
                    Thoughout the past 25 years, LRC has been committed to the same core values which have defined the Group’s goals and culture.
                  </p>
                  <a>values</a>
                </div>
              </div>


            </div>
            <div className={styles.gridItems}>
              <div className={styles.subGrid}>
                <div className={styles.subGridItems}>
                  <div className={styles.iconBoxDesign}>
                    <img src={CombinedIcon} alt="CombinedIcon" className="flipImage" data-scroll data-scroll-repeat />
                  </div>
                  {/* <h1>Decades of expertise</h1> */}
                  <h1
                    className="c-header_title o-h1"
                    data-scroll data-scroll-speed="0.5" data-scroll-repeat
                  >
                    <span
                      className={classNames("c-header_title_line", {
                        "is-reveal": reveal,
                      })}
                    >
                      <span
                        data-scroll
                        data-scroll-speed="1"
                        data-scroll-repeat
                        data-scroll-position="top"

                      >
                        Decades of expertise{" "}
                      </span>
                    </span>
                  </h1>
                  <p data-scroll data-scroll-speed="1">
                    We bring a combined experience of over 125 years in international property and finance markets to the table.
                  </p>
                </div>
                <div className={styles.subGridItems}>
                  <div className={styles.iconBoxDesign}>
                    <img src={DiamondIcon} alt="DiamondIcon" className="flipImage" data-scroll data-scroll-repeat />
                  </div>

                  <h1
                    className="c-header_title o-h1"
                    data-scroll data-scroll-speed="0.5" data-scroll-repeat
                  >
                    <span
                      className={classNames("c-header_title_line", {
                        "is-reveal": reveal,
                      })}
                    >
                      <span
                        data-scroll
                        data-scroll-speed="1"
                        data-scroll-repeat
                        data-scroll-position="top"

                      >
                        Entrepreneurship{" "}
                      </span>
                    </span>
                  </h1>
                  <p data-scroll data-scroll-speed="1">
                    We take strategic decisions and use our team’s intuition and skillful capabilities to seize opportunities.
                  </p>
                </div>
                <div className={styles.subGridItems}>
                  <div className={styles.iconBoxDesign}>
                    <img src={ContentIcon} alt="EarthIcon" className="flipImage" data-scroll data-scroll-repeat />
                  </div>

                  <h1
                    className="c-header_title o-h1"
                    data-scroll data-scroll-speed="0.5" data-scroll-repeat
                  >
                    <span
                      className={classNames("c-header_title_line", {
                        "is-reveal": reveal,
                      })}
                    >
                      <span
                        data-scroll
                        data-scroll-speed="1"
                        data-scroll-repeat
                        data-scroll-position="top"

                      >
                        Transparency{" "}
                      </span>
                    </span>
                  </h1>
                  <p data-scroll data-scroll-speed="1">
                    We believe integrity, authenticity and honesty are key for establishing long-term business practises and relationships.
                  </p>
                </div>
                <div className={styles.subGridItems}>
                  <div className={styles.iconBoxDesign}>
                    <img src={DeployIcon} alt="DeployIcon" className="flipImage" data-scroll data-scroll-repeat />
                  </div>

                  <h1
                    className="c-header_title o-h1"
                    data-scroll data-scroll-speed="0.5" data-scroll-repeat
                  >
                    <span
                      className={classNames("c-header_title_line", {
                        "is-reveal": reveal,
                      })}
                    >
                      <span
                        data-scroll
                        data-scroll-speed="1"
                        data-scroll-repeat
                        data-scroll-position="top"

                      >
                        Innovative approach{" "}
                      </span>
                    </span>
                  </h1>
                  <p data-scroll data-scroll-speed="1">
                    We aim to always be ahead of the curve, utilising innovative technologies and forward thinking.
                  </p>
                </div>
                <div className={styles.subGridItems}>
                  <div className={styles.iconBoxDesign}>
                    <img src={ChartIcon} alt="ChartIcon" className="flipImage" data-scroll data-scroll-repeat />
                  </div>

                  <h1
                    className="c-header_title o-h1"
                    data-scroll data-scroll-speed="0.5" data-scroll-repeat
                  >
                    <span
                      className={classNames("c-header_title_line", {
                        "is-reveal": reveal,
                      })}
                    >
                      <span
                        data-scroll
                        data-scroll-speed="1"
                        data-scroll-repeat
                        data-scroll-position="top"

                      >
                        Sustainability{" "}
                      </span>
                    </span>
                  </h1>
                  <p data-scroll data-scroll-speed="1">
                    We introduce and encourage a new way of consuming sustainable and environmentally friendly products.
                  </p>
                </div>
                <div className={styles.subGridItems}>
                  <div className={styles.iconBoxDesign}>
                    <img src={EarthIcon} alt="ContentIcon" className="flipImage" data-scroll data-scroll-repeat />
                  </div>

                  <h1
                    className="c-header_title o-h1"
                    data-scroll data-scroll-speed="0.5" data-scroll-repeat
                  >
                    <span
                      className={classNames("c-header_title_line", {
                        "is-reveal": reveal,
                      })}
                    >
                      <span
                        data-scroll
                        data-scroll-speed="1"
                        data-scroll-repeat
                        data-scroll-position="top"

                      >
                        Impact investing{" "}
                      </span>
                    </span>
                  </h1>
                  <p data-scroll data-scroll-speed="1">
                    We strive to create responsible business decisions in order to drive positive impact on communities.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
