import React, { useEffect, useState, useRef } from "react";
import styles from './Scale.module.scss';
import BlackHeader from '../Layout/BlackHeader/BlackHeader';
import ScaleBanner from '../../Assets/Images/scale-banner.png';
import TechnolofyIncrease from './TechnolofyIncrease/TechnolofyIncrease';
import RapidlyExpanding from './RapidlyExpanding/RapidlyExpanding';
import UrbenRegeneration from '../CommunityPage/UrbenRegeneration/UrbenRegeneration';
import Footer from '../Layout/Footer/Footer';
import classNames from 'classnames';
import useLocoScroll from '../../hooks/useLocoScroll';
import useOnScreen from "../../hooks/useOnScreen";
import MetaDecorator from "../Util/MetaDecorator";
export default function Scale() {
    useLocoScroll(true)
    const ref = useRef(null);

    const [reveal, setReveal] = useState(false);
    const onScreen = useOnScreen(ref);

    useEffect(() => {
        if (onScreen) setReveal(onScreen);
    }, [onScreen]);
    return (
        <>

            <div className="sticky-header">
                <BlackHeader />
            </div>
            <div id="main-container" data-scroll-container>
                <MetaDecorator title={"LRC Group | Scale"} description={"LRC uses the leverage of scale to allow cost-effective solutions across its real estate ecosystem. With 15k residential units under management, LRC is one of the largest PRS platforms in Europe. "} />
                <section className={styles.ScaleAlignment} data-scroll-section>
                    <div className='long-container'>
                        <div className={styles.pageTitle}>
                            <h1 className="c-header_title o-h1" data-scroll data-scroll-speed="3" data-scroll-position="top" data-scroll-repeat>
                                <span className={classNames("c-header_title_line", { "is-reveal": reveal })}>
                                    <span data-scroll data-scroll-speed="3" data-scroll-position="top" data-scroll-repeat>Transforming Real Estate through </span>
                                    <span className="italic-class" data-scroll data-scroll-speed="3" data-scroll-position="top" data-scroll-repeat>Scale </span>

                                </span>
                            </h1>
                        </div>
                    </div>
                </section>
                <section className={styles.ScaleBanner} data-scroll-section>
                    <div className={classNames(styles.scaleBannerSpaceRemove, 'long-container')}>
                        <img src={ScaleBanner} alt="ScaleBanner" className="flipImage" data-scroll data-scroll-repeat />
                    </div>
                </section>
                <>
                    <TechnolofyIncrease />
                </>
                <>
                    <RapidlyExpanding />
                </>
                <>
                    <UrbenRegeneration />
                </>
                <>
                    <Footer />
                </>
                <div className="footerBottomAlignment"></div>
            </div>
        </>
    )
}
