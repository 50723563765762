import React from "react";
import classNames from "classnames";
import styles from "./Timeline.module.scss";
export default function Timeline() {

  return (
    <div data-scroll-section>
      <div className="container">
        <div className={styles.timelineCenterAlignment}>
          <div className={styles.timeline}>
            <div className={classNames(styles.timelineContainer, styles.left)}>
              <div className={styles.content} data-scroll data-scroll-speed="1">
                <h2>1995</h2>
                <p>Founded in London with a focus on residential real estate</p>
              </div>
            </div>
            <div className={classNames(styles.timelineContainer, styles.right)}>
              <div className={styles.content} data-scroll data-scroll-speed="1">
                <h2>2005</h2>
                <p >
                  Expansion into Germany with commercial real estate valued €4bn
                </p>
              </div>
            </div>
            <div className={classNames(styles.timelineContainer, styles.left)}>
              <div className={styles.content} data-scroll data-scroll-speed="1">
                <h2 >2015</h2>


                <p>
                  Acquisition of a €600m commercial portfolio in the
                  Netherlands, with extension of the portfolio to a value of
                  €1bn
                </p>
              </div>
            </div>
            <div className={classNames(styles.timelineContainer, styles.right)}>
              <div className={styles.content} data-scroll data-scroll-speed="1">
                <h2>2016</h2>
                <ul >
                  <li>Acquisition of commercial assets in Poland</li>
                  <li>Acquisition of residential assets in Ireland</li>
                </ul>
              </div>
            </div>
            <div className={classNames(styles.timelineContainer, styles.left)}>
              <div className={styles.content} data-scroll data-scroll-speed="1">
                <h2>2017</h2>
                <p>Acquisition of residential assets in the UK & Ireland</p>
              </div>
            </div>
            <div className={classNames(styles.timelineContainer, styles.right)}>
              <div className={styles.content} data-scroll data-scroll-speed="1">
                <h2>2018</h2>
                <ul>
                  <li>Acquisition of residential assets in Poland </li>
                  <li>Launch of Vonder in Germany </li>
                  <li>Acquisition of hospitality portfolios in the UK</li>
                  <li>Launch of Amaris Hospitality</li>
                </ul>
              </div>
            </div>
            <div className={classNames(styles.timelineContainer, styles.left)}>
              <div className={styles.content} data-scroll data-scroll-speed="1">
                <h2>2019</h2>
                <ul>
                  <li>Exit from the Netherlands through portfolio sale</li>
                  <li>
                    Acquisition of a commercial portfolio in France for €400m
                  </li>
                  <li>Launch of Vonder in the UK</li>
                </ul>
              </div>
            </div>
            <div className={classNames(styles.timelineContainer, styles.right)}>
              <div className={styles.content} data-scroll data-scroll-speed="1">
                <h2>2021</h2>
                <ul>
                  <li>
                    Expansion in the UK with a €1bn residential portfolio{" "}
                  </li>
                  <li>Launch of Vonder in Poland & Dubai</li>
                </ul>
              </div>
            </div>
            <div className={classNames(styles.timelineContainer, styles.left, styles.contentspaceRemove)}>
              <div className={classNames(styles.content, styles.contentspaceRemove1)} data-scroll data-scroll-speed="1">
                <h2>2022</h2>
                <ul>
                  <p>
                    Continued expansion of UK and Ireland residential portfolios
                  </p>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
