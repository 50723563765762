import React, { useEffect, useState, useRef } from "react";
import styles from './RemovingFriction.module.scss';
import RemovingFrictionImage from '../../../Assets/Images/removing-friction.png';
import useOnScreen from "../../../hooks/useOnScreen";
import classNames from "classnames";
export default function RemovingFriction() {
    const ref = useRef(null);

    const [reveal, setReveal] = useState(false);
    const onScreen = useOnScreen(ref);

    useEffect(() => {
        if (onScreen) setReveal(onScreen);
    }, [onScreen]);
    return (
        <div data-scroll-section>
            <section className={styles.RemovingFrictionAlignment}>
                <div className='container'>
                    <div className={styles.grid}>
                        <div className={styles.gridItems}>
                            <h1><span className="italic-class">Removing friction </span>with technology</h1>

                            <p >
                                Digitalisation of processes enables a seamless experience. We reduce hassle for both tenants and landlords with smart technological solutions, from digital dashboards for landlords to
                                a mobile app for tenants and an online rewards scheme offering lifestyle perks and services.
                            </p>
                        </div>
                        <div className={styles.gridItems}>
                            <img src={RemovingFrictionImage} alt="RemovingFrictionImage" className="flipImage" data-scroll data-scroll-repeat />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
