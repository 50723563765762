import React, { useEffect, useState, useRef } from "react";
import classNames from 'classnames';
import useOnScreen from "../../../hooks/useOnScreen";
import styles from './CommunityBlog.module.scss';
import ImproveImage from '../../../Assets/Images/improve.png';
import IntergrationImage from '../../../Assets/Images/intergration.png';
export default function CommunityBlog() {
    const ref = useRef(null);

    const [reveal, setReveal] = useState(false);
    const onScreen = useOnScreen(ref);

    useEffect(() => {
        if (onScreen) setReveal(onScreen);
    }, [onScreen]);
    return (
        <div data-scroll-section>
            <section className={styles.CommunityBlogAlign}>
                <div className='container'>
                    <div className={styles.grid}>
                        <div className={styles.gridItems}>
                            <img src={ImproveImage} alt="ImproveImage" className="flipImage" data-scroll data-scroll-repeat />
                        </div>
                        <div className={styles.gridItems}>
                            <a >#BELOCAL</a>
                            <h1>Empowering communities through LRC platforms</h1>

                            <div >
                                <p>
                                    LRC’s products and platforms are built around communities. The group develops healthy,
                                    resilient communities through dedication to social, economic and environmental sustainability.
                                </p>
                                <p>
                                    Once tenants join LRC’s platforms, they are turned into locals. LRC offers invaluable opportunities to grow and connect, with a variety of value-added services right on their doorstep.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.subGrid}>
                        <div className={styles.subGridItems}>
                            <a >#BELOCAL</a>
                            <h1>Integrating communities with local businesses</h1>

                            <div >
                                <p>
                                    LRC places a major focus on integrating communities with local businesses and bringing assets back to the communities.
                                </p>
                                <p>
                                    Local businesses build stronger communities within LRC’s properties. LRC believes relationships between businesses are important in creating a cohesive, welcoming community identity.
                                </p>
                            </div>
                        </div>
                        <div className={styles.subGridItems}>
                            <img src={IntergrationImage} alt="IntergrationImage" className="flipImage" data-scroll data-scroll-repeat />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
