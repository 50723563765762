import React from "react";
import BlackHeader from "../Layout/BlackHeader/BlackHeader";
import Footer from "../Layout/Footer/Footer";

export default function PAI() {
  return (
    <>
      <div className="sticky-header">
        <BlackHeader />
      </div>
      <div
        id="main-container"
        style={{
          maxWidth: "1200px",
          padding: "120px 0",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <h1
          style={{
            paddingTop: "9pt",
            textIndent: "0pt",
            textAlign: "center",
          }}
        >
          Statement<span className="s1"> </span>on<span className="s1"> </span>
          principal<span className="s1"> </span>adverse
          <span className="s1"> </span>
          impacts<span className="s1"> </span>of<span className="s1"> </span>
          investment<span className="s1"> </span>decisions
          <span className="s1"> </span>on<span className="s1"> </span>
          sustainability
          <span className="s1"> </span>factors
        </h1>
        <p style={{ textIndent: "0pt", textAlign: "left" }}>
          <br />
        </p>
        <p style={{ textIndent: "0pt", textAlign: "left" }} />
        <p style={{ textIndent: "0pt", textAlign: "left" }}>
          Financial<span className="s1"> </span>market
          <span className="s1"> </span>
          participant<span className="s1"> </span>-<span className="s1"> </span>
          Storton<span className="s1"> </span>Fund<span className="s1"> </span>
          Management<span className="s1"> </span>Ltd
          <span className="s1"> </span>
          [LEI
          <span className="s1"> </span>984500999A540457B545]
        </p>
        <h1
          style={{
            paddingTop: "6pt",
            textIndent: "0pt",
            textAlign: "left",
          }}
        >
          Summary
        </h1>
        <p
          className="s1"
          style={{
            paddingTop: "5pt",
            textIndent: "0pt",
            textAlign: "justify",
          }}
        >
          <span className="p">Storton</span> <span className="p">Fund</span>{" "}
          <span className="p">Management</span> <span className="p">Ltd</span>{" "}
          <span className="p">[LEI</span>{" "}
          <span className="p">984500999A540457B545]</span>{" "}
          <span className="p">considers</span>{" "}
          <span className="p">principal</span>{" "}
          <span className="p">adverse</span> <span className="p">impacts</span>{" "}
          <span className="p">of</span> <span className="p">its</span>{" "}
          <span className="p">investment</span>{" "}
          <span className="p">decisions</span> <span className="p">on</span>{" "}
          <span className="p">sustainability</span>{" "}
          <span className="p">factors.</span> <span className="p">The</span>{" "}
          <span className="p">present</span>{" "}
          <span className="p">statement</span> <span className="p">is</span>{" "}
          <span className="p">the</span> <span className="p">consolidated</span>{" "}
          <span className="p">statement</span> <span className="p">on</span>{" "}
          <span className="p">principal</span>{" "}
          <span className="p">adverse</span> <span className="p">impacts</span>{" "}
          <span className="p">on</span>{" "}
          <span className="p">sustainability</span>{" "}
          <span className="p">factors</span> <span className="p">of</span>{" "}
          <span className="p">Storton</span> <span className="p">Fund</span>{" "}
          <span className="p">Management</span> <span className="p">Ltd.</span>{" "}
          <span className="p">This</span> <span className="p">statement</span>{" "}
          <span className="p">on</span> <span className="p">principal</span>{" "}
          <span className="p">adverse</span> <span className="p">impacts</span>{" "}
          <span className="p">on</span>{" "}
          <span className="p">sustainability</span>{" "}
          <span className="p">factors</span> <span className="p">covers</span>{" "}
          <span className="p">the</span> <span className="p">reference</span>{" "}
          <span className="p">period</span> <span className="p">from</span>{" "}
          <span className="h1">1</span> <span className="h1">January</span>{" "}
          <span className="h1">2023</span> <span className="h1">to</span>{" "}
          <span className="h1">31</span> <span className="h1">December</span>{" "}
          <span className="h1">2023</span>
          <span className="p">.</span>
        </p>
        <p style={{ paddingTop: "12pt", textIndent: "0pt", textAlign: "left" }}>
          <br />
        </p>
        <p
          className="s2"
          style={{
            textIndent: "0pt",
            textAlign: "justify",
          }}
        >
          Storton<span className="s1"> </span>Fund<span className="s1"> </span>
          Management<span className="s1"> </span>Ltd
          <span className="s1"> </span>
          [LEI
          <span className="s1"> </span>S84500SSSA540457B545]
          <span className="s1"> </span>berücksichtigt
          <span className="s1"> </span>
          die
          <span className="s1"> </span>wichtigsten<span className="s1"> </span>
          negativen<span className="s1"> </span>Auswirkungen
          <span className="s1"> </span>seiner<span className="s1"> </span>
          Anlageentscheidungen<span className="s1"> </span>auf
          <span className="s1"> </span>Nachhaltigkeitsfaktoren.
          <span className="s1"> </span>Die<span className="s1"> </span>
          vorliegende
          <span className="s1"> </span>Erklärung<span className="s1"> </span>ist
          <span className="s1"> </span>die<span className="s1"> </span>
          konsolidierte
          <span className="s1"> </span>Erklärung<span className="s1"> </span>zu
          <span className="s1"> </span>den<span className="s1"> </span>
          wichtigsten
          <span className="s1"> </span>negativen<span className="s1"> </span>
          Auswirkungen<span className="s1"> </span>auf
          <span className="s1"> </span>
          Nachhaltigkeitsfaktoren<span className="s1"> </span>von
          <span className="s1"> </span>Storton<span className="s1"> </span>Fund
          <span className="s1"> </span>Management<span className="s1"> </span>
          Ltd.
          <span className="s1"> </span>Diese<span className="s1"> </span>
          Erklärung
          <span className="s1"> </span>zu<span className="s1"> </span>den
          <span className="s1"> </span>wichtigsten<span className="s1"> </span>
          negativen<span className="s1"> </span>Auswirkungen
          <span className="s1"> </span>auf<span className="s1"> </span>
          Nachhaltigkeitsfaktoren<span className="s1"> </span>deckt
          <span className="s1"> </span>den<span className="s1"> </span>
          Referenzzeitraum<span className="s1"> </span>vom
          <span className="s1"> </span>1.<span className="s1"> </span>Januar
          <span className="s1"> </span>2023<span className="s1"> </span>bis
          <span className="s1"> </span>zum<span className="s1"> </span>31.
          <span className="s1"> </span>Dezember<span className="s1"> </span>2023
          <span className="s1"> </span>ab.
        </p>
        <p style={{ paddingTop: "12pt", textIndent: "0pt", textAlign: "left" }}>
          <br />
        </p>
        <p
          className="s2"
          style={{
            textIndent: "0pt",
            textAlign: "justify",
          }}
        >
          Storton<span className="s1"> </span>Fund<span className="s1"> </span>
          Management<span className="s1"> </span>Ltd
          <span className="s1"> </span>
          [LEI
          <span className="s1"> </span>S84500SSSA540457B545]
          <span className="s1"> </span>considère<span className="s1"> </span>les
          <span className="s1"> </span>principaux<span className="s1"> </span>
          impacts
          <span className="s1"> </span>négatifs<span className="s1"> </span>de
          <span className="s1"> </span>ses<span className="s1"> </span>décisions
          <span className="s1"> </span>d'investissement
          <span className="s1"> </span>
          sur<span className="s1"> </span>les<span className="s1"> </span>
          facteurs
          <span className="s1"> </span>de<span className="s1"> </span>
          durabilité.
          <span className="s1"> </span>Le<span className="s1"> </span>présent
          <span className="s1"> </span>rapport<span className="s1"> </span>est
          <span className="s1"> </span>le<span className="s1"> </span>rapport
          <span className="s1"> </span>consolidé<span className="s1"> </span>sur
          <span className="s1"> </span>les<span className="s1"> </span>
          principaux
          <span className="s1"> </span>impacts<span className="s1"> </span>
          négatifs
          <span className="s1"> </span>sur<span className="s1"> </span>les
          <span className="s1"> </span>facteurs<span className="s1"> </span>de
          <span className="s1"> </span>durabilité<span className="s1"> </span>de
          <span className="s1"> </span>Storton<span className="s1"> </span>Fund
          <span className="s1"> </span>Management<span className="s1"> </span>
          Ltd.
          <span className="s1"> </span>Cette<span className="s1"> </span>
          déclaration
          <span className="s1"> </span>sur<span className="s1"> </span>les
          <span className="s1"> </span>principaux<span className="s1"> </span>
          impacts
          <span className="s1"> </span>négatifs<span className="s1"> </span>sur
          <span className="s1"> </span>les<span className="s1"> </span>facteurs
          <span className="s1"> </span>de<span className="s1"> </span>durabilité
          <span className="s1"> </span>couvre<span className="s1"> </span>la
          <span className="s1"> </span>période<span className="s1"> </span>de
          <span className="s1"> </span>référence<span className="s1"> </span>du
          <span className="s1"> </span>1er<span className="s1"> </span>janvier
          <span className="s1"> </span>2023<span className="s1"> </span>au
          <span className="s1"> </span>31<span className="s1"> </span>décembre
          <span className="s1"> </span>2023.
        </p>
        <p style={{ paddingTop: "12pt", textIndent: "0pt", textAlign: "left" }}>
          <br />
        </p>
        <div
          className="textbox"
          style={{
            background: "#F8F9FA",
            display: "block",
            minHeight: "48.9pt",
            width: "705.5pt",
          }}
        >
          <p className="s3" style={{ textIndent: "0pt", textAlign: "left" }}>
            Storton<span className="s4"> </span>Fund
            <span className="s4"> </span>
            Management<span className="s4"> </span>Ltd
            <span className="s4"> </span>
            [LEI<span className="s4"> </span>S84500SSSA540457B545]
            <span className="s4"> </span>considera<span className="s4"> </span>
            los
            <span className="s4"> </span>principales
            <span className="s4"> </span>
            impactos<span className="s4"> </span>adversos
            <span className="s4"> </span>
            de<span className="s4"> </span>sus<span className="s4"> </span>
            decisiones
            <span className="s4"> </span>de<span className="s4"> </span>
            inversión
            <span className="s4"> </span>en<span className="s4"> </span>los
            <span className="s4"> </span>factores<span className="s4"> </span>de
            <span className="s4"> </span>sostenibilidad.
            <span className="s4"> </span>
            La<span className="s4"> </span>presente<span className="s4"> </span>
            declaración<span className="s4"> </span>es
            <span className="s4"> </span>la
            <span className="s4"> </span>declaración
            <span className="s4"> </span>
            consolidada<span className="s4"> </span>sobre
            <span className="s4"> </span>
            los<span className="s4"> </span>principales
            <span className="s4"> </span>
            impactos<span className="s4"> </span>adversos
            <span className="s4"> </span>
            en<span className="s4"> </span>los<span className="s4"> </span>
            factores
            <span className="s4"> </span>de<span className="s4"> </span>
            sostenibilidad
            <span className="s4"> </span>de<span className="s4"> </span>Storton
            <span className="s4"> </span>Fund<span className="s4"> </span>
            Management
            <span className="s4"> </span>Ltd.<span className="s4"> </span>Esta
            <span className="s4"> </span>declaración
            <span className="s4"> </span>
            sobre
            <span className="s4"> </span>los<span className="s4"> </span>
            principales
            <span className="s4"> </span>impactos<span className="s4"> </span>
            adversos
            <span className="s4"> </span>en<span className="s4"> </span>los
            <span className="s4"> </span>factores<span className="s4"> </span>de
            <span className="s4"> </span>sostenibilidad
            <span className="s4"> </span>
            cubre<span className="s4"> </span>el<span className="s4"> </span>
            período
            <span className="s4"> </span>de<span className="s4"> </span>
            referencia
            <span className="s4"> </span>del<span className="s4"> </span>1
            <span className="s4"> </span>de<span className="s4"> </span>enero
            <span className="s4"> </span>de<span className="s4"> </span>2023
            <span className="s4"> </span>al<span className="s4"> </span>31
            <span className="s4"> </span>de<span className="s4"> </span>
            diciembre
            <span className="s4"> </span>de<span className="s4"> </span>2023.
          </p>
        </div>
        <p style={{ textIndent: "0pt", textAlign: "left" }} />
        <p style={{ textIndent: "0pt", textAlign: "left" }}>
          <br />
        </p>
        <table
          style={{ borderCollapse: "collapse", marginLeft: "5.88064pt" }}
          cellSpacing={0}
        >
          <tbody>
            <tr style={{ height: "19pt" }}>
              <th
                style={{
                  width: "714pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
                colSpan={9}
              >
                <p
                  className="s5"
                  style={{
                    paddingTop: "5pt",
                    textIndent: "0pt",
                    lineHeight: "11pt",
                    textAlign: "left",
                  }}
                >
                  Description<span className="s4"> </span>of
                  <span className="s4"> </span>the<span className="s4"> </span>
                  principal<span className="s4"> </span>adverse
                  <span className="s4"> </span>impacts
                  <span className="s4"> </span>on
                  <span className="s4"> </span>sustainability
                  <span className="s4"> </span>factors
                </p>
              </th>
            </tr>
            <tr style={{ height: "55pt" }}>
              <th
                style={{
                  width: "6pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </th>
              <th
                style={{
                  width: "702pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
                colSpan={7}
              >
                <p
                  style={{
                    paddingTop: "11pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  <br />
                </p>
                <p
                  className="s5"
                  style={{ textIndent: "0pt", textAlign: "center" }}
                >
                  Indicators<span className="s4"> </span>applicable
                  <span className="s4"> </span>to<span className="s4"> </span>
                  investments<span className="s4"> </span>in
                  <span className="s4"> </span>investee
                  <span className="s4"> </span>
                  companies
                </p>
              </th>
              <td
                style={{
                  width: "6pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
            </tr>
            <tr style={{ height: "104pt" }}>
              <th
                style={{
                  width: "6pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </th>
              <th
                style={{
                  width: "214pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
                colSpan={2}
              >
                <p
                  className="s5"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "35pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Adverse<span className="s4"> </span>sustainability
                  <span className="s4"> </span>indicator
                </p>
              </th>
              <th
                style={{
                  width: "162pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s5"
                  style={{
                    paddingTop: "5pt",
                    textIndent: "0pt",
                    textAlign: "center",
                  }}
                >
                  Metric
                </p>
              </th>
              <th
                style={{
                  width: "89pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s5"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "16pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Impact<span className="s4"> </span>2023
                </p>
              </th>
              <th
                style={{
                  width: "86pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s5"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "15pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Impact<span className="s4"> </span>2022
                </p>
              </th>
              <th
                style={{
                  width: "78pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s5"
                  style={{
                    paddingTop: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Explanation
                </p>
              </th>
              <th
                style={{
                  width: "73pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s5"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "6pt",
                    paddingRight: "6pt",
                    textIndent: "0pt",
                    textAlign: "center",
                  }}
                >
                  Actions<span className="s4"> </span>taken,
                  <span className="s4"> </span>and<span className="s4"> </span>
                  actions
                  <span className="s4"> </span>planned
                  <span className="s4"> </span>and
                  <span className="s4"> </span>targets
                  <span className="s4"> </span>set
                  <span className="s4"> </span>for<span className="s4"> </span>
                  the
                  <span className="s4"> </span>next<span className="s4"> </span>
                  reference
                </p>
                <p
                  className="s5"
                  style={{
                    paddingLeft: "6pt",
                    paddingRight: "6pt",
                    textIndent: "0pt",
                    lineHeight: "11pt",
                    textAlign: "center",
                  }}
                >
                  period
                </p>
              </th>
              <td
                style={{
                  width: "6pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
            </tr>
            <tr style={{ height: "55pt" }}>
              <td
                style={{
                  width: "6pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
              <th
                style={{
                  width: "702pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
                colSpan={7}
              >
                <p
                  style={{
                    paddingTop: "11pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  <br />
                </p>
                <p
                  className="s5"
                  style={{ textIndent: "0pt", textAlign: "center" }}
                >
                  CLIMATE<span className="s4"> </span>AND
                  <span className="s4"> </span>
                  OTHER<span className="s4"> </span>ENVIRONMENT-RELATED
                  <span className="s4"> </span>INDICATORS
                </p>
              </th>
              <td
                style={{
                  width: "6pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
            </tr>
            <tr style={{ height: "37pt" }}>
              <td
                style={{
                  width: "6pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
                rowSpan={7}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
              <td
                style={{
                  width: "86pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
                rowSpan={7}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Greenhouse<span className="s4"> </span>gas
                  <span className="s4"> </span>emissions
                </p>
              </td>
              <td
                style={{
                  width: "128pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
                rowSpan={4}
              >
                <p
                  className="s6"
                  style={{
                    paddingLeft: "23pt",
                    textIndent: "0pt",
                    lineHeight: "12pt",
                    textAlign: "left",
                  }}
                >
                  1.<span className="s4"> </span>GHG
                  <span className="s4"> </span>
                  emissions
                </p>
              </td>
              <td
                style={{
                  width: "162pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Scope<span className="s4"> </span>1
                  <span className="s4"> </span>
                  GHG
                  <span className="s4"> </span>emissions
                </p>
              </td>
              <td
                style={{
                  width: "89pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  0%
                </p>
              </td>
              <td
                style={{
                  width: "86pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  0%
                </p>
              </td>
              <td
                style={{
                  width: "78pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  N/A
                </p>
              </td>
              <td
                style={{
                  width: "73pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  N/A
                </p>
              </td>
              <td
                style={{
                  width: "6pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
            </tr>
            <tr style={{ height: "18pt" }}>
              <td
                style={{
                  width: "162pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "11pt",
                    textAlign: "left",
                  }}
                >
                  Scope<span className="s4"> </span>2
                  <span className="s4"> </span>
                  GHG
                  <span className="s4"> </span>emissions
                </p>
              </td>
              <td
                style={{
                  width: "89pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "11pt",
                    textAlign: "left",
                  }}
                >
                  0%
                </p>
              </td>
              <td
                style={{
                  width: "86pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "11pt",
                    textAlign: "left",
                  }}
                >
                  0%
                </p>
              </td>
              <td
                style={{
                  width: "78pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "11pt",
                    textAlign: "left",
                  }}
                >
                  N/A
                </p>
              </td>
              <td
                style={{
                  width: "73pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "11pt",
                    textAlign: "left",
                  }}
                >
                  N/A
                </p>
              </td>
              <td
                style={{
                  width: "6pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
            </tr>
            <tr style={{ height: "18pt" }}>
              <td
                style={{
                  width: "162pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "11pt",
                    textAlign: "left",
                  }}
                >
                  Scope<span className="s4"> </span>3
                  <span className="s4"> </span>
                  GHG
                  <span className="s4"> </span>emissions
                </p>
              </td>
              <td
                style={{
                  width: "89pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "11pt",
                    textAlign: "left",
                  }}
                >
                  0%
                </p>
              </td>
              <td
                style={{
                  width: "86pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "11pt",
                    textAlign: "left",
                  }}
                >
                  0%
                </p>
              </td>
              <td
                style={{
                  width: "78pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "11pt",
                    textAlign: "left",
                  }}
                >
                  N/A
                </p>
              </td>
              <td
                style={{
                  width: "73pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "11pt",
                    textAlign: "left",
                  }}
                >
                  N/A
                </p>
              </td>
              <td
                style={{
                  width: "6pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
            </tr>
            <tr style={{ height: "18pt" }}>
              <td
                style={{
                  width: "162pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "11pt",
                    textAlign: "left",
                  }}
                >
                  Total<span className="s4"> </span>GHG
                  <span className="s4"> </span>
                  emissions
                </p>
              </td>
              <td
                style={{
                  width: "89pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "11pt",
                    textAlign: "left",
                  }}
                >
                  0%
                </p>
              </td>
              <td
                style={{
                  width: "86pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "11pt",
                    textAlign: "left",
                  }}
                >
                  0%
                </p>
              </td>
              <td
                style={{
                  width: "78pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "11pt",
                    textAlign: "left",
                  }}
                >
                  N/A
                </p>
              </td>
              <td
                style={{
                  width: "73pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "11pt",
                    textAlign: "left",
                  }}
                >
                  N/A
                </p>
              </td>
              <td
                style={{
                  width: "6pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
            </tr>
            <tr style={{ height: "18pt" }}>
              <td
                style={{
                  width: "128pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingLeft: "23pt",
                    textIndent: "0pt",
                    lineHeight: "12pt",
                    textAlign: "left",
                  }}
                >
                  2.<span className="s4"> </span>Carbon
                  <span className="s4"> </span>
                  footprint
                </p>
              </td>
              <td
                style={{
                  width: "162pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "11pt",
                    textAlign: "left",
                  }}
                >
                  Carbon<span className="s4"> </span>footprint
                </p>
              </td>
              <td
                style={{
                  width: "89pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "11pt",
                    textAlign: "left",
                  }}
                >
                  0%
                </p>
              </td>
              <td
                style={{
                  width: "86pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "11pt",
                    textAlign: "left",
                  }}
                >
                  0%
                </p>
              </td>
              <td
                style={{
                  width: "78pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "11pt",
                    textAlign: "left",
                  }}
                >
                  N/A
                </p>
              </td>
              <td
                style={{
                  width: "73pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "11pt",
                    textAlign: "left",
                  }}
                >
                  N/A
                </p>
              </td>
              <td
                style={{
                  width: "6pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
            </tr>
            <tr style={{ height: "37pt" }}>
              <td
                style={{
                  width: "128pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  3.<span className="s4"> </span>GHG
                  <span className="s4"> </span>
                  intensity<span className="s4"> </span>of
                  <span className="s4"> </span>investee
                </p>
                <p
                  className="s6"
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "0pt",
                    lineHeight: "11pt",
                    textAlign: "left",
                  }}
                >
                  companies
                </p>
              </td>
              <td
                style={{
                  width: "162pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    paddingRight: "2pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  GHG<span className="s4"> </span>intensity
                  <span className="s4"> </span>of<span className="s4"> </span>
                  investee
                  <span className="s4"> </span>companies
                </p>
              </td>
              <td
                style={{
                  width: "89pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  0%
                </p>
              </td>
              <td
                style={{
                  width: "86pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  0%
                </p>
              </td>
              <td
                style={{
                  width: "78pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  N/A
                </p>
              </td>
              <td
                style={{
                  width: "73pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  N/A
                </p>
              </td>
              <td
                style={{
                  width: "6pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
            </tr>
            <tr style={{ height: "49pt" }}>
              <td
                style={{
                  width: "128pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingLeft: "41pt",
                    paddingRight: "10pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  4.<span className="s4"> </span>Exposure
                  <span className="s4"> </span>
                  to<span className="s4"> </span>companies
                  <span className="s4"> </span>active
                  <span className="s4"> </span>
                  in
                  <span className="s4"> </span>the<span className="s4"> </span>
                  fossil
                  <span className="s4"> </span>fuel
                </p>
                <p
                  className="s6"
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "0pt",
                    lineHeight: "12pt",
                    textAlign: "left",
                  }}
                >
                  sector
                </p>
              </td>
              <td
                style={{
                  width: "162pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Share<span className="s4"> </span>of
                  <span className="s4"> </span>
                  investments<span className="s4"> </span>in
                  <span className="s4"> </span>companies
                  <span className="s4"> </span>
                  active<span className="s4"> </span>in
                  <span className="s4"> </span>
                  the<span className="s4"> </span>fossil
                  <span className="s4"> </span>
                  fuel<span className="s4"> </span>sector
                </p>
              </td>
              <td
                style={{
                  width: "89pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  0%
                </p>
              </td>
              <td
                style={{
                  width: "86pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  0%
                </p>
              </td>
              <td
                style={{
                  width: "78pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  N/A
                </p>
              </td>
              <td
                style={{
                  width: "73pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  N/A
                </p>
              </td>
              <td
                style={{
                  width: "6pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p style={{ textIndent: "0pt", textAlign: "left" }}>
          <br />
        </p>
        <table
          style={{ borderCollapse: "collapse", marginLeft: "5.88064pt" }}
          cellSpacing={0}
        >
          <tbody>
            <tr style={{ height: "104pt" }}>
              <td
                style={{
                  width: "6pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
                rowSpan={2}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
              <td
                style={{
                  width: "86pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
                rowSpan={2}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
              <td
                style={{
                  width: "128pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  5.<span className="s4"> </span>Share
                  <span className="s4"> </span>of
                  <span className="s4"> </span>non-<span className="s4"> </span>
                  renewable<span className="s4"> </span>energy
                  <span className="s4"> </span>consumption
                  <span className="s4"> </span>and<span className="s4"> </span>
                  production
                </p>
              </td>
              <td
                style={{
                  width: "162pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    paddingRight: "4pt",
                    textIndent: "0pt",
                    textAlign: "justify",
                  }}
                >
                  Share<span className="s4"> </span>of
                  <span className="s4"> </span>
                  non-renewable<span className="s4"> </span>energy
                  <span className="s4"> </span>consumption
                  <span className="s4"> </span>and<span className="s4"> </span>
                  non-renewable<span className="s4"> </span>energy
                  <span className="s4"> </span>production
                  <span className="s4"> </span>
                  of<span className="s4"> </span>investee
                  <span className="s4"> </span>
                  companies<span className="s4"> </span>from
                  <span className="s4"> </span>non-renewable
                  <span className="s4"> </span>energy
                  <span className="s4"> </span>
                  sources<span className="s4"> </span>compared
                  <span className="s4"> </span>to<span className="s4"> </span>
                  renewable<span className="s4"> </span>energy
                  <span className="s4"> </span>sources,
                  <span className="s4"> </span>
                  expressed<span className="s4"> </span>as
                  <span className="s4"> </span>a<span className="s4"> </span>
                  percentage<span className="s4"> </span>of
                  <span className="s4"> </span>total
                </p>
                <p
                  className="s6"
                  style={{
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "11pt",
                    textAlign: "justify",
                  }}
                >
                  energy<span className="s4"> </span>sources
                </p>
              </td>
              <td
                style={{
                  width: "89pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  0%
                </p>
              </td>
              <td
                style={{
                  width: "86pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  0%
                </p>
              </td>
              <td
                style={{
                  width: "78pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  N/A
                </p>
              </td>
              <td
                style={{
                  width: "73pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  N/A
                </p>
              </td>
              <td
                style={{
                  width: "6pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
            </tr>
            <tr style={{ height: "61pt" }}>
              <td
                style={{
                  width: "128pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingLeft: "41pt",
                    paddingRight: "10pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  6.<span className="s4"> </span>Energy
                  <span className="s4"> </span>
                  consumption<span className="s4"> </span>intensity
                  <span className="s4"> </span>per<span className="s4"> </span>
                  high
                  <span className="s4"> </span>impact
                  <span className="s4"> </span>
                  climate
                </p>
                <p
                  className="s6"
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "0pt",
                    lineHeight: "11pt",
                    textAlign: "left",
                  }}
                >
                  sector
                </p>
              </td>
              <td
                style={{
                  width: "162pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    paddingRight: "4pt",
                    textIndent: "0pt",
                    textAlign: "justify",
                  }}
                >
                  Energy<span className="s4"> </span>consumption
                  <span className="s4"> </span>in<span className="s4"> </span>
                  GWh
                  <span className="s4"> </span>per<span className="s4"> </span>
                  million
                  <span className="s4"> </span>EUR<span className="s4"> </span>
                  of
                  <span className="s4"> </span>revenue
                  <span className="s4"> </span>of
                  <span className="s4"> </span>investee
                  <span className="s4"> </span>
                  companies,<span className="s4"> </span>per
                  <span className="s4"> </span>high<span className="s4"> </span>
                  impact
                  <span className="s4"> </span>climate
                  <span className="s4"> </span>
                  sector
                </p>
              </td>
              <td
                style={{
                  width: "89pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  0%
                </p>
              </td>
              <td
                style={{
                  width: "86pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  0%
                </p>
              </td>
              <td
                style={{
                  width: "78pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  N/A
                </p>
              </td>
              <td
                style={{
                  width: "73pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  N/A
                </p>
              </td>
              <td
                style={{
                  width: "6pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
            </tr>
            <tr style={{ height: "80pt" }}>
              <td
                style={{
                  width: "6pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
              <td
                style={{
                  width: "86pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Biodiversity
                </p>
              </td>
              <td
                style={{
                  width: "128pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingLeft: "23pt",
                    textIndent: "0pt",
                    lineHeight: "12pt",
                    textAlign: "left",
                  }}
                >
                  7.<span className="s4"> </span>Activities
                </p>
                <p
                  className="s6"
                  style={{
                    paddingLeft: "41pt",
                    paddingRight: "21pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  negatively<span className="s4"> </span>affecting
                  <span className="s4"> </span>biodiversity-
                  <span className="s4"> </span>sensitive
                  <span className="s4"> </span>
                  areas
                </p>
              </td>
              <td
                style={{
                  width: "162pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    paddingRight: "4pt",
                    textIndent: "0pt",
                    textAlign: "justify",
                  }}
                >
                  Share<span className="s4"> </span>of
                  <span className="s4"> </span>
                  investments<span className="s4"> </span>in
                  <span className="s4"> </span>investee
                  <span className="s4"> </span>
                  companies<span className="s4"> </span>with
                  <span className="s4"> </span>sites/operations
                  <span className="s4"> </span>located
                  <span className="s4"> </span>in
                  <span className="s4"> </span>or<span className="s4"> </span>
                  near
                  <span className="s4"> </span>to<span className="s4"> </span>
                  biodiversity-<span className="s4"> </span>sensitive
                  <span className="s4"> </span>areas
                  <span className="s4"> </span>
                  where
                  <span className="s4"> </span>activities
                  <span className="s4"> </span>
                  of<span className="s4"> </span>those
                  <span className="s4"> </span>
                  investee<span className="s4"> </span>companies
                </p>
                <p
                  className="s6"
                  style={{
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "11pt",
                    textAlign: "justify",
                  }}
                >
                  negatively<span className="s4"> </span>affect
                  <span className="s4"> </span>those
                  <span className="s4"> </span>
                  areas
                </p>
              </td>
              <td
                style={{
                  width: "89pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  0%
                </p>
              </td>
              <td
                style={{
                  width: "86pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  0%
                </p>
              </td>
              <td
                style={{
                  width: "78pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  N/A
                </p>
              </td>
              <td
                style={{
                  width: "73pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  N/A
                </p>
              </td>
              <td
                style={{
                  width: "6pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
            </tr>
            <tr style={{ height: "55pt" }}>
              <td
                style={{
                  width: "6pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
              <td
                style={{
                  width: "86pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Water
                </p>
              </td>
              <td
                style={{
                  width: "128pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingLeft: "41pt",
                    paddingRight: "21pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  8.<span className="s4"> </span>Emissions
                  <span className="s4"> </span>to<span className="s4"> </span>
                  water
                </p>
              </td>
              <td
                style={{
                  width: "162pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    paddingRight: "4pt",
                    textIndent: "0pt",
                    textAlign: "justify",
                  }}
                >
                  Tonnes<span className="s4"> </span>of
                  <span className="s4"> </span>
                  emissions<span className="s4"> </span>to
                  <span className="s4"> </span>water
                  <span className="s4"> </span>
                  generated<span className="s4"> </span>by
                  <span className="s4"> </span>investee
                  <span className="s4"> </span>
                  companies<span className="s4"> </span>per
                  <span className="s4"></span>million
                  <span className="s4"></span>
                  EUR
                  <span className="s4"></span>invested,
                </p>
                <p
                  className="s6"
                  style={{
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "11pt",
                    textAlign: "justify",
                  }}
                >
                  expressed<span className="s4"> </span>as
                  <span className="s4"> </span>a<span className="s4"> </span>
                  weighted
                  <span className="s4"> </span>average
                </p>
              </td>
              <td
                style={{
                  width: "89pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  0%
                </p>
              </td>
              <td
                style={{
                  width: "86pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  0%
                </p>
              </td>
              <td
                style={{
                  width: "78pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  N/A
                </p>
              </td>
              <td
                style={{
                  width: "73pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  N/A
                </p>
              </td>
              <td
                style={{
                  width: "6pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
            </tr>
            <tr style={{ height: "68pt" }}>
              <td
                style={{
                  width: "6pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
              <td
                style={{
                  width: "86pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Waste
                </p>
              </td>
              <td
                style={{
                  width: "128pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingLeft: "41pt",
                    paddingRight: "10pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  9.<span className="s4"> </span>Hazardous
                  <span className="s4"> </span>waste
                  <span className="s4"> </span>
                  and
                  <span className="s4"> </span>radioactive
                  <span className="s4"> </span>waste
                  <span className="s4"> </span>
                  ratio
                </p>
              </td>
              <td
                style={{
                  width: "162pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    paddingRight: "4pt",
                    textIndent: "0pt",
                    textAlign: "justify",
                  }}
                >
                  Tonnes<span className="s4"> </span>of
                  <span className="s4"> </span>
                  hazardous<span className="s4"> </span>waste
                  <span className="s4"> </span>and<span className="s4"> </span>
                  radioactive<span className="s4"> </span>waste
                  <span className="s4"> </span>generated
                  <span className="s4"> </span>
                  by<span className="s4"> </span>investee
                  <span className="s4"> </span>
                  companies<span className="s4"> </span>per
                  <span className="s4"> </span>million
                  <span className="s4"> </span>EUR
                  <span className="s4"> </span>invested,
                  <span className="s4"> </span>
                  expressed<span className="s4"> </span>as
                  <span className="s4"> </span>a
                </p>
                <p
                  className="s6"
                  style={{
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "11pt",
                    textAlign: "justify",
                  }}
                >
                  weighted<span className="s4"> </span>average
                </p>
              </td>
              <td
                style={{
                  width: "89pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  0%
                </p>
              </td>
              <td
                style={{
                  width: "86pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  0%
                </p>
              </td>
              <td
                style={{
                  width: "78pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  N/A
                </p>
              </td>
              <td
                style={{
                  width: "73pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  N/A
                </p>
              </td>
              <td
                style={{
                  width: "6pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
            </tr>
            <tr style={{ height: "73pt" }}>
              <td
                style={{
                  width: "6pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
              <td
                style={{
                  width: "702pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
                colSpan={7}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
              <td
                style={{
                  width: "6pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p style={{ textIndent: "0pt", textAlign: "left" }}>
          <br />
        </p>
        <table
          style={{ borderCollapse: "collapse", marginLeft: "5.88064pt" }}
          cellSpacing={0}
        >
          <tbody>
            <tr style={{ height: "18pt" }}>
              <td
                style={{
                  width: "6pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
              <th
                style={{
                  width: "702pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
                colSpan={7}
              >
                <p
                  className="s5"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "71pt",
                    textIndent: "0pt",
                    lineHeight: "11pt",
                    textAlign: "left",
                  }}
                >
                  INDICATORS<span className="s4"> </span>FOR
                  <span className="s4"> </span>SOCIAL
                  <span className="s4"> </span>
                  AND
                  <span className="s4"> </span>EMPLOYEE,
                  <span className="s4"> </span>
                  RESPECT<span className="s4"> </span>FOR
                  <span className="s4"> </span>
                  HUMAN<span className="s4"> </span>RIGHTS,
                  <span className="s4"> </span>ANTI-CORRUPTION
                  <span className="s4"> </span>AND<span className="s4"> </span>
                  ANTI-BRIBERY<span className="s4"> </span>MATTERS
                </p>
              </th>
              <td
                style={{
                  width: "6pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
            </tr>
            <tr style={{ height: "140pt" }}>
              <td
                style={{
                  width: "6pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
                rowSpan={5}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
              <td
                style={{
                  width: "86pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
                rowSpan={5}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    paddingRight: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Social<span className="s4"> </span>and
                  <span className="s4"> </span>
                  employee<span className="s4"> </span>matters
                </p>
              </td>
              <td
                style={{
                  width: "128pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingLeft: "41pt",
                    paddingRight: "4pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  10.<span className="s4"> </span>Violations
                  <span className="s4"> </span>of<span className="s4"> </span>UN
                  <span className="s4"> </span>Global
                  <span className="s4"> </span>
                  Compact<span className="s4"> </span>principles
                  <span className="s4"> </span>and<span className="s4"> </span>
                  Organisation<span className="s4"> </span>for
                  <span className="s4"> </span>Economic
                  <span className="s4"> </span>
                  Cooperation<span className="s4"> </span>and
                  <span className="s4"> </span>Development
                  <span className="s4"> </span>(OECD)
                  <span className="s4"> </span>
                  Guidelines<span className="s4"> </span>for
                  <span className="s4"> </span>Multinational
                  <span className="s4"> </span>Enterprises
                </p>
              </td>
              <td
                style={{
                  width: "162pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    paddingRight: "4pt",
                    textIndent: "0pt",
                    textAlign: "justify",
                  }}
                >
                  Share<span className="s4"> </span>of
                  <span className="s4"> </span>
                  investments<span className="s4"> </span>in
                  <span className="s4"> </span>investee
                  <span className="s4"> </span>
                  companies<span className="s4"> </span>that
                  <span className="s4"> </span>have<span className="s4"> </span>
                  been
                  <span className="s4"> </span>involved
                  <span className="s4"> </span>in
                  <span className="s4"> </span>violations
                  <span className="s4"> </span>
                  of<span className="s4"> </span>the
                  <span className="s4"> </span>
                  UNGC
                  <span className="s4"> </span>principles
                  <span className="s4"> </span>
                  or<span className="s4"> </span>OECD
                  <span className="s4"> </span>
                  Guidelines<span className="s4"> </span>for
                  <span className="s4"> </span>Multinational
                  <span className="s4"> </span>Enterprises
                </p>
              </td>
              <td
                style={{
                  width: "89pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  0%
                </p>
              </td>
              <td
                style={{
                  width: "86pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  0%
                </p>
              </td>
              <td
                style={{
                  width: "78pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  N/A
                </p>
              </td>
              <td
                style={{
                  width: "73pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  N/A
                </p>
              </td>
              <td
                style={{
                  width: "6pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
            </tr>
            <tr style={{ height: "134pt" }}>
              <td
                style={{
                  width: "128pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingLeft: "41pt",
                    paddingRight: "8pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  11.<span className="s4"> </span>Lack
                  <span className="s4"> </span>of
                  <span className="s4"> </span>processes
                  <span className="s4"> </span>
                  and<span className="s4"> </span>compliance
                  <span className="s4"> </span>mechanisms
                  <span className="s4"> </span>
                  to<span className="s4"> </span>monitor
                  <span className="s4"> </span>
                  compliance<span className="s4"> </span>with
                  <span className="s4"> </span>UN<span className="s4"> </span>
                  Global
                  <span className="s4"> </span>Compact
                  <span className="s4"> </span>
                  principles<span className="s4"> </span>and
                  <span className="s4"> </span>OECD<span className="s4"> </span>
                  Guidelines<span className="s4"> </span>for
                  <span className="s4"> </span>Multinational
                </p>
                <p
                  className="s6"
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "0pt",
                    lineHeight: "11pt",
                    textAlign: "left",
                  }}
                >
                  Enterprises
                </p>
              </td>
              <td
                style={{
                  width: "162pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    paddingRight: "4pt",
                    textIndent: "0pt",
                    textAlign: "justify",
                  }}
                >
                  Share<span className="s4"> </span>of
                  <span className="s4"> </span>
                  investments<span className="s4"> </span>in
                  <span className="s4"> </span>investee
                  <span className="s4"> </span>
                  companies<span className="s4"> </span>without
                  <span className="s4"> </span>policies
                  <span className="s4"> </span>to
                  <span className="s4"> </span>monitor
                  <span className="s4"> </span>
                  compliance<span className="s4"> </span>with
                  <span className="s4"> </span>the<span className="s4"> </span>
                  UNGC
                  <span className="s4"> </span>principles
                  <span className="s4"> </span>
                  or<span className="s4"> </span>OECD
                  <span className="s4"> </span>
                  Guidelines<span className="s4"> </span>for
                  <span className="s4"> </span>Multinational
                  <span className="s4"> </span>Enterprises
                  <span className="s4"> </span>or<span className="s4"> </span>
                  grievance
                </p>
                <p
                  className="s6"
                  style={{
                    paddingLeft: "5pt",
                    paddingRight: "4pt",
                    textIndent: "0pt",
                    textAlign: "justify",
                  }}
                >
                  /complaints<span className="s4"> </span>handling
                  <span className="s4"> </span>mechanisms
                  <span className="s4"> </span>
                  to<span className="s4"> </span>address
                  <span className="s4"> </span>
                  violations<span className="s4"> </span>of
                  <span className="s4"> </span>the<span className="s4"> </span>
                  UNGC
                  <span className="s4"> </span>principles
                  <span className="s4"> </span>
                  or<span className="s4"> </span>OECD
                  <span className="s4"> </span>
                  Guidelines<span className="s4"> </span>for
                  <span className="s4"> </span>Multinational
                  <span className="s4"> </span>Enterprises
                </p>
              </td>
              <td
                style={{
                  width: "89pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  0%
                </p>
              </td>
              <td
                style={{
                  width: "86pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  0%
                </p>
              </td>
              <td
                style={{
                  width: "78pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  N/A
                </p>
              </td>
              <td
                style={{
                  width: "73pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  N/A
                </p>
              </td>
              <td
                style={{
                  width: "6pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
            </tr>
            <tr style={{ height: "30pt" }}>
              <td
                style={{
                  width: "128pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingLeft: "41pt",
                    paddingRight: "10pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  12.<span className="s4"> </span>Unadjusted
                  <span className="s4"> </span>gender
                  <span className="s4"> </span>
                  pay
                  <span className="s4"> </span>gap
                </p>
              </td>
              <td
                style={{
                  width: "162pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "4pt",
                    paddingLeft: "5pt",
                    paddingRight: "2pt",
                    textIndent: "0pt",
                    lineHeight: "12pt",
                    textAlign: "left",
                  }}
                >
                  Average<span className="s4"> </span>unadjusted
                  <span className="s4"> </span>gender
                  <span className="s4"> </span>
                  pay
                  <span className="s4"> </span>gap<span className="s4"> </span>
                  of
                  <span className="s4"> </span>investee
                  <span className="s4"> </span>
                  companies
                </p>
              </td>
              <td
                style={{
                  width: "89pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  0%
                </p>
              </td>
              <td
                style={{
                  width: "86pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  0%
                </p>
              </td>
              <td
                style={{
                  width: "78pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  N/A
                </p>
              </td>
              <td
                style={{
                  width: "73pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  N/A
                </p>
              </td>
              <td
                style={{
                  width: "6pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
            </tr>
            <tr style={{ height: "54pt" }}>
              <td
                style={{
                  width: "128pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  13.<span className="s4"> </span>Board
                  <span className="s4"> </span>
                  gender<span className="s4"> </span>diversity
                </p>
              </td>
              <td
                style={{
                  width: "162pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    paddingRight: "4pt",
                    textIndent: "0pt",
                    textAlign: "justify",
                  }}
                >
                  Average<span className="s4"> </span>ratio
                  <span className="s4"> </span>of<span className="s4"> </span>
                  female
                  <span className="s4"> </span>to<span className="s4"> </span>
                  male
                  <span className="s4"> </span>board
                  <span className="s4"> </span>
                  members<span className="s4"> </span>in
                  <span className="s4"> </span>
                  investee<span className="s4"> </span>companies,
                  <span className="s4"></span>expressed
                  <span className="s4"></span>as
                  <span className="s4"></span>a
                </p>
                <p
                  className="s6"
                  style={{
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "11pt",
                    textAlign: "justify",
                  }}
                >
                  percentage<span className="s4"> </span>of
                  <span className="s4"> </span>all<span className="s4"> </span>
                  board
                  <span className="s4"> </span>members
                </p>
              </td>
              <td
                style={{
                  width: "89pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  0%
                </p>
              </td>
              <td
                style={{
                  width: "86pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  0%
                </p>
              </td>
              <td
                style={{
                  width: "78pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  N/A
                </p>
              </td>
              <td
                style={{
                  width: "73pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  N/A
                </p>
              </td>
              <td
                style={{
                  width: "6pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
            </tr>
            <tr style={{ height: "61pt" }}>
              <td
                style={{
                  width: "128pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  14.<span className="s4"> </span>Exposure
                  <span className="s4"> </span>to<span className="s4"> </span>
                  controversial<span className="s4"> </span>weapons
                  <span className="s4"> </span>(anti-
                  <span className="s4"> </span>
                  personnel<span className="s4"> </span>mines,
                </p>
                <p
                  className="s6"
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "0pt",
                    lineHeight: "11pt",
                    textAlign: "left",
                  }}
                >
                  cluster<span className="s4"> </span>munitions,
                </p>
              </td>
              <td
                style={{
                  width: "162pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    paddingRight: "4pt",
                    textIndent: "0pt",
                    textAlign: "justify",
                  }}
                >
                  Share<span className="s4"> </span>of
                  <span className="s4"> </span>
                  investments<span className="s4"> </span>in
                  <span className="s4"> </span>investee
                  <span className="s4"> </span>
                  companies<span className="s4"> </span>involved
                  <span className="s4"> </span>in<span className="s4"> </span>
                  the
                  <span className="s4"> </span>manufacture
                  <span className="s4"> </span>or<span className="s4"> </span>
                  selling
                  <span className="s4"> </span>of<span className="s4"> </span>
                  controversial<span className="s4"> </span>weapons
                </p>
              </td>
              <td
                style={{
                  width: "89pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  0%
                </p>
              </td>
              <td
                style={{
                  width: "86pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  0%
                </p>
              </td>
              <td
                style={{
                  width: "78pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  N/A
                </p>
              </td>
              <td
                style={{
                  width: "73pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  N/A
                </p>
              </td>
              <td
                style={{
                  width: "6pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p style={{ textIndent: "0pt", textAlign: "left" }}>
          <br />
        </p>
        <table
          style={{ borderCollapse: "collapse", marginLeft: "5.88064pt" }}
          cellSpacing={0}
        >
          <tbody>
            <tr style={{ height: "37pt" }}>
              <td
                style={{
                  width: "6pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
              <td
                style={{
                  width: "86pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
              <td
                style={{
                  width: "128pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  chemical<span className="s4"> </span>weapons
                  <span className="s4"> </span>and<span className="s4"> </span>
                  biological
                </p>
                <p
                  className="s6"
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "0pt",
                    lineHeight: "11pt",
                    textAlign: "left",
                  }}
                >
                  weapons)
                </p>
              </td>
              <td
                style={{
                  width: "162pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
              <td
                style={{
                  width: "89pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
              <td
                style={{
                  width: "86pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
              <td
                style={{
                  width: "78pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
              <td
                style={{
                  width: "73pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
              <td
                style={{
                  width: "6pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
            </tr>
            <tr style={{ height: "55pt" }}>
              <td
                style={{
                  width: "6pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
              <th
                style={{
                  width: "702pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
                colSpan={7}
              >
                <p
                  style={{
                    paddingTop: "11pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  <br />
                </p>
                <p
                  className="s5"
                  style={{ textIndent: "0pt", textAlign: "center" }}
                >
                  Indicators<span className="s4"> </span>applicable
                  <span className="s4"> </span>to<span className="s4"> </span>
                  investments<span className="s4"> </span>in
                  <span className="s4"> </span>sovereigns
                  <span className="s4"> </span>
                  and<span className="s4"> </span>supranationals
                </p>
              </th>
              <td
                style={{
                  width: "6pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
            </tr>
            <tr style={{ height: "104pt" }}>
              <td
                style={{
                  width: "6pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
              <th
                style={{
                  width: "214pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
                colSpan={2}
              >
                <p
                  className="s5"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "35pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Adverse<span className="s4"> </span>sustainability
                  <span className="s4"> </span>indicator
                </p>
              </th>
              <th
                style={{
                  width: "162pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s5"
                  style={{
                    paddingTop: "5pt",
                    textIndent: "0pt",
                    textAlign: "center",
                  }}
                >
                  Metric
                </p>
              </th>
              <th
                style={{
                  width: "89pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s5"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "16pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Impact<span className="s4"> </span>2023
                </p>
              </th>
              <th
                style={{
                  width: "86pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s5"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "15pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Impact<span className="s4"> </span>2022
                </p>
              </th>
              <th
                style={{
                  width: "78pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s5"
                  style={{
                    paddingTop: "5pt",

                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Explanation
                </p>
              </th>
              <th
                style={{
                  width: "73pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s5"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "6pt",
                    paddingRight: "6pt",
                    textIndent: "0pt",
                    textAlign: "center",
                  }}
                >
                  Actions<span className="s4"> </span>taken,
                  <span className="s4"> </span>and<span className="s4"> </span>
                  actions
                  <span className="s4"> </span>planned
                  <span className="s4"> </span>and
                  <span className="s4"> </span>targets
                  <span className="s4"> </span>set
                  <span className="s4"> </span>for<span className="s4"> </span>
                  the
                  <span className="s4"> </span>next<span className="s4"> </span>
                  reference
                </p>
                <p
                  className="s5"
                  style={{
                    paddingLeft: "6pt",
                    paddingRight: "6pt",
                    textIndent: "0pt",
                    lineHeight: "11pt",
                    textAlign: "center",
                  }}
                >
                  period
                </p>
              </th>
              <td
                style={{
                  width: "6pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
            </tr>
            <tr style={{ height: "19pt" }}>
              <td
                style={{
                  width: "6pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
              <td
                style={{
                  width: "86pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "11pt",
                    textAlign: "left",
                  }}
                >
                  Environmental
                </p>
              </td>
              <td
                style={{
                  width: "128pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingLeft: "23pt",
                    textIndent: "0pt",
                    lineHeight: "12pt",
                    textAlign: "left",
                  }}
                >
                  15.<span className="s4"> </span>GHG
                  <span className="s4"> </span>
                  intensity
                </p>
              </td>
              <td
                style={{
                  width: "162pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    textIndent: "0pt",
                    lineHeight: "11pt",
                    textAlign: "center",
                  }}
                >
                  GHG<span className="s4"> </span>intensity
                  <span className="s4"> </span>of<span className="s4"> </span>
                  investee
                  <span className="s4"> </span>countries
                </p>
              </td>
              <td
                style={{
                  width: "89pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "11pt",
                    textAlign: "left",
                  }}
                >
                  0%
                </p>
              </td>
              <td
                style={{
                  width: "86pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "11pt",
                    textAlign: "left",
                  }}
                >
                  0%
                </p>
              </td>
              <td
                style={{
                  width: "78pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "11pt",
                    textAlign: "left",
                  }}
                >
                  N/A
                </p>
              </td>
              <td
                style={{
                  width: "73pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "11pt",
                    textAlign: "left",
                  }}
                >
                  N/A
                </p>
              </td>
              <td
                style={{
                  width: "6pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
            </tr>
            <tr style={{ height: "116pt" }}>
              <td
                style={{
                  width: "6pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
              <td
                style={{
                  width: "86pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Social
                </p>
              </td>
              <td
                style={{
                  width: "128pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingLeft: "41pt",
                    paddingRight: "6pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  16.<span className="s4"> </span>Investee
                  <span className="s4"> </span>countries
                  <span className="s4"> </span>
                  subject<span className="s4"> </span>to
                  <span className="s4"> </span>
                  social<span className="s4"> </span>violations
                </p>
              </td>
              <td
                style={{
                  width: "162pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    paddingRight: "4pt",
                    textIndent: "0pt",
                    textAlign: "justify",
                  }}
                >
                  Number<span className="s4"> </span>of
                  <span className="s4"> </span>
                  investee<span className="s4"> </span>countries
                  <span className="s4"> </span>subject
                  <span className="s4"> </span>to
                  <span className="s4"> </span>social
                  <span className="s4"> </span>
                  violations<span className="s4"> </span>(absolute
                  <span className="s4"> </span>number
                  <span className="s4"> </span>
                  and
                  <span className="s4"> </span>relative
                  <span className="s4"> </span>
                  number<span className="s4"> </span>divided
                  <span className="s4"> </span>by<span className="s4"> </span>
                  all
                  <span className="s4"> </span>investee
                  <span className="s4"> </span>
                  countries),<span className="s4"> </span>as
                  <span className="s4"> </span>referred
                  <span className="s4"> </span>to
                  <span className="s4"> </span>in<span className="s4"> </span>
                  international<span className="s4"> </span>treaties
                  <span className="s4"> </span>and<span className="s4"> </span>
                  conventions,<span className="s4"> </span>United
                  <span className="s4"> </span>Nations
                  <span className="s4"> </span>
                  principles<span className="s4"> </span>and,
                  <span className="s4"> </span>where
                  <span className="s4"> </span>
                  applicable,
                </p>
                <p
                  className="s6"
                  style={{
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "11pt",
                    textAlign: "justify",
                  }}
                >
                  national<span className="s4"> </span>law
                </p>
              </td>
              <td
                style={{
                  width: "89pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  0%
                </p>
              </td>
              <td
                style={{
                  width: "86pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  0%
                </p>
              </td>
              <td
                style={{
                  width: "78pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  N/A
                </p>
              </td>
              <td
                style={{
                  width: "73pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  N/A
                </p>
              </td>
              <td
                style={{
                  width: "6pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
            </tr>
            <tr style={{ height: "110pt" }}>
              <td
                style={{
                  width: "6pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
              <td
                style={{
                  width: "702pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
                colSpan={7}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
              <td
                style={{
                  width: "6pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p style={{ textIndent: "0pt", textAlign: "left" }}>
          <br />
        </p>
        <table
          style={{ borderCollapse: "collapse", marginLeft: "5.88064pt" }}
          cellSpacing={0}
        >
          <tbody>
            <tr style={{ height: "37pt" }}>
              <td
                style={{
                  width: "6pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
              <th
                style={{
                  width: "702pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
                colSpan={7}
              >
                <p
                  className="s5"
                  style={{
                    paddingTop: "5pt",
                    textIndent: "0pt",
                    textAlign: "center",
                  }}
                >
                  Indicators<span className="s4"> </span>applicable
                  <span className="s4"> </span>to<span className="s4"> </span>
                  investments<span className="s4"> </span>in
                  <span className="s4"> </span>real<span className="s4"> </span>
                  estate
                  <span className="s4"> </span>assets
                </p>
              </th>
              <td
                style={{
                  width: "6pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
            </tr>
            <tr style={{ height: "104pt" }}>
              <td
                style={{
                  width: "6pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
              <th
                style={{
                  width: "214pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
                colSpan={2}
              >
                <p
                  className="s5"
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "0pt",
                    lineHeight: "12pt",
                    textAlign: "left",
                  }}
                >
                  Adverse<span className="s4"> </span>sustainability
                  <span className="s4"> </span>indicator
                </p>
              </th>
              <th
                style={{
                  width: "162pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s5"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Metric
                </p>
              </th>
              <th
                style={{
                  width: "89pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s5"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "4pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Impact<span className="s4"> </span>2023
                </p>
              </th>
              <th
                style={{
                  width: "86pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s5"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Impact<span className="s4"> </span>2022
                </p>
              </th>
              <th
                style={{
                  width: "78pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s5"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Explanation
                </p>
              </th>
              <th
                style={{
                  width: "73pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s5"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    paddingRight: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Actions<span className="s4"> </span>taken,
                  <span className="s4"> </span>and<span className="s4"> </span>
                  actions
                  <span className="s4"> </span>planned
                  <span className="s4"> </span>and
                  <span className="s4"> </span>targets
                  <span className="s4"> </span>set
                  <span className="s4"> </span>for<span className="s4"> </span>
                  the
                  <span className="s4"> </span>next<span className="s4"> </span>
                  reference
                </p>
                <p
                  className="s5"
                  style={{
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "11pt",
                    textAlign: "left",
                  }}
                >
                  period
                </p>
              </th>
              <td
                style={{
                  width: "6pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
            </tr>
            <tr style={{ height: "65pt" }}>
              <td
                style={{
                  width: "6pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
              <td
                style={{
                  width: "86pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Fossil<span className="s4"> </span>fuels
                </p>
              </td>
              <td
                style={{
                  width: "128pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  style={{
                    paddingTop: "1pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  <br />
                </p>
                <p
                  className="s6"
                  style={{
                    paddingLeft: "41pt",
                    paddingRight: "10pt",
                    textIndent: "-18pt",
                    textAlign: "justify",
                  }}
                >
                  17.<span className="s4"> </span>Exposure
                  <span className="s4"> </span>to<span className="s4"> </span>
                  fossil
                  <span className="s4"> </span>fuels
                  <span className="s4"> </span>
                  through<span className="s4"> </span>real
                  <span className="s4"> </span>estate
                  <span className="s4"> </span>
                  assets
                </p>
              </td>
              <td
                style={{
                  width: "162pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "10pt",
                    paddingLeft: "5pt",
                    paddingRight: "4pt",
                    textIndent: "0pt",
                    textAlign: "justify",
                  }}
                >
                  Share<span className="s4"> </span>of
                  <span className="s4"> </span>
                  investments<span className="s4"> </span>in
                  <span className="s4"> </span>real<span className="s4"> </span>
                  estate
                  <span className="s4"> </span>assets
                  <span className="s4"> </span>
                  involved<span className="s4"> </span>in
                  <span className="s4"> </span>
                  the<span className="s4"> </span>extraction,
                  <span className="s4"> </span>storage,
                  <span className="s4"> </span>
                  transport<span className="s4"> </span>or
                  <span className="s4"> </span>manufacture
                  <span className="s4"> </span>of<span className="s4"> </span>
                  fossil
                  <span className="s4"> </span>fuels
                </p>
              </td>
              <td
                style={{
                  width: "89pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "4pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  0%
                </p>
              </td>
              <td
                style={{
                  width: "86pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  N/A
                </p>
              </td>
              <td
                style={{
                  width: "78pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s7"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    paddingRight: "6pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  LRC<span className="s8"> </span>does
                  <span className="s8"> </span>not
                  <span className="s8"> </span>own<span className="s8"> </span>
                  assets
                  <span className="s8"> </span>involved
                  <span className="s8"> </span>in
                  <span className="s8"> </span>the<span className="s8"> </span>
                  extraction,<span className="s8"> </span>storage,
                  <span className="s8"> </span>transport
                  <span className="s8"> </span>
                  or<span className="s8"> </span>manufacture
                  <span className="s8"> </span>of
                </p>
                <p
                  className="s7"
                  style={{
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "9pt",
                    textAlign: "left",
                  }}
                >
                  fossil<span className="s8"> </span>fuels.
                </p>
              </td>
              <td
                style={{
                  width: "73pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s7"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  N/A
                </p>
              </td>
              <td
                style={{
                  width: "6pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
            </tr>
            <tr style={{ height: "94pt" }}>
              <td
                style={{
                  width: "6pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
              <td
                style={{
                  width: "86pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Energy<span className="s4"> </span>efficiency
                </p>
              </td>
              <td
                style={{
                  width: "128pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  style={{
                    paddingTop: "10pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  <br />
                </p>
                <p
                  className="s6"
                  style={{
                    paddingLeft: "41pt",
                    paddingRight: "10pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  18.<span className="s4"> </span>Exposure
                  <span className="s4"> </span>to<span className="s4"> </span>
                  energy-inefficient<span className="s4"> </span>real
                  <span className="s4"> </span>estate
                  <span className="s4"> </span>
                  assets
                </p>
              </td>
              <td
                style={{
                  width: "162pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
                <p
                  className="s6"
                  style={{
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Share<span className="s4"> </span>of
                  <span className="s4"> </span>
                  investments<span className="s4"> </span>in
                  <span className="s4"> </span>energy-
                  <span className="s4"> </span>
                  inefficient<span className="s4"> </span>real
                  <span className="s4"> </span>estate
                  <span className="s4"> </span>
                  assets
                </p>
              </td>
              <td
                style={{
                  width: "89pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "4pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  83%
                </p>
              </td>
              <td
                style={{
                  width: "86pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  N/A
                </p>
              </td>
              <td
                style={{
                  width: "78pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s7"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    paddingRight: "6pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  This<span className="s8"> </span>indicator
                  <span className="s8"> </span>has<span className="s8"> </span>
                  been
                  <span className="s8"> </span>calculated
                  <span className="s8"> </span>
                  in<span className="s8"> </span>accordance
                  <span className="s8"> </span>with<span className="s8"> </span>
                  the
                  <span className="s8"> </span>relevant
                  <span className="s8"> </span>
                  formula<span className="s8"> </span>using
                  <span className="s8"> </span>EPC/BER
                </p>
                <p
                  className="s7"
                  style={{
                    paddingLeft: "5pt",
                    paddingRight: "6pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  certificates<span className="s8"> </span>and
                  <span className="s8"> </span>year<span className="s8"> </span>
                  end
                  <span className="s8"> </span>2023
                </p>
                <p
                  className="s7"
                  style={{
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "9pt",
                    textAlign: "left",
                  }}
                >
                  asset<span className="s8"> </span>valuations.
                </p>
              </td>
              <td
                style={{
                  width: "73pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s7"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    paddingRight: "6pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  We<span className="s8"> </span>have
                  <span className="s8"> </span>
                  set
                  <span className="s8"> </span>a<span className="s8"> </span>
                  target
                  <span className="s8"> </span>of<span className="s8"> </span>
                  improving<span className="s8"> </span>the
                  <span className="s8"> </span>EPC<span className="s8"> </span>
                  scores
                  <span className="s8"> </span>of<span className="s8"> </span>5%
                  <span className="s8"> </span>of<span className="s8"> </span>
                  our
                  <span className="s8"> </span>assets
                  <span className="s8"> </span>
                  per
                  <span className="s8"> </span>annum
                  <span className="s8"> </span>
                  from
                  <span className="s8"> </span>2024<span className="s8"> </span>
                  onwards.
                </p>
              </td>
              <td
                style={{
                  width: "6pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
            </tr>
            <tr style={{ height: "146pt" }}>
              <td
                style={{
                  width: "6pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
              <td
                style={{
                  width: "702pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
                colSpan={7}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
              <td
                style={{
                  width: "6pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p style={{ textIndent: "0pt", textAlign: "left" }}>
          <br />
        </p>
        <table
          style={{ borderCollapse: "collapse", marginLeft: "5.88064pt" }}
          cellSpacing={0}
        >
          <tbody>
            <tr style={{ height: "21pt" }}>
              <td
                style={{
                  width: "6pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
              <th
                style={{
                  width: "702pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
                colSpan={7}
              >
                <p
                  className="s5"
                  style={{
                    paddingTop: "5pt",
                    textIndent: "0pt",
                    textAlign: "center",
                  }}
                >
                  Other<span className="s4"> </span>indicators
                  <span className="s4"> </span>for<span className="s4"> </span>
                  principal<span className="s4"> </span>adverse
                  <span className="s4"> </span>impacts
                  <span className="s4"> </span>on
                  <span className="s4"> </span>sustainability
                  <span className="s4"> </span>factors
                </p>
              </th>
              <td
                style={{
                  width: "6pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
            </tr>
            <tr style={{ height: "104pt" }}>
              <th
                style={{
                  width: "6pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </th>
              <th
                style={{
                  width: "214pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
                colSpan={2}
              >
                <p
                  className="s5"
                  style={{
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "12pt",
                    textAlign: "left",
                  }}
                >
                  Adverse<span className="s4"> </span>sustainability
                  <span className="s4"> </span>indicator
                </p>
              </th>
              <th
                style={{
                  width: "162pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s5"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Metric
                </p>
              </th>
              <th
                style={{
                  width: "89pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s5"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "4pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Impact<span className="s4"> </span>2023
                </p>
              </th>
              <th
                style={{
                  width: "86pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s5"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Impact<span className="s4"> </span>2022
                </p>
              </th>
              <th
                style={{
                  width: "78pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s5"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Explanation
                </p>
              </th>
              <th
                style={{
                  width: "73pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s5"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    paddingRight: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Actions<span className="s4"> </span>taken,
                  <span className="s4"> </span>and<span className="s4"> </span>
                  actions
                  <span className="s4"> </span>planned
                  <span className="s4"> </span>and
                  <span className="s4"> </span>targets
                  <span className="s4"> </span>set
                  <span className="s4"> </span>for<span className="s4"> </span>
                  the
                  <span className="s4"> </span>next<span className="s4"> </span>
                  reference
                </p>
                <p
                  className="s5"
                  style={{
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "11pt",
                    textAlign: "left",
                  }}
                >
                  period
                </p>
              </th>
              <td
                style={{
                  width: "6pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
            </tr>
            <tr style={{ height: "31pt" }}>
              <td
                style={{
                  width: "6pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
                rowSpan={4}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
              <td
                style={{
                  width: "86pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
                rowSpan={4}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Greenhouse<span className="s4"> </span>gas
                  <span className="s4"> </span>emissions
                </p>
              </td>
              <td
                style={{
                  width: "128pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
                rowSpan={4}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
                <p
                  className="s6"
                  style={{
                    paddingLeft: "23pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  18.<span className="s4"> </span>GHG
                  <span className="s4"> </span>
                  emissions
                </p>
              </td>
              <td
                style={{
                  width: "162pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "12pt",
                    textAlign: "left",
                  }}
                >
                  Scope<span className="s4"> </span>1
                  <span className="s4"> </span>
                  GHG
                  <span className="s4"> </span>emissions
                  <span className="s4"> </span>
                  generated<span className="s4"> </span>by
                  <span className="s4"> </span>real<span className="s4"> </span>
                  estate
                  <span className="s4"> </span>assets
                </p>
              </td>
              <td
                style={{
                  width: "89pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "4pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  28.37<span className="s4"> </span>tCO2e
                </p>
              </td>
              <td
                style={{
                  width: "86pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  N/A
                </p>
              </td>
              <td
                style={{
                  width: "78pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
                rowSpan={4}
              >
                <p
                  className="s7"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    paddingRight: "9pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  We<span className="s8"> </span>used
                  <span className="s8"> </span>3<span className="s9">rd</span>
                  <span className="s8"> </span>party
                  <span className="s8"> </span>
                  ESG
                  <span className="s8"> </span>specialists
                  <span className="s8"> </span>to<span className="s8"> </span>
                  assess
                  <span className="s8"> </span>and<span className="s8"> </span>
                  compile
                  <span className="s8"> </span>our<span className="s8"> </span>
                  2023
                  <span className="s8"> </span>GHG<span className="s8"> </span>
                  emissions<span className="s8"> </span>data.
                </p>
              </td>
              <td
                style={{
                  width: "73pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
                rowSpan={4}
              >
                <p
                  className="s7"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    paddingRight: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  As<span className="s8"> </span>this
                  <span className="s8"> </span>
                  was
                  <span className="s8"> </span>the<span className="s8"> </span>
                  first
                  <span className="s8"> </span>time<span className="s8"> </span>
                  we
                  <span className="s8"> </span>had<span className="s8"> </span>
                  compiled<span className="s8"> </span>our
                  <span className="s8"> </span>annual
                  <span className="s8"> </span>
                  GHG
                  <span className="s8"> </span>emissions
                  <span className="s8"> </span>
                  we<span className="s8"> </span>are
                  <span className="s8"> </span>
                  concentrating<span className="s8"> </span>on
                  <span className="s8"> </span>refining
                  <span className="s8"> </span>
                  our<span className="s8"> </span>data
                  <span className="s8"> </span>
                  collection<span className="s8"> </span>systems
                  <span className="s8"> </span>whilst
                  <span className="s8"> </span>
                  also
                  <span className="s8"> </span>reviewing
                  <span className="s8"> </span>
                  the<span className="s8"> </span>feasability
                  <span className="s8"> </span>of<span className="s8"> </span>
                  various
                  <span className="s8"> </span>GHG<span className="s8"> </span>
                  reduction
                </p>
                <p
                  className="s7"
                  style={{
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "9pt",
                    textAlign: "left",
                  }}
                >
                  initiatives.
                </p>
              </td>
              <td
                style={{
                  width: "6pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
                rowSpan={4}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
            </tr>
            <tr style={{ height: "31pt" }}>
              <td
                style={{
                  width: "162pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "12pt",
                    textAlign: "left",
                  }}
                >
                  Scope<span className="s4"> </span>2
                  <span className="s4"> </span>
                  GHG
                  <span className="s4"> </span>emissions
                  <span className="s4"> </span>
                  generated<span className="s4"> </span>by
                  <span className="s4"> </span>real<span className="s4"> </span>
                  estate
                  <span className="s4"> </span>assets
                </p>
              </td>
              <td
                style={{
                  width: "89pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "4pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  4007.76<span className="s4"> </span>tCO2e
                </p>
              </td>
              <td
                style={{
                  width: "86pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  N/A
                </p>
              </td>
            </tr>
            <tr style={{ height: "31pt" }}>
              <td
                style={{
                  width: "162pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "12pt",
                    textAlign: "left",
                  }}
                >
                  Scope<span className="s4"> </span>3
                  <span className="s4"> </span>
                  GHG
                  <span className="s4"> </span>emissions
                  <span className="s4"> </span>
                  generated<span className="s4"> </span>by
                  <span className="s4"> </span>real<span className="s4"> </span>
                  estate
                  <span className="s4"> </span>assets
                </p>
              </td>
              <td
                style={{
                  width: "89pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "4pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  15,778.44<span className="s4"> </span>tCO2e
                </p>
              </td>
              <td
                style={{
                  width: "86pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  N/A
                </p>
              </td>
            </tr>
            <tr style={{ height: "50pt" }}>
              <td
                style={{
                  width: "162pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  style={{
                    paddingTop: "3pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  <br />
                </p>
                <p
                  className="s6"
                  style={{
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Total<span className="s4"> </span>GHG
                  <span className="s4"> </span>
                  emissions<span className="s4"> </span>generated
                  <span className="s4"> </span>by<span className="s4"> </span>
                  real
                  <span className="s4"> </span>estate
                  <span className="s4"> </span>
                  assets
                </p>
              </td>
              <td
                style={{
                  width: "89pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "4pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  19,814.56<span className="s4"> </span>tCO2e
                </p>
              </td>
              <td
                style={{
                  width: "86pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  N/A
                </p>
              </td>
            </tr>
            <tr style={{ height: "65pt" }}>
              <td
                style={{
                  width: "6pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
              <td
                style={{
                  width: "86pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Waste
                </p>
              </td>
              <td
                style={{
                  width: "128pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  style={{
                    paddingTop: "7pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  <br />
                </p>
                <p
                  className="s6"
                  style={{
                    paddingLeft: "41pt",
                    paddingRight: "4pt",
                    textIndent: "-18pt",
                    textAlign: "left",
                  }}
                >
                  20.<span className="s4"> </span>Waste
                  <span className="s4"> </span>
                  production<span className="s4"> </span>in
                  <span className="s4"> </span>operations
                </p>
              </td>
              <td
                style={{
                  width: "162pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "10pt",
                    paddingLeft: "5pt",
                    paddingRight: "4pt",
                    textIndent: "0pt",
                    textAlign: "justify",
                  }}
                >
                  Share<span className="s4"> </span>of
                  <span className="s4"> </span>
                  real<span className="s4"> </span>estate
                  <span className="s4"> </span>
                  assets<span className="s4"> </span>not
                  <span className="s4"> </span>
                  equipped<span className="s4"> </span>with
                  <span className="s4"> </span>facilities
                  <span className="s4"> </span>
                  for<span className="s4"> </span>waste
                  <span className="s4"> </span>
                  sorting<span className="s4"> </span>and
                  <span className="s4"> </span>
                  not<span className="s4"> </span>covered
                  <span className="s4"> </span>
                  by<span className="s4"> </span>a<span className="s4"> </span>
                  waste
                  <span className="s4"> </span>recovery
                  <span className="s4"> </span>or
                  <span className="s4"> </span>recycling
                  <span className="s4"> </span>
                  contract
                </p>
              </td>
              <td
                style={{
                  width: "89pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "4pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  20%
                </p>
              </td>
              <td
                style={{
                  width: "86pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  N/A
                </p>
              </td>
              <td
                style={{
                  width: "78pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s7"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    paddingRight: "6pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Calculated<span className="s8"> </span>on
                  <span className="s8"> </span>an<span className="s8"> </span>
                  asset
                  <span className="s8"> </span>by<span className="s8"> </span>
                  asset
                  <span className="s8"> </span>basis
                  <span className="s8"> </span>
                  and
                  <span className="s8"> </span>then<span className="s8"> </span>
                  compiled
                </p>
              </td>
              <td
                style={{
                  width: "73pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s7"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    paddingRight: "6pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  For<span className="s8"> </span>2024,
                  <span className="s8"> </span>
                  assess<span className="s8"> </span>the
                  <span className="s8"> </span>
                  possibility<span className="s8"> </span>of
                  <span className="s8"> </span>implementing
                  <span className="s8"> </span>recycling
                  <span className="s8"> </span>
                  where<span className="s8"> </span>not
                  <span className="s8"> </span>
                  already
                </p>
                <p
                  className="s7"
                  style={{
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "9pt",
                    textAlign: "left",
                  }}
                >
                  present
                </p>
              </td>
              <td
                style={{
                  width: "6pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
            </tr>
            <tr style={{ height: "80pt" }}>
              <td
                style={{
                  width: "6pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
              <td
                style={{
                  width: "86pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Biodiversity
                </p>
              </td>
              <td
                style={{
                  width: "128pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
                <p
                  className="s6"
                  style={{
                    paddingLeft: "23pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  22.<span className="s4"> </span>Land
                </p>
                <p
                  className="s6"
                  style={{
                    paddingLeft: "41pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  artificialisation
                </p>
              </td>
              <td
                style={{
                  width: "162pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    paddingRight: "4pt",
                    textIndent: "0pt",
                    textAlign: "justify",
                  }}
                >
                  Share<span className="s4"> </span>of
                  <span className="s4"> </span>
                  non-vegetated<span className="s4"> </span>surface
                  <span className="s4"> </span>area<span className="s4"> </span>
                  (surfaces<span className="s4"> </span>that
                  <span className="s4"> </span>have<span className="s4"> </span>
                  not
                  <span className="s4"> </span>been<span className="s4"> </span>
                  vegetated<span className="s4"> </span>in
                  <span className="s4"> </span>ground,
                  <span className="s4"> </span>as
                  <span className="s4"> </span>well<span className="s4"> </span>
                  as
                  <span className="s4"> </span>on<span className="s4"> </span>
                  roofs,
                  <span className="s4"> </span>terraces
                  <span className="s4"> </span>
                  and<span className="s4"> </span>walls)
                  <span className="s4"> </span>
                  compared<span className="s4"> </span>to
                  <span className="s4"> </span>
                  the<span className="s4"> </span>total
                  <span className="s4"> </span>
                  surface<span className="s4"> </span>area
                </p>
                <p
                  className="s6"
                  style={{
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "12pt",
                    textAlign: "justify",
                  }}
                >
                  of<span className="s4"> </span>the
                  <span className="s4"> </span>
                  plots
                  <span className="s4"> </span>of<span className="s4"> </span>
                  all
                  <span className="s4"> </span>assets
                </p>
              </td>
              <td
                style={{
                  width: "89pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "4pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  92%
                </p>
              </td>
              <td
                style={{
                  width: "86pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  N/A
                </p>
              </td>
              <td
                style={{
                  width: "78pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s7"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    paddingRight: "6pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Calculated<span className="s8"> </span>on
                  <span className="s8"> </span>an<span className="s8"> </span>
                  asset
                  <span className="s8"> </span>by<span className="s8"> </span>
                  asset
                  <span className="s8"> </span>basis
                  <span className="s8"> </span>
                  and
                  <span className="s8"> </span>then<span className="s8"> </span>
                  compiled
                </p>
              </td>
              <td
                style={{
                  width: "73pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s7"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    paddingRight: "6pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  For<span className="s8"> </span>2024,
                  <span className="s8"> </span>
                  assess<span className="s8"> </span>the
                  <span className="s8"> </span>
                  possibility<span className="s8"> </span>of
                  <span className="s8"> </span>increasing
                  <span className="s8"> </span>
                  the<span className="s8"> </span>share
                  <span className="s8"> </span>of
                  <span className="s8"> </span>vegetated
                  <span className="s8"> </span>
                  surface<span className="s8"> </span>area.
                </p>
              </td>
              <td
                style={{
                  width: "6pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
            </tr>
            <tr style={{ height: "37pt" }}>
              <td
                style={{
                  width: "714pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
                colSpan={9}
              >
                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                  <br />
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p style={{ textIndent: "0pt", textAlign: "left" }}>
          <br />
        </p>
        <h1
          style={{
            textIndent: "0pt",
            textAlign: "justify",
          }}
        >
          Description<span className="s1"> </span>of
          <span className="s1"> </span>
          policies<span className="s1"> </span>to<span className="s1"> </span>
          identify
          <span className="s1"> </span>and<span className="s1"> </span>
          prioritise
          <span className="s1"> </span>principal<span className="s1"> </span>
          adverse
          <span className="s1"> </span>impacts<span className="s1"> </span>on
          <span className="s1"> </span>sustainability
          <span className="s1"> </span>
          factors
        </h1>
        <p
          style={{
            paddingTop: "6pt",

            textIndent: "0pt",
            textAlign: "justify",
          }}
        >
          LRC<span className="s1"> </span>has<span className="s1"> </span>a
          <span className="s1"> </span>Responsible<span className="s1"> </span>
          Investment<span className="s1"> </span>Policy
          <span className="s1"> </span>
          that<span className="s1"> </span>was<span className="s1"> </span>
          initially
          <span className="s1"> </span>drafted<span className="s1"> </span>in
          <span className="s1"> </span>2023<span className="s1"> </span>and
          <span className="s1"> </span>approved<span className="s1"> </span>in
          <span className="s1"> </span>2024,<span className="s1"> </span>which
          <span className="s1"> </span>outlines<span className="s1"> </span>our
          <span className="s1"> </span>responsible<span className="s1"> </span>
          investment<span className="s1"> </span>principles
          <span className="s1"> </span>and<span className="s1"> </span>sets
          <span className="s1"> </span>out<span className="s1"> </span>how
          <span className="s1"> </span>these<span className="s1"> </span>are
          <span className="s1"> </span>to<span className="s1"> </span>be
          <span className="s1"> </span>incorporated<span className="s1"> </span>
          into
          <span className="s1"> </span>our<span className="s1"> </span>
          requirements
          <span className="s1"> </span>for<span className="s1"> </span>
          investment
          <span className="s1"> </span>activities<span className="s1"> </span>
          and
          <span className="s1"> </span>ownership<span className="s1"> </span>
          practices.<span className="s1"> </span>This
          <span className="s1"> </span>
          policy<span className="s1"> </span>is<span className="s1"> </span>
          reviewed
          <span className="s1"> </span>and<span className="s1"> </span>approved
          <span className="s1"> </span>at<span className="s1"> </span>least
          <span className="s1"> </span>annually.
        </p>
        <p
          style={{
            paddingTop: "5pt",

            textIndent: "0pt",
            textAlign: "justify",
          }}
        >
          In<span className="s1"> </span>addition,<span className="s1"> </span>
          we
          <span className="s1"> </span>have<span className="s1"> </span>
          implemented
          <span className="s1"> </span>various<span className="s1"> </span>
          policies
          <span className="s1"> </span>and<span className="s1"> </span>
          procedures
          <span className="s1"> </span>including<span className="s1"> </span>
          risk
          <span className="s1"> </span>matrices/models
          <span className="s1"> </span>to
          <span className="s1"> </span>manage<span className="s1"> </span>
          principal
          <span className="s1"> </span>adverse<span className="s1"> </span>
          impacts
          <span className="s1"> </span>on<span className="s1"> </span>an
          <span className="s1"> </span>ongoing<span className="s1"> </span>
          basis.
          <span className="s1"> </span>We<span className="s1"> </span>have
          <span className="s1"> </span>identified<span className="s1"> </span>
          specific
          <span className="s1"> </span>principal<span className="s1"> </span>
          adverse
          <span className="s1"> </span>impact<span className="s1"> </span>
          indicators
          <span className="s1"> </span>which<span className="s1"> </span>align
          <span className="s1"> </span>with<span className="s1"> </span>our
          <span className="s1"> </span>responsible<span className="s1"> </span>
          investment.
        </p>
        <p
          style={{
            paddingTop: "6pt",

            textIndent: "0pt",
            textAlign: "justify",
          }}
        >
          We<span className="s1"> </span>report<span className="s1"> </span>on
          <span className="s1"> </span>the<span className="s1"> </span>mandatory
          <span className="s1"> </span>indicators<span className="s1"> </span>
          applicable<span className="s1"> </span>to<span className="s1"> </span>
          investments<span className="s1"> </span>in
          <span className="s1"> </span>
          real
          <span className="s1"> </span>estate<span className="s1"> </span>assets
          <span className="s1"> </span>in<span className="s1"> </span>Table
          <span className="s1"> </span>1<span className="s1"> </span>in
          <span className="s1"> </span>Annex<span className="s1"> </span>I
          <span className="s1"> </span>Supplementing
          <span className="s1"> </span>
          Regulation<span className="s1"> </span>(EU)
          <span className="s1"> </span>
          2019/2088<span className="s1"> </span>of<span className="s1"> </span>
          the
          <span className="s1"> </span>European<span className="s1"> </span>
          Parliament
          <span className="s1"> </span>and<span className="s1"> </span>of
          <span className="s1"> </span>the<span className="s1"> </span>Council.
          <span className="s1"> </span>We<span className="s1"> </span>also
          <span className="s1"> </span>report<span className="s1"> </span>on
          <span className="s1"> </span>three<span className="s1"> </span>
          additional
          <span className="s1"> </span>climate<span className="s1"> </span>and
          <span className="s1"> </span>other<span className="s1"> </span>
          environment-related<span className="s1"> </span>indicators
          <span className="s1"> </span>applicable<span className="s1"> </span>to
          <span className="s1"> </span>investments<span className="s1"> </span>
          in
          <span className="s1"> </span>real<span className="s1"> </span>estate
          <span className="s1"> </span>assets<span className="s1"> </span>from
          <span className="s1"> </span>Table<span className="s1"> </span>2
          <span className="s1"> </span>in<span className="s1"> </span>Annex
          <span className="s1"> </span>I<span className="s1"> </span>
          supplementing
          <span className="s1"> </span>Regulation<span className="s1"> </span>
          (EU)
          <span className="s1"> </span>2019/2088<span className="s1"> </span>of
          <span className="s1"> </span>the<span className="s1"> </span>European
          <span className="s1"> </span>Parliament<span className="s1"> </span>
          and
          <span className="s1"> </span>of<span className="s1"> </span>the
          <span className="s1"> </span>Council.<span className="s1"> </span>The
          <span className="s1"> </span>impact<span className="s1"> </span>on
          <span className="s1"> </span>the<span className="s1"> </span>mandatory
          <span className="s1"> </span>and<span className="s1"> </span>
          additional
          <span className="s1"> </span>indicators<span className="s1"> </span>is
          <span className="s1"> </span>reported<span className="s1"> </span>by
          <span className="s1"> </span>collecting<span className="s1"> </span>
          and
          <span className="s1"> </span>aggregating<span className="s1"> </span>
          data
          <span className="s1"> </span>from<span className="s1"> </span>the
          <span className="s1"> </span>real<span className="s1"> </span>estate
          <span className="s1"> </span>investments<span className="s1"> </span>
          in
          <span className="s1"> </span>our<span className="s1"> </span>
          portfolio.
        </p>
        <p
          style={{
            paddingTop: "6pt",

            textIndent: "0pt",
            textAlign: "justify",
          }}
        >
          We<span className="s1"> </span>do<span className="s1"> </span>not
          <span className="s1"> </span>report<span className="s1"> </span>on
          <span className="s1"> </span>additional<span className="s1"> </span>
          indicators<span className="s1"> </span>for
          <span className="s1"> </span>
          social<span className="s1"> </span>and<span className="s1"> </span>
          employee,
          <span className="s1"> </span>respect<span className="s1"> </span>for
          <span className="s1"> </span>human<span className="s1"> </span>rights,
          <span className="s1"> </span>anti-corruption
          <span className="s1"> </span>and
          <span className="s1"> </span>anti-bribery<span className="s1"> </span>
          matters<span className="s1"> </span>in<span className="s1"> </span>
          Table
          <span className="s1"> </span>3<span className="s1"> </span>in
          <span className="s1"> </span>Annex<span className="s1"> </span>I
          <span className="s1"> </span>supplementing
          <span className="s1"> </span>
          Regulation<span className="s1"> </span>(EU)
          <span className="s1"> </span>
          2019/2088<span className="s1"> </span>of<span className="s1"> </span>
          the
          <span className="s1"> </span>European<span className="s1"> </span>
          Parliament
          <span className="s1"> </span>and<span className="s1"> </span>of
          <span className="s1"> </span>the<span className="s1"> </span>Council
          <span className="s1"> </span>as<span className="s1"> </span>there
          <span className="s1"> </span>are<span className="s1"> </span>no
          <span className="s1"> </span>indicators<span className="s1"> </span>
          applicable<span className="s1"> </span>to<span className="s1"> </span>
          investments<span className="s1"> </span>in
          <span className="s1"> </span>
          real
          <span className="s1"> </span>estate<span className="s1"> </span>
          assets.
        </p>
        <p
          style={{
            paddingTop: "5pt",

            textIndent: "0pt",
            textAlign: "justify",
          }}
        >
          We<span className="s1"> </span>incorporate
          <span className="s1"> </span>
          ESG
          <span className="s1"> </span>aspects<span className="s1"> </span>into
          <span className="s1"> </span>the<span className="s1"> </span>
          investment
          <span className="s1"> </span>life<span className="s1"> </span>cycle
          <span className="s1"> </span>from<span className="s1"> </span>the
          <span className="s1"> </span>earliest<span className="s1"> </span>
          stages
          <span className="s1"> </span>of<span className="s1"> </span>asset
          <span className="s1"> </span>identification,
          <span className="s1"> </span>
          through<span className="s1"> </span>acquisition,
          <span className="s1"> </span>into<span className="s1"> </span>holding
          <span className="s1"> </span>and<span className="s1"> </span>then
          <span className="s1"> </span>to<span className="s1"> </span>disposal.
          <span className="s1"> </span>All<span className="s1"> </span>
          prospective
          <span className="s1"> </span>acquisitions<span className="s1"> </span>
          are
          <span className="s1"> </span>subject<span className="s1"> </span>to
          <span className="s1"> </span>a<span className="s1"> </span>
          comprehensive
          <span className="s1"> </span>due<span className="s1"> </span>diligence
          <span className="s1"> </span>process<span className="s1"> </span>
          including
          <span className="s1"> </span>evaluation<span className="s1"> </span>of
          <span className="s1"> </span>technical<span className="s1"> </span>and
          <span className="s1"> </span>environmental
          <span className="s1"> </span>
          performance<span className="s1"> </span>to
          <span className="s1"> </span>
          identify<span className="s1"> </span>potential
          <span className="s1"> </span>
          climate<span className="s1"> </span>and<span className="s1"> </span>
          ESG-related<span className="s1"> </span>risks.
        </p>
        <p
          style={{
            paddingTop: "5pt",

            textIndent: "0pt",
            textAlign: "justify",
          }}
        >
          Ǫuantifying<span className="s1"> </span>and
          <span className="s1"> </span>
          assessing<span className="s1"> </span>our<span className="s1"> </span>
          principal<span className="s1"> </span>adverse
          <span className="s1"> </span>
          impacts<span className="s1"> </span>is<span className="s1"> </span>
          dependent
          <span className="s1"> </span>on<span className="s1"> </span>data
          <span className="s1"> </span>availability<span className="s1"> </span>
          and
          <span className="s1"> </span>quality.<span className="s1"> </span>We
          <span className="s1"> </span>rely<span className="s1"> </span>on
          <span className="s1"> </span>multiple<span className="s1"> </span>
          sources
          <span className="s1"> </span>to<span className="s1"> </span>collect
          <span className="s1"> </span>data<span className="s1"> </span>on
          <span className="s1"> </span>impacts.
        </p>
        <ul id="l1">
          <li data-list-text="•">
            <p
              style={{
                paddingTop: "6pt",
                paddingLeft: "47pt",
                textIndent: "-18pt",
                textAlign: "left",
              }}
            >
              Effects<span className="s1"> </span>on
              <span className="s1"> </span>
              energy<span className="s1"> </span>efficiency
              <span className="s1"> </span>are,<span className="s1"> </span>when
              <span className="s1"> </span>available,
              <span className="s1"> </span>
              calculated<span className="s1"> </span>on
              <span className="s1"> </span>
              actual<span className="s1"> </span>consumption.
              <span className="s1"> </span>Where<span className="s1"> </span>
              actual
              <span className="s1"> </span>data<span className="s1"> </span>is
              <span className="s1"> </span>not<span className="s1"> </span>
              available,
              <span className="s1"> </span>the<span className="s1"> </span>data
              <span className="s1"> </span>is<span className="s1"> </span>based
              <span className="s1"> </span>on<span className="s1"> </span>
              calculated
              <span className="s1"> </span>estimates.
            </p>
          </li>
          <li data-list-text="•">
            <p
              style={{
                paddingTop: "5pt",
                paddingLeft: "47pt",
                textIndent: "-17pt",
                textAlign: "left",
              }}
            >
              To<span className="s1"> </span>use<span className="s1"> </span>
              actual
              <span className="s1"> </span>data,<span className="s1"> </span>
              this
              <span className="s1"> </span>needs<span className="s1"> </span>to
              <span className="s1"> </span>be<span className="s1"> </span>
              provided
              <span className="s1"> </span>in<span className="s1"> </span>a
              <span className="s1"> </span>timely<span className="s1"> </span>
              and
              <span className="s1"> </span>accurate<span className="s1"> </span>
              manner.
            </p>
          </li>
          <li data-list-text="•">
            <p
              style={{
                paddingTop: "6pt",
                paddingLeft: "47pt",
                textIndent: "-17pt",
                textAlign: "left",
              }}
            >
              We<span className="s1"> </span>are<span className="s1"> </span>
              continually<span className="s1"> </span>striving
              <span className="s1"> </span>to<span className="s1"> </span>
              improve
              <span className="s1"> </span>our<span className="s1"> </span>data
              <span className="s1"> </span>collection
              <span className="s1"> </span>
              systems.
            </p>
          </li>
        </ul>
        <h1
          style={{
            paddingTop: "6pt",

            textIndent: "0pt",
            textAlign: "justify",
          }}
        >
          Best<span className="s1"> </span>efforts<span className="s1"> </span>
          used
          <span className="s1"> </span>to<span className="s1"> </span>obtain
          <span className="s1"> </span>information<span className="s1"> </span>
          relating<span className="s1"> </span>to<span className="s1"> </span>
          Scope
          <span className="s1"> </span>2<span className="s1"> </span>emissions
        </h1>
        <p
          style={{
            paddingTop: "6pt",

            textIndent: "0pt",
            textAlign: "justify",
          }}
        >
          For<span className="s1"> </span>the<span className="s1"> </span>
          impacts
          <span className="s1"> </span>related<span className="s1"> </span>to
          <span className="s1"> </span>“Scope<span className="s1"> </span>2
          <span className="s1"> </span>GHG<span className="s1"> </span>
          emissions”
          <span className="s1"> </span>the<span className="s1"> </span>data
          <span className="s1"> </span>for<span className="s1"> </span>several
          <span className="s1"> </span>real<span className="s1"> </span>estate
          <span className="s1"> </span>investments<span className="s1"> </span>
          has
          <span className="s1"> </span>been<span className="s1"> </span>
          partially
          <span className="s1"> </span>estimated<span className="s1"> </span>by
          <span className="s1"> </span>making<span className="s1"> </span>
          reasonable
          <span className="s1"> </span>assumptions<span className="s1"> </span>
          for
          <span className="s1"> </span>one<span className="s1"> </span>or
          <span className="s1"> </span>more<span className="s1"> </span>time
          <span className="s1"> </span>periods.<span className="s1"> </span>Our
          <span className="s1"> </span>ESG<span className="s1"> </span>data
          <span className="s1"> </span>disclosure<span className="s1"> </span>is
          <span className="s1"> </span>continually<span className="s1"> </span>
          improving<span className="s1"> </span>and<span className="s1"> </span>
          our
          <span className="s1"> </span>aim<span className="s1"> </span>is
          <span className="s1"> </span>to<span className="s1"> </span>
          continually
          <span className="s1"> </span>improve<span className="s1"> </span>the
          <span className="s1"> </span>accuracy<span className="s1"> </span>of
          <span className="s1"> </span>our<span className="s1"> </span>data
          <span className="s1"> </span>and<span className="s1"> </span>to
          <span className="s1"> </span>reduce<span className="s1"> </span>any
          <span className="s1"> </span>associated<span className="s1"> </span>
          margin
          <span className="s1"> </span>of<span className="s1"> </span>error.
        </p>
        <p style={{ textIndent: "0pt", textAlign: "left" }} />
        <p style={{ textIndent: "0pt", textAlign: "left" }}>
          <br />
        </p>
        <table
          style={{ borderCollapse: "collapse", marginLeft: "5.88064pt" }}
          cellSpacing={0}
        >
          <tbody>
            <tr style={{ height: "171pt" }}>
              <td
                style={{
                  width: "713pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s5"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "justify",
                    fontWeight: 700,
                  }}
                >
                  Engagement<span className="s4"> </span>policies
                </p>
                <p
                  className="s4"
                  style={{
                    paddingTop: "6pt",
                    paddingLeft: "5pt",
                    paddingRight: "4pt",
                    textIndent: "0pt",
                    textAlign: "justify",
                  }}
                >
                  <span className="s5">Engagement</span>{" "}
                  <span className="s5">of</span> <span className="s5">our</span>{" "}
                  <span className="s5">tenants,</span>{" "}
                  <span className="s5">suppliers</span>{" "}
                  <span className="s5">and</span>{" "}
                  <span className="s5">service</span>{" "}
                  <span className="s5">providers.</span>{" "}
                  <span className="s6">We</span>{" "}
                  <span className="s6">recognize</span>{" "}
                  <span className="s6">the</span>{" "}
                  <span className="s6">need</span>{" "}
                  <span className="s6">to</span>{" "}
                  <span className="s6">improve</span>{" "}
                  <span className="s6">our</span>{" "}
                  <span className="s6">communication</span>{" "}
                  <span className="s6">and</span>{" "}
                  <span className="s6">interaction</span>{" "}
                  <span className="s6">with</span>{" "}
                  <span className="s6">tenants</span>{" "}
                  <span className="s6">and</span> <span className="s6">in</span>{" "}
                  <span className="s6">2024</span>{" "}
                  <span className="s6">we</span>{" "}
                  <span className="s6">will</span>{" "}
                  <span className="s6">conduct</span>{" "}
                  <span className="s6">our</span>{" "}
                  <span className="s6">first</span>{" "}
                  <span className="s6">tenant</span>{" "}
                  <span className="s6">survey.</span>{" "}
                  <span className="s6">This</span>{" "}
                  <span className="s6">survey</span>{" "}
                  <span className="s6">will</span>{" "}
                  <span className="s6">include</span>{" "}
                  <span className="s6">several</span>{" "}
                  <span className="s6">specific</span>{" "}
                  <span className="s6">ESG</span>{" "}
                  <span className="s6">related</span>{" "}
                  <span className="s6">queries</span>{" "}
                  <span className="s6">to</span>{" "}
                  <span className="s6">help</span>{" "}
                  <span className="s6">us</span>{" "}
                  <span className="s6">better</span>{" "}
                  <span className="s6">understand</span>{" "}
                  <span className="s6">our</span>{" "}
                  <span className="s6">tenants</span>{" "}
                  <span className="s6">ESG</span>{" "}
                  <span className="s6">aspirations</span>{" "}
                  <span className="s6">and</span>{" "}
                  <span className="s6">this</span>{" "}
                  <span className="s6">feedback,</span>{" "}
                  <span className="s6">once</span>{" "}
                  <span className="s6">analysed,</span>{" "}
                  <span className="s6">will</span>{" "}
                  <span className="s6">help</span>{" "}
                  <span className="s6">guide</span>{" "}
                  <span className="s6">future</span>{" "}
                  <span className="s6">initiatives.</span>
                </p>
                <p
                  className="s6"
                  style={{
                    paddingTop: "6pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "justify",
                  }}
                >
                  We<span className="s4"> </span>also
                  <span className="s4"> </span>
                  endeavour<span className="s4"> </span>to
                  <span className="s4"> </span>provide
                  <span className="s4"> </span>our
                  <span className="s4"> </span>tenants
                  <span className="s4"> </span>
                  with<span className="s4"> </span>feedback
                  <span className="s4"> </span>on<span className="s4"> </span>
                  how
                  <span className="s4"> </span>they<span className="s4"> </span>
                  can
                  <span className="s4"> </span>improve
                  <span className="s4"> </span>
                  their<span className="s4"> </span>sustainability
                  <span className="s4"> </span>performance.
                </p>
                <p
                  className="s6"
                  style={{
                    paddingTop: "6pt",
                    paddingLeft: "5pt",
                    paddingRight: "4pt",
                    textIndent: "0pt",
                    textAlign: "justify",
                  }}
                >
                  Equally<span className="s4"> </span>we
                  <span className="s4"> </span>
                  also<span className="s4"> </span>aim
                  <span className="s4"> </span>for
                  <span className="s4"> </span>a<span className="s4"> </span>
                  behavioural<span className="s4"> </span>change
                  <span className="s4"> </span>amongst
                  <span className="s4"> </span>our
                  <span className="s4"> </span>stakeholders
                  <span className="s4"> </span>and<span className="s4"> </span>
                  business<span className="s4"> </span>partners
                  <span className="s4"> </span>with<span className="s4"> </span>
                  whom
                  <span className="s4"> </span>we<span className="s4"> </span>
                  actively
                  <span className="s4"> </span>engage
                  <span className="s4"> </span>
                  and
                  <span className="s4"> </span>promote
                  <span className="s4"> </span>ESG
                  <span className="s4"> </span>messages
                  <span className="s4"> </span>to
                  <span className="s4"> </span>and<span className="s4"> </span>
                  solicit
                  <span className="s4"> </span>feedback
                  <span className="s4"> </span>
                  from.
                </p>
                <p
                  className="s4"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    paddingRight: "4pt",
                    textIndent: "0pt",
                    textAlign: "justify",
                  }}
                >
                  <strong>
                    <span className="s5">Engagement</span>{" "}
                    <span className="s5">of</span>{" "}
                    <span className="s5">our</span>{" "}
                    <span className="s5">staff.</span>{" "}
                  </strong>
                  <span className="s6">We</span>{" "}
                  <span className="s6">recognize</span>{" "}
                  <span className="s6">the</span>{" "}
                  <span className="s6">need</span>{" "}
                  <span className="s6">to</span>{" "}
                  <span className="s6">improve</span>{" "}
                  <span className="s6">our</span>{" "}
                  <span className="s6">communication</span>{" "}
                  <span className="s6">and</span>{" "}
                  <span className="s6">interaction</span>{" "}
                  <span className="s6">with</span>{" "}
                  <span className="s6">colleagues</span>{" "}
                  <span className="s6">and</span> <span className="s6">in</span>{" "}
                  <span className="s6">early</span>{" "}
                  <span className="s6">2024</span>{" "}
                  <span className="s6">we</span>{" "}
                  <span className="s6">conducted</span>{" "}
                  <span className="s6">our</span>{" "}
                  <span className="s6">first</span>{" "}
                  <span className="s6">tenant</span>{" "}
                  <span className="s6">survey.</span>{" "}
                  <span className="s6">This</span>{" "}
                  <span className="s6">survey</span>{" "}
                  <span className="s6">will</span>{" "}
                  <span className="s6">included</span>{" "}
                  <span className="s6">several</span>{" "}
                  <span className="s6">specific</span>{" "}
                  <span className="s6">ESG</span>{" "}
                  <span className="s6">related</span>{" "}
                  <span className="s6">queries</span>{" "}
                  <span className="s6">to</span>{" "}
                  <span className="s6">help</span>{" "}
                  <span className="s6">us</span>{" "}
                  <span className="s6">better</span>{" "}
                  <span className="s6">understand</span>{" "}
                  <span className="s6">our</span>{" "}
                  <span className="s6">colleagues</span>{" "}
                  <span className="s6">ESG</span>{" "}
                  <span className="s6">aspirations</span>{" "}
                  <span className="s6">and</span>{" "}
                  <span className="s6">this</span>{" "}
                  <span className="s6">feedback,</span>{" "}
                  <span className="s6">once</span>{" "}
                  <span className="s6">analysed,</span>{" "}
                  <span className="s6">will</span>{" "}
                  <span className="s6">help</span>{" "}
                  <span className="s6">guide</span>{" "}
                  <span className="s6">future</span>{" "}
                  <span className="s6">initiatives.</span>
                </p>
              </td>
            </tr>
            <tr style={{ height: "91pt" }}>
              <td
                style={{
                  width: "713pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s5"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                    fontWeight: 700,
                  }}
                >
                  References<span className="s4"> </span>to
                  <span className="s4"> </span>international
                  <span className="s4"> </span>standards
                </p>
                <p
                  className="s6"
                  style={{
                    paddingTop: "6pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  We<span className="s4"> </span>submitted
                  <span className="s4"> </span>an<span className="s4"> </span>
                  application<span className="s4"> </span>to
                  <span className="s4"> </span>UNPri
                  <span className="s4"> </span>
                  in
                  <span className="s4"> </span>early
                  <span className="s4"> </span>
                  2024.
                </p>
                <p
                  className="s6"
                  style={{
                    paddingTop: "6pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}
                >
                  Our<span className="s4"> </span>Scope
                  <span className="s4"> </span>1,
                  <span className="s4"> </span>2,<span className="s4"> </span>
                  and
                  <span className="s4"> </span>3<span className="s4"> </span>
                  emissions
                  <span className="s4"> </span>were<span className="s4"> </span>
                  calculated<span className="s4"> </span>in
                  <span className="s4"> </span>line<span className="s4"> </span>
                  with
                  <span className="s4"> </span>the<span className="s4"> </span>
                  GHG
                  <span className="s4"> </span>protocol.
                </p>
                <p
                  className="s6"
                  style={{
                    paddingLeft: "5pt",
                    paddingRight: "308pt",
                    textIndent: "0pt",
                    lineHeight: "18pt",
                    textAlign: "left",
                  }}
                >
                  Our<span className="s4"> </span>Risk
                  <span className="s4"> </span>
                  Framework<span className="s4"> </span>was
                  <span className="s4"> </span>drawn
                  <span className="s4"> </span>
                  up,
                  <span className="s4"> </span>taking
                  <span className="s4"> </span>
                  into
                  <span className="s4"> </span>account
                  <span className="s4"> </span>
                  TCFD<span className="s4"> </span>principles
                  <span className="s4"> </span>from<span className="s4"> </span>
                  its
                  <span className="s4"> </span>inception.
                  <span className="s4"> </span>
                  We<span className="s4"> </span>are
                  <span className="s4"> </span>
                  assessing<span className="s4"> </span>various
                  <span className="s4"> </span>other
                  <span className="s4"> </span>
                  standards<span className="s4"> </span>as
                  <span className="s4"> </span>to<span className="s4"> </span>
                  their
                  <span className="s4"> </span>suitability
                  <span className="s4"> </span>(GRESB
                  <span className="s4"> </span>
                  and
                  <span className="s4"> </span>INRev).
                </p>
              </td>
            </tr>
            <tr style={{ height: "49pt" }}>
              <td
                style={{
                  width: "713pt",
                  borderTopStyle: "solid",
                  borderTopWidth: "1pt",
                  borderLeftStyle: "solid",
                  borderLeftWidth: "1pt",
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1pt",
                  borderRightStyle: "solid",
                  borderRightWidth: "1pt",
                }}
              >
                <p
                  className="s5"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                    fontWeight: 700,
                  }}
                >
                  Historical<span className="s4"> </span>comparison
                </p>
                <p
                  className="s6"
                  style={{
                    paddingTop: "5pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "12pt",
                    textAlign: "left",
                  }}
                >
                  Since<span className="s4"> </span>data
                  <span className="s4"> </span>
                  for<span className="s4"> </span>our
                  <span className="s4"> </span>
                  principal<span className="s4"> </span>adverse
                  <span className="s4"> </span>sustainability
                  <span className="s4"> </span>impacts
                  <span className="s4"> </span>is
                  <span className="s4"> </span>not<span className="s4"> </span>
                  available<span className="s4"> </span>prior
                  <span className="s4"> </span>to<span className="s4"> </span>
                  2023,
                  <span className="s4"> </span>this<span className="s4"> </span>
                  section<span className="s4"> </span>is
                  <span className="s4"> </span>
                  not<span className="s4"> </span>applicable.
                  <span className="s4"> </span>A<span className="s4"> </span>
                  historical<span className="s4"> </span>comparison
                  <span className="s4"> </span>of<span className="s4"> </span>
                  the
                  <span className="s4"> </span>period
                  <span className="s4"> </span>
                  reported<span className="s4"> </span>on
                  <span className="s4"> </span>
                  with<span className="s4"> </span>the
                  <span className="s4"> </span>
                  preceding<span className="s4"> </span>period
                  <span className="s4"> </span>will<span className="s4"> </span>
                  be
                  <span className="s4"> </span>included
                  <span className="s4"> </span>in
                  <span className="s4"> </span>the<span className="s4"> </span>
                  2024
                  <span className="s4"> </span>disclosure.
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Footer />
    </>
  );
}
