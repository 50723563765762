import React from 'react'
import styles from './NewsCard.module.scss';
import NewsCardImage from '../../../Assets/Images/news-card.png';
import Pagination from '../../Common/Pagination/Pagination';
import { NavLink } from 'react-router-dom';
export default function NewsCard() {
    return (
        <div>
            <div className={styles.NewsCardGrid}>
                {
                    [0, 1, 2, 3, 4, 5, 6, 7, 8].map(() => {
                        return (
                            <NavLink to="/newsdetails">
                                <div className={styles.NewsCardGridItems}>
                                    <div className={styles.cardImage}>
                                        <img src={NewsCardImage} alt="NewsCardImage" />
                                    </div>
                                    <div className={styles.cardDetails}>

                                        <h2>LRC has exchanged contracts on Atelier in Salford, UK</h2>
                                        <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. </p>
                                        <span>By <a>LRC Group</a> 02 March 2022</span>
                                    </div>
                                </div>
                            </NavLink>
                        )
                    })
                }
            </div>
            <>
                <Pagination />
            </>
        </div>
    )
}
