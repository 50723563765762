import React, { useEffect, useState, useRef } from "react";
import classNames from "classnames";
import useOnScreen from "../../../hooks/useOnScreen";
import styles from './Curabitur.module.scss';
import CurabiturImage from '../../../Assets/Images/curabitur-img.png';

export default function CurabiturSection() {
    const ref = useRef(null);

    const [reveal, setReveal] = useState(false);
    const onScreen = useOnScreen(ref);

    useEffect(() => {
        if (onScreen) setReveal(onScreen);
    }, [onScreen]);
    return (
        <div data-scroll-section>
            <section className={styles.CurabiturSectionAlignment}>
                <div className='container'>
                    <div className={styles.grid}>
                        <div className={styles.gridItems}>
                            <h1>Reducing <span className="italic-class">carbon footprints</span></h1>

                            <div >
                                <p>
                                    LRC ensures its actions have the lowest environmental footprint possible by critically examining the resources, direction of investments and the technology used within company protocols.
                                </p>
                                <p>
                                    LRC is committed to excellence in its property operations. The Group certifies its properties under the highest ESG rankings and demonstrates the integration of best practices for sustainable energy and water management, health and safety and tenant engagement throughout its diverse portfolio.
                                </p>
                            </div>
                        </div>
                        <div className={styles.gridItems}>
                            <img src={CurabiturImage} alt="CurabiturImage" className="flipImage" data-scroll data-scroll-repeat />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
