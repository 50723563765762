import React, { useEffect, useState, useRef } from "react";
import classNames from "classnames";
import useOnScreen from "../../../hooks/useOnScreen";
import styles from "./ResidentialSection.module.scss";
import PropertyImage from '../../../Assets/Images/property-image.png';
import PropertyImageMobile from '../../../Assets/Images/property-image-mobile.png';
import CommericalImage from '../../../Assets/Images/commerical-image.png';
import CommericalImage1 from '../../../Assets/Images/Commercial-1.png';
import CommericalImage1Mobile from '../../../Assets/Images/Commercial-1-mobile.png';
import LivingImage from '../../../Assets/Images/living-image.png';
import LivingImageMobile from '../../../Assets/Images/living-image-mobile.png';

export default function ResidentialSection() {
    const ref = useRef(null);

    const [reveal, setReveal] = useState(false);
    const onScreen = useOnScreen(ref);

    useEffect(() => {
        if (onScreen) setReveal(onScreen);
    }, [onScreen]);
    return (
        <div data-scroll-section>
            <div className={styles.ResidentialSectionBottomAlignment}>
                <section className="container">
                    <div className={styles.ResidentialSectionAlignment}>
                        <div className={styles.titleAlign}>
                            <h1
                                className="c-header_title o-h1"
                                data-scroll data-scroll-speed="1" data-scroll-repeat
                            >
                                <span
                                    className={classNames("c-header_title_line", {
                                        "is-reveal": reveal,
                                    })}
                                >
                                    <span
                                        data-scroll
                                        data-scroll-speed="1"
                                        data-scroll-repeat
                                        data-scroll-position="top"
                                    >
                                        Residential
                                    </span>
                                </span>
                            </h1>
                        </div>
                        <div className={styles.boxEndSide}>
                            <div>
                                <div className={classNames(styles.grid, styles.gridBottomAlign)}>
                                    <div className={styles.gridItems}>
                                        <h1
                                            className="c-header_title o-h1"
                                            data-scroll data-scroll-speed="1" data-scroll-repeat
                                        >
                                            <span
                                                className={classNames("c-header_title_line", {
                                                    "is-reveal": reveal,
                                                })}
                                            >
                                                <span
                                                    data-scroll
                                                    data-scroll-speed="1"
                                                    data-scroll-repeat
                                                    data-scroll-position="top"
                                                >
                                                    Efficient Residential Property Management
                                                </span>
                                            </span>
                                        </h1>
                                        <p data-scroll data-scroll-speed="1">
                                            The backbone of the LRC operation. Tenant relationships and property maintenance are key to preserving and improving the value of residential properties. LRC employs in-house property managers backed by state-of-the-art systems to ensure each property is managed to a high-class standard.
                                        </p>
                                    </div>
                                    <div className={styles.gridItems}>
                                        <img className={styles.mobileViewHiddenImage} src={PropertyImage} alt="PropertyImage" />
                                        <img className={styles.mobileViewImaageShow} src={PropertyImageMobile} alt="PropertyImageMobile" />
                                    </div>
                                </div>
                                <div className={classNames(styles.grid)}>
                                    <div className={styles.gridItems}>
                                        <h1
                                            className="c-header_title o-h1"
                                            data-scroll data-scroll-speed="1" data-scroll-repeat
                                        >
                                            <span
                                                className={classNames("c-header_title_line", {
                                                    "is-reveal": reveal,
                                                })}
                                            >
                                                <span
                                                    data-scroll
                                                    data-scroll-speed="1"
                                                    data-scroll-repeat
                                                    data-scroll-position="top"
                                                >
                                                    All-In-One Living By Vonder
                                                </span>
                                            </span>
                                        </h1>
                                        <p data-scroll data-scroll-speed="1">
                                            LRC’s <a style={{ textDecoration: "underline" }} href="https://www.vondereurope.com/" target="_blank" rel="noopener noreferrer">Vonder</a> is a lifestyle-driven residential management platform which provides significant rental uplift through technology and value-added services. Vonder’s global assets are valued at over €2.2bn and
                                            rapidly growing, with expansion plans for the US and other global cities in the near future.
                                        </p>
                                    </div>
                                    <div className={styles.gridItems}>
                                        <img className={styles.mobileViewHiddenImage} src={LivingImage} alt="LivingImage" />
                                        <img className={styles.mobileViewImaageShow} src={LivingImageMobile} alt="LivingImageMobile" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.ResidentialSectionAlignment}>
                        <div className={styles.titleAlign}>
                            <h1
                                className="c-header_title o-h1"
                                data-scroll data-scroll-speed="1" data-scroll-repeat
                            >
                                <span
                                    className={classNames("c-header_title_line", {
                                        "is-reveal": reveal,
                                    })}
                                >
                                    <span
                                        data-scroll
                                        data-scroll-speed="1"
                                        data-scroll-repeat
                                        data-scroll-position="top"
                                    >
                                        Commercial
                                    </span>
                                </span>
                            </h1>
                        </div>
                        <div className={styles.boxEndSide}>
                            <div>
                                <div className={classNames(styles.grid)}>
                                    <div className={styles.gridItems}>
                                        <h1
                                            className="c-header_title o-h1"
                                            data-scroll data-scroll-speed="1" data-scroll-repeat
                                        >
                                            <span
                                                className={classNames("c-header_title_line", {
                                                    "is-reveal": reveal,
                                                })}
                                            >
                                                <span
                                                    data-scroll
                                                    data-scroll-speed="1"
                                                    data-scroll-repeat
                                                    data-scroll-position="top"
                                                >
                                                    Owning & Managing office buildings
                                                </span>
                                            </span>
                                        </h1>
                                        <p data-scroll data-scroll-speed="1">
                                            Over the past decade, LRC has owned and managed over two million sqm of commercial buildings across Europe. LRC leases these spaces to long-term corporate partners from different fields and industries. LRC ensures that each commercial property is managed and maintained to the highest standard and through that enhances the value of the
                                            property and the tenants’ experience. LRC believes in a mixture of asset management with a focus on value optimisation.
                                        </p>
                                    </div>
                                    <div className={styles.gridItems}>
                                        <img className={styles.mobileViewHiddenImage} src={CommericalImage1} alt="CommericalImage" />
                                        <img className={styles.mobileViewImaageShow} src={CommericalImage1Mobile} alt="CommericalImage1Mobile" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.ResidentialSectionAlignment}>
                        <div className={styles.titleAlign}>
                            <h1
                                className="c-header_title o-h1"
                                data-scroll data-scroll-speed="1" data-scroll-repeat
                            >
                                <span
                                    className={classNames("c-header_title_line", {
                                        "is-reveal": reveal,
                                    })}
                                >
                                    <span
                                        data-scroll
                                        data-scroll-speed="1"
                                        data-scroll-repeat
                                        data-scroll-position="top"
                                    >
                                        Hospitality
                                    </span>
                                </span>
                            </h1>
                        </div>
                        <div className={styles.boxEndSide}>
                            <div>
                                <div className={classNames(styles.grid)}>
                                    <div className={styles.gridItems}>
                                        <h1
                                            className="c-header_title o-h1"
                                            data-scroll data-scroll-speed="1" data-scroll-repeat
                                        >
                                            <span
                                                className={classNames("c-header_title_line", {
                                                    "is-reveal": reveal,
                                                })}
                                            >
                                                <span
                                                    data-scroll
                                                    data-scroll-speed="1"
                                                    data-scroll-repeat
                                                    data-scroll-position="top"
                                                >
                                                    Leading Hospitality Management
                                                </span>
                                            </span>
                                        </h1>
                                        <p data-scroll data-scroll-speed="1">
                                            Amaris is a hospitality platform with a track record of managing 30 hotels and 4,900 rooms under prestigious, international brands
                                            such as Hilton, Mercure and Ibis Styles. Amaris has a heritage of significantly enhancing the quality and performance of its portfolio.
                                        </p>
                                    </div>
                                    <div className={styles.gridItems}>
                                        <img src={CommericalImage} alt="CommericalImage" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}
