import React, { useEffect, useState, useRef } from "react";
import BlackHeader from '../Layout/BlackHeader/BlackHeader'
import styles from './TechnologyPage.module.scss';
import TechnologyBanner from '../../Assets/Images/technology-banner.png';
import TechnologyBannerMobile from '../../Assets/Images/technology-banner-mobile.png';
import RemovingFriction from './RemovingFriction/RemovingFriction';
import DigitalExperience from './DigitalExperience/DigitalExperience';
import TechnologyImprove from './TechnologyImprove/TechnologyImprove';
import TechnologyRewards from './TechnologyRewards/TechnologyRewards';
import UrbenRegeneration from '../CommunityPage/UrbenRegeneration/UrbenRegeneration';
import Footer from '../Layout/Footer/Footer';
import classNames from 'classnames';
import useLocoScroll from '../../hooks/useLocoScroll';
import useOnScreen from "../../hooks/useOnScreen";
import MetaDecorator from "../Util/MetaDecorator";
export default function TechnologyPage() {
    useLocoScroll(true)
    const ref = useRef(null);

    const [reveal, setReveal] = useState(false);
    const onScreen = useOnScreen(ref);

    useEffect(() => {
        if (onScreen) setReveal(onScreen);
    }, [onScreen]);
    return (
        <>

            <div className="sticky-header">
                <BlackHeader />
            </div>
            <div id="main-container" data-scroll-container>
                <MetaDecorator title={"LRC Group | Technology"} description={"Removing friction with technology. Digitalisation of processes enables a seamless experience. We reduce hassle for both tenants and landlords with smart technological solutions, from digital dashboards for landlords to a mobile app for tenants and an online rewards scheme offering lifestyle perks and services. "} />
                <section className={styles.TechnologyPageAlignment} data-scroll-section>
                    <div className='long-container'>
                        <div className={styles.pageTitle}>

                            <h1 className="c-header_title o-h1" data-scroll data-scroll-speed="3" data-scroll-position="top" data-scroll-repeat>
                                <span className={classNames("c-header_title_line", { "is-reveal": reveal })}>
                                    <span data-scroll data-scroll-speed="3" data-scroll-position="top" data-scroll-repeat>Transforming Real Estate through </span>
                                    <span className="italic-class" data-scroll data-scroll-speed="3" data-scroll-position="top" data-scroll-repeat>Technology</span>

                                </span>
                            </h1>
                        </div>
                    </div>
                </section>
                <section className={styles.technologyBanner} data-scroll-section>
                    <div className={classNames(styles.spaceRemveImage, "long-container")}>
                        {/* <img src={TechnologyBanner} alt="TechnologyBanner" className="flipImage" data-scroll data-scroll-repeat /> */}
                        <img className={classNames(styles.communityBannerMobileHidden, "flipImage")} src={TechnologyBanner} alt="AboutBanner" data-scroll data-scroll-repeat />
                        <img className={classNames(styles.communityBannerMobileShow, "flipImage")} src={TechnologyBannerMobile} alt="CommunityBannerMobile" data-scroll data-scroll-repeat />
                    </div>
                </section>
                <>
                    <RemovingFriction />
                </>
                <>
                    <DigitalExperience />
                </>
                <>
                    <TechnologyImprove />
                </>
                <>
                    <TechnologyRewards />
                </>
                <>
                    <UrbenRegeneration />
                </>
                <>
                    <Footer />
                </>
                <div className="footerBottomAlignment"></div>
            </div>
        </>
    )
}
