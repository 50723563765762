import React, { useEffect, useState, useRef } from "react";
import classNames from 'classnames';
import useOnScreen from "../../../hooks/useOnScreen";
import styles from './EnhancingTenansts.module.scss';
import RoomImage from '../../../Assets/Images/room-image.png';
import MobileImage from '../../../Assets/Images/mobile-image.png';
export default function EnhancingTenansts() {
    const ref = useRef(null);

    const [reveal, setReveal] = useState(false);
    const onScreen = useOnScreen(ref);

    useEffect(() => {
        if (onScreen) setReveal(onScreen);
    }, [onScreen]);
    return (
        <div data-scroll-section>
            <section className={styles.EnhancingTenanstsAlignment}>
                <div className='container'>
                    <div className={styles.textGrid}>
                        <div className={styles.textGridItems}>
                            <h1 className="c-header_title o-h1" data-scroll data-scroll-speed="2" data-scroll-repeat>
                                <span className={classNames("c-header_title_line", { "is-reveal": reveal })}>
                                    Enhancing tenants’
                                    <span className="italic-class">personal growth & wellbeing</span>
                                </span>
                            </h1>
                        </div>
                        <div className={styles.textGridItems}>
                            <p>wellbeing</p>
                        </div>
                    </div>
                    <div className={styles.grid}>
                        <div className={styles.gridItems} data-scroll data-scroll-speed="1">
                            <p >
                                LRC’s Vonder and its ‘All-in-one’ concept offers tenants the opportunity to connect via community events such as fitness and yoga classes and meditation sessions. These events bring the global community together and promote a healthy and wellbeing-focused lifestyle within LRC’s properties.
                            </p>
                            <div className={styles.childImage}>
                                <img src={MobileImage} alt="MobileImage" className="flipImage" data-scroll data-scroll-repeat />
                            </div>
                        </div>
                        <div className={styles.gridItems}>
                            <div className={styles.fullImage}>
                                <img src={RoomImage} alt="RoomImage" className="flipImage" data-scroll data-scroll-repeat />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
