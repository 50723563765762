import React, { useEffect, useState, useRef } from "react";
import styles from './TechnologyRewards.module.scss';
import stefanImage from '../../../Assets/Images/stefan.png';
import useOnScreen from "../../../hooks/useOnScreen";
import classNames from "classnames";
export default function TechnologyRewards() {
    const ref = useRef(null);

    const [reveal, setReveal] = useState(false);
    const onScreen = useOnScreen(ref);

    useEffect(() => {
        if (onScreen) setReveal(onScreen);
    }, [onScreen]);
    return (
        <div data-scroll-section>
            <div className={styles.TechnologyRewardsBanner}>
                <div className='container'>
                    <div className={styles.textGrid}>
                        <div className={styles.textGridItems}>
                            <a>lifestyle</a>

                            <h1
                                className="c-header_title o-h1"
                                data-scroll data-scroll-speed="0.5" data-scroll-repeat
                            >
                                <span
                                    className={classNames("c-header_title_line", {
                                        "is-reveal": reveal,
                                    })}
                                >
                                    <span
                                        data-scroll
                                        data-scroll-speed="1"
                                        data-scroll-repeat
                                        data-scroll-position="top"

                                    >
                                        Rewards & lifesyle platforms{" "}
                                    </span>
                                </span>
                            </h1>
                        </div>
                        <div className={styles.textGridItems} data-scroll data-scroll-speed="1">
                            <p>
                                Vonder’s rewards scheme offers a comprehensive digital experience with a variety of services and perks, such
                                as exclusive discounts for ordering groceries, booking transportation, purchasing wellness products and much more.
                            </p>
                            <p>
                                We partner with market leaders across different fields and industries to
                                offer maximum added value to our tenants.
                            </p>
                        </div>
                    </div>
                    <div className={styles.grid}>
                        <div className={styles.gridItems}>
                            <img src={stefanImage} alt="stefanImage" className="flipImage" data-scroll data-scroll-repeat />
                        </div>
                        <div className={styles.gridItems}>
                            <h1
                                className="c-header_title o-h1"
                                data-scroll data-scroll-speed="0.5" data-scroll-repeat
                            >
                                <span
                                    className={classNames("c-header_title_line", {
                                        "is-reveal": reveal,
                                    })}
                                >
                                    <span
                                        data-scroll
                                        data-scroll-speed="1"
                                        data-scroll-repeat
                                        data-scroll-position="top"

                                    >
                                        We provide our tenants with a variety of value-add services & offerings as part of one all-inclusive platform.
                                    </span>
                                </span>
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
