import React, { useEffect, useState, useRef } from "react";
import classNames from "classnames";
import useOnScreen from "../../../hooks/useOnScreen";
import styles from './TechnologySection.module.scss';
import TransformingImage from '../../../Assets/Images/solar-panels.jpeg';
import CommunitiesImage from '../../../Assets/Images/wellbeing-banner.png';
import WellbeingBannerMobile from '../../../Assets/Images/wellbeing-banner-mobile.png';
import CommunitiesImageNew from '../../../Assets/Images/communities-new.png';
import technolodgy from '../../../Assets/Images/technolodgy.png';
import scale from '../../../Assets/Images/scale.png';
import { NavLink, useHistory } from "react-router-dom/cjs/react-router-dom.min";
export default function TechnologySection() {
  const history = useHistory();
  const ref = useRef(null);

  const [reveal, setReveal] = useState(false);
  const onScreen = useOnScreen(ref);
  const isRouteHandler = (route) => {
    history.push(`/${route}`);

  }
  useEffect(() => {
    if (onScreen) setReveal(onScreen);
  }, [onScreen]);
  return (
    <div data-scroll-section>
      <section className={classNames(styles.communtiesAlignment, styles.bottomSpaceRemoveSection)}>
        <div className={styles.TechnologySectionAlignment}>
          <div className='container'>
            <div className={styles.grid}>
              <div className={styles.gridItems}>
                <div>
                  <a>TECHNOLOGY</a>
                </div>
                <h3
                  className="c-header_title o-h1"
                  data-scroll data-scroll-speed="1" data-scroll-repeat
                >
                  <span
                    className={classNames("c-header_title_line", {
                      "is-reveal": reveal,
                    })}
                  >
                    <span
                      data-scroll
                      data-scroll-speed="1"
                      data-scroll-repeat
                      data-scroll-position="top"
                    >
                      LRC’s advanced technology allows us to operate{" "}
                    </span>
                    <span className="italic-class" data-scroll data-scroll-speed="1" data-scroll-position="top" data-scroll-repeat>efficiently </span>
                    <span data-scroll data-scroll-speed="1" data-scroll-position="top" data-scroll-repeat>and to </span>
                    <span className="italic-class" data-scroll data-scroll-speed="1" data-scroll-position="top" data-scroll-repeat>maintain positive unit economics.</span>
                  </span>
                </h3>
                <NavLink to="/technology">
                  <button data-scroll data-scroll-speed="1">
                    <span>Learn More</span>
                    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7.24277 0.410734C7.56821 0.0852973 8.09585 0.0852973 8.42129 0.410734L13.4213 5.41074C13.5776 5.56702 13.6654 5.77898 13.6654 5.99999C13.6654 6.221 13.5776 6.43297 13.4213 6.58925L8.42129 11.5892C8.09585 11.9147 7.56821 11.9147 7.24277 11.5892C6.91734 11.2638 6.91734 10.7362 7.24277 10.4107L10.8202 6.83332L1.16536 6.83332C0.705127 6.83332 0.332031 6.46023 0.332031 5.99999C0.332031 5.53975 0.705127 5.16666 1.16536 5.16666L10.8202 5.16666L7.24277 1.58925C6.91734 1.26381 6.91734 0.736171 7.24277 0.410734Z" fill="#121D24" />
                    </svg>
                  </button>
                </NavLink>
              </div>
              <div className={styles.gridItems}>
                <div className={styles.trasnformingBox}>
                  <h1
                    className="c-header_title o-h1"
                    data-scroll data-scroll-speed="1" data-scroll-repeat
                  >
                    <span
                      className={classNames("c-header_title_line", {
                        "is-reveal": reveal,
                      })}
                    >
                      <span
                        data-scroll
                        data-scroll-speed="1"
                        data-scroll-repeat
                        data-scroll-position="top"
                      >
                        Transforming Real Estate through{" "}
                      </span>
                      <span className={classNames("italic-class", styles.underlineAlignment)} data-scroll data-scroll-speed="1" data-scroll-position="top" data-scroll-repeat onClick={() => isRouteHandler("technology")}>Technology</span>
                    </span>
                  </h1>
                  <div data-scroll data-scroll-speed="1">
                    <h2>Removing friction for landlords and tenants  </h2>
                    <p>
                      LRC reduces the hassle for both tenants and landlords with smart technological solutions. Digitalisation of processes enables LRC to offer a seamless experience.
                    </p>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className='container'>
              <div className={styles.transformingImage}>
                <img src={technolodgy} alt="CommunitiesImage" className="flipImage" data-scroll data-scroll-repeat />
              </div>
            </div>
          </div>
          <div className={styles.mobileTextShow}>
            <h3
              className="c-header_title o-h1"
              data-scroll data-scroll-speed="1" data-scroll-repeat
            >
              <span
                className={classNames("c-header_title_line", {
                  "is-reveal": reveal,
                })}
              >
                <span
                  data-scroll
                  data-scroll-speed="1"
                  data-scroll-repeat
                  data-scroll-position="top"
                >
                  LRC’s advanced technology allows us to operate{" "}
                </span>
                <span className="italic-class" data-scroll data-scroll-speed="1" data-scroll-position="top" data-scroll-repeat>efficiently </span>
                <span data-scroll data-scroll-speed="1" data-scroll-position="top" data-scroll-repeat>and to </span>
                <span className="italic-class" data-scroll data-scroll-speed="1" data-scroll-position="top" data-scroll-repeat>maintain positive unit economics.</span>
              </span>
            </h3>
            <NavLink to="/technology">
              <button data-scroll data-scroll-speed="1">
                <span>Learn More</span>
                <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.24277 0.410734C7.56821 0.0852973 8.09585 0.0852973 8.42129 0.410734L13.4213 5.41074C13.5776 5.56702 13.6654 5.77898 13.6654 5.99999C13.6654 6.221 13.5776 6.43297 13.4213 6.58925L8.42129 11.5892C8.09585 11.9147 7.56821 11.9147 7.24277 11.5892C6.91734 11.2638 6.91734 10.7362 7.24277 10.4107L10.8202 6.83332L1.16536 6.83332C0.705127 6.83332 0.332031 6.46023 0.332031 5.99999C0.332031 5.53975 0.705127 5.16666 1.16536 5.16666L10.8202 5.16666L7.24277 1.58925C6.91734 1.26381 6.91734 0.736171 7.24277 0.410734Z" fill="#121D24" />
                </svg>
              </button>
            </NavLink>
          </div>
        </div>
      </section>
      <section className={styles.communtiesAlignment}>
        <div className={styles.TechnologySectionAlignment}>
          <div className='container'>
            <div className={styles.gridChildSec}>

              <div className={styles.gridItems}>
                <div className={styles.trasnformingBox}>
                  <h1
                    className="c-header_title o-h1"
                    data-scroll data-scroll-speed="1" data-scroll-repeat
                  >
                    <span
                      className={classNames("c-header_title_line", {
                        "is-reveal": reveal,
                      })}
                    >
                      <span
                        data-scroll
                        data-scroll-speed="1"
                        data-scroll-repeat
                        data-scroll-position="top"
                      >
                        Transforming Real Estate through{" "}
                      </span>
                      <span className={classNames("italic-class", styles.underlineAlignment)} data-scroll data-scroll-speed="1" data-scroll-position="top" data-scroll-repeat onClick={() => isRouteHandler("scale")}>Scale</span>
                    </span>
                  </h1>
                  <div data-scroll data-scroll-speed="1">
                    <h2>LRC Group’s <span className="italic-class">Portfolio</span></h2>
                    <p>
                      LRC uses the leverage of scale to allow cost-effective solutions across its real estate ecosystem. With 15k residential units under management, LRC is one of the largest PRS platforms in Europe.
                    </p>
                  </div>
                </div>
              </div>
              <div className={styles.gridItems}>
                <div>
                  <a>SCALE</a>
                </div>
                <h3
                  className="c-header_title o-h1"
                  data-scroll data-scroll-speed="1" data-scroll-repeat
                >
                  <span
                    className={classNames("c-header_title_line", {
                      "is-reveal": reveal,
                    })}
                  >
                    <span
                      data-scroll
                      data-scroll-speed="1"
                      data-scroll-repeat
                      data-scroll-position="top"
                    >
                      LRC deploys significant capital to achieve investment strategies, focusing on{" "}
                    </span>
                    <span className="italic-class" data-scroll data-scroll-speed="1" data-scroll-position="top" data-scroll-repeat>fast growth in key areas and cities.</span>
                  </span>
                </h3>
                <NavLink to="/scale">
                  <button data-scroll data-scroll-speed="1">
                    <span>Learn More</span>
                    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7.24277 0.410734C7.56821 0.0852973 8.09585 0.0852973 8.42129 0.410734L13.4213 5.41074C13.5776 5.56702 13.6654 5.77898 13.6654 5.99999C13.6654 6.221 13.5776 6.43297 13.4213 6.58925L8.42129 11.5892C8.09585 11.9147 7.56821 11.9147 7.24277 11.5892C6.91734 11.2638 6.91734 10.7362 7.24277 10.4107L10.8202 6.83332L1.16536 6.83332C0.705127 6.83332 0.332031 6.46023 0.332031 5.99999C0.332031 5.53975 0.705127 5.16666 1.16536 5.16666L10.8202 5.16666L7.24277 1.58925C6.91734 1.26381 6.91734 0.736171 7.24277 0.410734Z" fill="#121D24" />
                    </svg>
                  </button>
                </NavLink>
              </div>
            </div>
          </div>
          <div>
            <div className='container'>
              <div className={styles.transformingImageleftAlign}>
                <img src={scale} alt="CommunitiesImage" className="flipImage" data-scroll data-scroll-repeat />
              </div>
            </div>
          </div>
          <div className={styles.mobileTextShow}>
            <h3
              className="c-header_title o-h1"
              data-scroll data-scroll-speed="1" data-scroll-repeat
            >
              <span
                className={classNames("c-header_title_line", {
                  "is-reveal": reveal,
                })}
              >
                <span
                  data-scroll
                  data-scroll-speed="1"
                  data-scroll-repeat
                  data-scroll-position="top"
                >
                  LRC deploys significant capital to achieve investment strategies, focusing on{" "}
                </span>
                <span className="italic-class" data-scroll data-scroll-speed="1" data-scroll-position="top" data-scroll-repeat>fast growth in key areas and cities.</span>
              </span>
            </h3>
            <NavLink to="/scale">
              <button data-scroll data-scroll-speed="1">
                <span>Learn More</span>
                <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.24277 0.410734C7.56821 0.0852973 8.09585 0.0852973 8.42129 0.410734L13.4213 5.41074C13.5776 5.56702 13.6654 5.77898 13.6654 5.99999C13.6654 6.221 13.5776 6.43297 13.4213 6.58925L8.42129 11.5892C8.09585 11.9147 7.56821 11.9147 7.24277 11.5892C6.91734 11.2638 6.91734 10.7362 7.24277 10.4107L10.8202 6.83332L1.16536 6.83332C0.705127 6.83332 0.332031 6.46023 0.332031 5.99999C0.332031 5.53975 0.705127 5.16666 1.16536 5.16666L10.8202 5.16666L7.24277 1.58925C6.91734 1.26381 6.91734 0.736171 7.24277 0.410734Z" fill="#121D24" />
                </svg>
              </button>
            </NavLink>
          </div>
        </div>
      </section>

      <section className={styles.TechnologySectionAlignment}>
        <div className='container'>
          <div className={styles.grid}>
            <div className={styles.gridItems}>
              <div>
                <a>SUSTAINABILITY</a>
              </div>
              <h3
                className="c-header_title o-h1"
                data-scroll data-scroll-speed="1" data-scroll-repeat
              >
                <span
                  className={classNames("c-header_title_line", {
                    "is-reveal": reveal,
                  })}
                >
                  <span
                    data-scroll
                    data-scroll-speed="1"
                    data-scroll-repeat
                    data-scroll-position="top"
                  >
                    LRC introduces and encourages a new way of consuming sustainable, environmentally friendly products to its developers, landlords and tenants.
                  </span>
                </span>
              </h3>
              <NavLink to="/sustainability">
                <button data-scroll data-scroll-speed="1">
                  <span>Learn More</span>
                  <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.24277 0.410734C7.56821 0.0852973 8.09585 0.0852973 8.42129 0.410734L13.4213 5.41074C13.5776 5.56702 13.6654 5.77898 13.6654 5.99999C13.6654 6.221 13.5776 6.43297 13.4213 6.58925L8.42129 11.5892C8.09585 11.9147 7.56821 11.9147 7.24277 11.5892C6.91734 11.2638 6.91734 10.7362 7.24277 10.4107L10.8202 6.83332L1.16536 6.83332C0.705127 6.83332 0.332031 6.46023 0.332031 5.99999C0.332031 5.53975 0.705127 5.16666 1.16536 5.16666L10.8202 5.16666L7.24277 1.58925C6.91734 1.26381 6.91734 0.736171 7.24277 0.410734Z" fill="#121D24" />
                  </svg>
                </button>
              </NavLink>
            </div>
            <div className={styles.gridItems}>
              <div className={styles.trasnformingBox}>
                <h1
                  className="c-header_title o-h1"
                  data-scroll data-scroll-speed="1" data-scroll-repeat
                >
                  <span
                    className={classNames("c-header_title_line", {
                      "is-reveal": reveal,
                    })}
                  >
                    <span
                      data-scroll
                      data-scroll-speed="1"
                      data-scroll-repeat
                      data-scroll-position="top"
                    >
                      Transforming Real Estate through{" "}
                    </span>
                    <span className={classNames("italic-class", styles.underlineAlignment)} data-scroll data-scroll-speed="1" data-scroll-position="top" data-scroll-repeat onClick={() => isRouteHandler("sustainability")}>Sustainability</span>
                  </span>
                </h1>
                <div data-scroll data-scroll-speed="1">
                  <h2>Building a <span className='italic-class'>green & sustainable ecosystem</span></h2>
                  <p>
                    LRC ensures its actions have the lowest environmental footprint possible by critically examining the resources, direction of investments and the technology used within company protocols.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className='container'>
          <div className={styles.transformingImage}>
            <img src={TransformingImage} alt="TransformingImage" className="flipImage" data-scroll data-scroll-repeat />
          </div>
        </div>
        <div className={styles.mobileTextShow}>
          <h3
            className="c-header_title o-h1"
            data-scroll data-scroll-speed="1" data-scroll-repeat
          >
            <span
              className={classNames("c-header_title_line", {
                "is-reveal": reveal,
              })}
            >
              <span
                data-scroll
                data-scroll-speed="1"
                data-scroll-repeat
                data-scroll-position="top"
              >
                LRC introduces and encourages a new way of consuming sustainable, environmentally friendly products to its developers, landlords and tenants.
              </span>
            </span>
          </h3>
          <NavLink to="/sustainability">
            <button data-scroll data-scroll-speed="1">
              <span>Learn More</span>
              <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.24277 0.410734C7.56821 0.0852973 8.09585 0.0852973 8.42129 0.410734L13.4213 5.41074C13.5776 5.56702 13.6654 5.77898 13.6654 5.99999C13.6654 6.221 13.5776 6.43297 13.4213 6.58925L8.42129 11.5892C8.09585 11.9147 7.56821 11.9147 7.24277 11.5892C6.91734 11.2638 6.91734 10.7362 7.24277 10.4107L10.8202 6.83332L1.16536 6.83332C0.705127 6.83332 0.332031 6.46023 0.332031 5.99999C0.332031 5.53975 0.705127 5.16666 1.16536 5.16666L10.8202 5.16666L7.24277 1.58925C6.91734 1.26381 6.91734 0.736171 7.24277 0.410734Z" fill="#121D24" />
              </svg>
            </button>
          </NavLink>
        </div>
      </section>
      <section className={styles.communtiesAlignment}>
        <div className={styles.TechnologySectionAlignment}>
          <div className='container'>
            <div className={classNames(styles.communitiesMobileViewAlignment, styles.gridChildSec)}>

              <div className={styles.gridItems}>
                <div className={styles.trasnformingBox}>
                  <h1
                    className="c-header_title o-h1"
                    data-scroll data-scroll-speed="1" data-scroll-repeat
                  >
                    <span
                      className={classNames("c-header_title_line", {
                        "is-reveal": reveal,
                      })}
                    >
                      <span
                        data-scroll
                        data-scroll-speed="1"
                        data-scroll-repeat
                        data-scroll-position="top"
                      >
                        Transforming Real Estate through {" "}
                      </span>
                      <span className={classNames("italic-class", styles.underlineAlignment)} data-scroll data-scroll-speed="1" data-scroll-position="top" data-scroll-repeat onClick={() => isRouteHandler("community")}>Communities</span>
                    </span>
                  </h1>
                  <div data-scroll data-scroll-speed="1">
                    <h2>Empowering <span className='italic-class'>communities</span></h2>
                    <p>
                      LRC’s products and platforms are built around communities. The group develops healthy, resilient communities through intergration with local businesses and dedication to social, economic and environmental sustainability.
                    </p>
                  </div>
                </div>
              </div>
              <div className={classNames(styles.communtiesMobileAlign, styles.gridItems)}>
                <div >
                  <a>communities</a>
                </div>
                <h3
                  className="c-header_title o-h1"
                  data-scroll data-scroll-speed="1" data-scroll-repeat
                >
                  <span
                    className={classNames("c-header_title_line", {
                      "is-reveal": reveal,
                    })}
                  >
                    <span
                      data-scroll
                      data-scroll-speed="1"
                      data-scroll-repeat
                      data-scroll-position="top"
                    >
                      LRC creates opportunities for tenants to
                    </span>
                    <span className="italic-class" data-scroll data-scroll-speed="1" data-scroll-position="top" data-scroll-repeat> connect, interact and create long-lasting relationships.</span>
                  </span>
                </h3>
                <NavLink to="/community">
                  <button data-scroll data-scroll-speed="1">
                    <span>Learn More</span>
                    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7.24277 0.410734C7.56821 0.0852973 8.09585 0.0852973 8.42129 0.410734L13.4213 5.41074C13.5776 5.56702 13.6654 5.77898 13.6654 5.99999C13.6654 6.221 13.5776 6.43297 13.4213 6.58925L8.42129 11.5892C8.09585 11.9147 7.56821 11.9147 7.24277 11.5892C6.91734 11.2638 6.91734 10.7362 7.24277 10.4107L10.8202 6.83332L1.16536 6.83332C0.705127 6.83332 0.332031 6.46023 0.332031 5.99999C0.332031 5.53975 0.705127 5.16666 1.16536 5.16666L10.8202 5.16666L7.24277 1.58925C6.91734 1.26381 6.91734 0.736171 7.24277 0.410734Z" fill="#121D24" />
                    </svg>
                  </button>
                </NavLink>
              </div>
            </div>
          </div>
          <div>
            <div className='container'>
              <div className={styles.transformingImageleftAlign}>
                <img src={CommunitiesImageNew} alt="CommunitiesImageNew" className="flipImage" data-scroll data-scroll-repeat />
              </div>
            </div>
          </div>
          <div className={styles.mobileTextShow}>
            <h3
              className="c-header_title o-h1"
              data-scroll data-scroll-speed="1" data-scroll-repeat
            >
              <span
                className={classNames("c-header_title_line", {
                  "is-reveal": reveal,
                })}
              >
                <span
                  data-scroll
                  data-scroll-speed="1"
                  data-scroll-repeat
                  data-scroll-position="top"
                >
                  LRC creates opportunities for tenants to
                </span>
                <span className="italic-class" data-scroll data-scroll-speed="1" data-scroll-position="top" data-scroll-repeat> connect, interact and create long-lasting relationships.</span>
              </span>
            </h3>
            <NavLink to="/community">
              <button data-scroll data-scroll-speed="1">
                <span>Learn More</span>
                <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.24277 0.410734C7.56821 0.0852973 8.09585 0.0852973 8.42129 0.410734L13.4213 5.41074C13.5776 5.56702 13.6654 5.77898 13.6654 5.99999C13.6654 6.221 13.5776 6.43297 13.4213 6.58925L8.42129 11.5892C8.09585 11.9147 7.56821 11.9147 7.24277 11.5892C6.91734 11.2638 6.91734 10.7362 7.24277 10.4107L10.8202 6.83332L1.16536 6.83332C0.705127 6.83332 0.332031 6.46023 0.332031 5.99999C0.332031 5.53975 0.705127 5.16666 1.16536 5.16666L10.8202 5.16666L7.24277 1.58925C6.91734 1.26381 6.91734 0.736171 7.24277 0.410734Z" fill="#121D24" />
                </svg>
              </button>
            </NavLink>
          </div>
        </div>
      </section>
      <section className={styles.TechnologySectionAlignment}>
        <div className='container'>
          <div className={styles.grid}>
            <div className={styles.gridItems}>
              <div>
                <a>Wellbeing</a>
              </div>
              <h3
                className="c-header_title o-h1"
                data-scroll data-scroll-speed="1" data-scroll-repeat
              >
                <span
                  className={classNames("c-header_title_line", {
                    "is-reveal": reveal,
                  })}
                >
                  <span
                    data-scroll
                    data-scroll-speed="1"
                    data-scroll-repeat
                    data-scroll-position="top"
                  >
                    LRC partnered with Delos to promote the Stay Well™ program.
                  </span>
                </span>
              </h3>
              <NavLink to="/wellbeing">
                <button data-scroll data-scroll-speed="1">
                  <span>Learn More</span>
                  <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.24277 0.410734C7.56821 0.0852973 8.09585 0.0852973 8.42129 0.410734L13.4213 5.41074C13.5776 5.56702 13.6654 5.77898 13.6654 5.99999C13.6654 6.221 13.5776 6.43297 13.4213 6.58925L8.42129 11.5892C8.09585 11.9147 7.56821 11.9147 7.24277 11.5892C6.91734 11.2638 6.91734 10.7362 7.24277 10.4107L10.8202 6.83332L1.16536 6.83332C0.705127 6.83332 0.332031 6.46023 0.332031 5.99999C0.332031 5.53975 0.705127 5.16666 1.16536 5.16666L10.8202 5.16666L7.24277 1.58925C6.91734 1.26381 6.91734 0.736171 7.24277 0.410734Z" fill="#121D24" />
                  </svg>
                </button>
              </NavLink>
            </div>
            <div className={styles.gridItems}>
              <div className={styles.trasnformingBox}>
                <h1
                  className="c-header_title o-h1"
                  data-scroll data-scroll-speed="1" data-scroll-repeat
                >
                  <span
                    className={classNames("c-header_title_line", {
                      "is-reveal": reveal,
                    })}
                  >
                    <span
                      data-scroll
                      data-scroll-speed="1"
                      data-scroll-repeat
                      data-scroll-position="top"
                    >
                      Transforming Real Estate through{" "}
                    </span>
                    <span className={classNames("italic-class", styles.underlineAlignment)} data-scroll data-scroll-speed="1" data-scroll-position="top" data-scroll-repeat onClick={() => isRouteHandler("wellbeing")}>Wellbeing</span>
                  </span>
                </h1>
                <div data-scroll data-scroll-speed="1">
                  <h2>Emphasising personal growth & wellbeing</h2>
                  <p>
                    Tenant wellbeing is a priority within all LRC projects. The group uses technology to offer healthier living and working spaces which support personal growth and development.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={styles.belowalignment}>
        <div className='container'>
          <div className={styles.transformingImage}>
            <img className={classNames(styles.communityBannerMobileHidden, "flipImage")} src={CommunitiesImage} alt="CommunitiesImage" data-scroll data-scroll-repeat />
            <img className={classNames(styles.communityBannerMobileShow, "flipImage")} src={WellbeingBannerMobile} alt="WellbeingBannerMobile" data-scroll data-scroll-repeat />
          </div>
        </div>
      </section>
      <div className={classNames(styles.mobileTextShowLast, styles.mobileTextShow)}>
        <h3
          className="c-header_title o-h1"
          data-scroll data-scroll-speed="1" data-scroll-repeat
        >
          <span
            className={classNames("c-header_title_line", {
              "is-reveal": reveal,
            })}
          >
            <span
              data-scroll
              data-scroll-speed="1"
              data-scroll-repeat
              data-scroll-position="top"
            >
              LRC partnered with Delos to promote the Stay Well™ program.
            </span>
          </span>
        </h3>
        <NavLink to="/wellbeing">
          <button data-scroll data-scroll-speed="1">
            <span>Learn More</span>
            <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.24277 0.410734C7.56821 0.0852973 8.09585 0.0852973 8.42129 0.410734L13.4213 5.41074C13.5776 5.56702 13.6654 5.77898 13.6654 5.99999C13.6654 6.221 13.5776 6.43297 13.4213 6.58925L8.42129 11.5892C8.09585 11.9147 7.56821 11.9147 7.24277 11.5892C6.91734 11.2638 6.91734 10.7362 7.24277 10.4107L10.8202 6.83332L1.16536 6.83332C0.705127 6.83332 0.332031 6.46023 0.332031 5.99999C0.332031 5.53975 0.705127 5.16666 1.16536 5.16666L10.8202 5.16666L7.24277 1.58925C6.91734 1.26381 6.91734 0.736171 7.24277 0.410734Z" fill="#121D24" />
            </svg>
          </button>
        </NavLink>
      </div>
    </div>
  )
}
