import React, { useEffect, useState, useRef } from "react";
import styles from './EnquiriesForm.module.scss';
import RightArrow from '../../../Assets/Images/right-arrow.svg';
import CheckIcon from '../../../Assets/Images/check-icon.svg';
import classNames from 'classnames';
import useOnScreen from "../../../hooks/useOnScreen";
import swal from 'sweetalert';
import * as emailjs from '@emailjs/browser';
emailjs.init("5XrfZmHXlE4FrPl35");
export default function EnquiriesForm() {
    const [names, setName] = useState("")
    const [surnames, setSurnames] = useState("")
    const [phones, setPhones] = useState("")
    const [emails, setEmails] = useState("")
    const [messages, setMessage] = useState("")
    const ref = useRef(null);
    const [Success, setSuccess] = useState(false)
    const [reveal, setReveal] = useState(false);
    const onScreen = useOnScreen(ref);

    useEffect(() => {
        if (onScreen) setReveal(onScreen);
    }, [onScreen]);
    const isName = (e) => {
        setName(e.target.value)
    }
    const isSurname = (e) => {
        setSurnames(e.target.value)
    }
    const isEmail = (e) => {
        setEmails(e.target.value)
    }
    const isPhone = (e) => {
        setPhones(e.target.value)
    }
    const isMessage = (e) => {
        setMessage(e.target.value)
    }
    const isSubmitHandler = () => {
        const emailValid = emails.match(
            /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
        );
        if (emailValid) {

            emailjs.send("service_kotkbx6", "template_s2wgj6q", {
                from_name: names,
                surname: surnames,
                email: emails,
                phone: phones,
                message: messages,
            });
            // swal("sent successfully!", "", "success");
            setSuccess(true)
            setName("")
            setEmails("")
            setSurnames("")
            setMessage("")
            setPhones("")
        } else {
            swal("something went wrong!", "Please Enter Email", "error");
        }
    }
    return (
        <div data-scroll-section>
            <section className={styles.enquiresFormAlignment}>
                <div className='container'>
                    <div className={styles.grid}>
                        <div className={styles.gridItems}>
                            {/* <h1>For any <span>enquiries</span> or questions, please complete the <span>form:</span></h1> */}
                            <h1
                                className="c-header_title o-h1"
                                data-scroll data-scroll-speed="0.5" data-scroll-repeat
                            >
                                <span
                                    className={classNames("c-header_title_line", {
                                        "is-reveal": reveal,
                                    })}
                                >
                                    <span
                                        data-scroll
                                        data-scroll-speed="1"
                                        data-scroll-repeat
                                        data-scroll-position="top"

                                    >
                                        For any{" "}
                                    </span>
                                    <span className="italic-class" data-scroll data-scroll-speed="1" data-scroll-position="top" data-scroll-repeat>enquiries{" "}</span>
                                    <span data-scroll data-scroll-speed="1" data-scroll-position="top" data-scroll-repeat>or questions, please complete the{" "}</span>
                                    <span className="italic-class" data-scroll data-scroll-speed="1" data-scroll-position="top" data-scroll-repeat>form:{" "}</span>
                                </span>
                            </h1>
                        </div>
                        <div className={styles.gridItems}>
                            <p >Contact</p>
                            {/* <div className={styles.cusDropdownFiled}>
                                <div className={styles.formControl}>
                                    <input type="text" placeholder='What are you interested in?' />
                                    <div className={styles.dropDownAlignment}>
                                        <img src={DownICon} alt="DownICon" />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.cusDropdownFiled}>
                                <div className={styles.formControl}>
                                    <input type="text" placeholder='Type of Real Estate' />
                                    <div className={styles.dropDownAlignment}>
                                        <img src={DownICon} alt="DownICon" />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.cusDropdownFiled}>
                                <div className={styles.formControl}>
                                    <input type="text" placeholder='Country' />
                                    <div className={styles.dropDownAlignment}>
                                        <img src={DownICon} alt="DownICon" />
                                    </div>
                                </div>
                            </div> */}
                            {!Success && <>   <div className={styles.formGrid}>
                                <div className={styles.formGridItems}>
                                    <div className={styles.formControl}>
                                        <input type="text" placeholder='Your name *' value={names} onChange={(e) => isName(e)} />
                                    </div>
                                </div>
                                <div className={styles.formGridItems}>
                                    <div className={styles.formControl}>
                                        <input type="text" placeholder='Surname*' value={surnames} onChange={(e) => isSurname(e)} />
                                    </div>
                                </div>
                                <div className={styles.formGridItems}>
                                    <div className={styles.formControl}>
                                        <input type="text" placeholder='Email*' value={emails} onChange={(e) => isEmail(e)} />
                                    </div>
                                </div>
                                <div className={styles.formGridItems}>
                                    <div className={styles.formControl}>
                                        <input type="text" placeholder='Phone*' value={phones} onChange={(e) => isPhone(e)} />
                                    </div>
                                </div>
                            </div>
                                <div className={styles.formControl}>
                                    <textarea placeholder='Message:' value={messages} onChange={(e) => isMessage(e)} />
                                </div>
                                <div className={styles.subMitButton}>
                                    <button onClick={() => isSubmitHandler()}>
                                        <span>Submit</span>
                                        <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.24473 0.412046C7.57016 0.0866095 8.0978 0.0866095 8.42324 0.412046L13.4232 5.41205C13.5795 5.56833 13.6673 5.78029 13.6673 6.0013C13.6673 6.22232 13.5795 6.43428 13.4232 6.59056L8.42324 11.5906C8.0978 11.916 7.57016 11.916 7.24473 11.5906C6.91929 11.2651 6.91929 10.7375 7.24473 10.412L10.8221 6.83464L1.16732 6.83464C0.70708 6.83464 0.333984 6.46154 0.333984 6.0013C0.333984 5.54107 0.70708 5.16797 1.16732 5.16797L10.8221 5.16797L7.24473 1.59056C6.91929 1.26512 6.91929 0.737483 7.24473 0.412046Z" fill="#333B49" />
                                        </svg>
                                    </button>
                                </div></>}
                            {Success && <div className={styles.thankyouMessage}>
                                <div className={styles.checkIconAlign}>
                                    <img src={CheckIcon} alt="CheckIcon" />
                                </div>
                                <div>

                                    <h6>
                                        Thank you for contacting us, we
                                        will review and contact you within 5 working days
                                    </h6>
                                </div>
                            </div>}

                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
