import React, { useEffect, useState, useRef } from "react";
import classNames from "classnames";
import styles from './OurApproach.module.scss';
import useOnScreen from "../../../hooks/useOnScreen";
export default function OurApproach() {
  const ref = useRef(null);

  const [reveal, setReveal] = useState(false);
  const onScreen = useOnScreen(ref);

  useEffect(() => {
    if (onScreen) setReveal(onScreen);
  }, [onScreen]);
  return (
    <div data-scroll-section>
      <section className={styles.OurApproachAlignment}>
        <div className='container'>
          <div className={styles.grid}>
            <div className={styles.gridItems}>
              <h1>LRC has <span className='italic-class'>sourced and acquired </span>real estate assets with a <span className='italic-class'>total value of €6bn</span></h1>

            </div>
            <div className={styles.gridItems}>
              <h6>LRC’s approach</h6>
            </div>
          </div>
          <div className={styles.subTextGrid} >
            <div className={styles.subTextGridItems}>
              <p>
                The LRC Group is a privately held real estate investment and fund management firm. LRC specialises in structuring and executing value-add impact investments and enhancing efficiencies through experienced in-house asset & property management teams.
              </p>
            </div>
            <div className={styles.subTextGridItems}>
              <p>
                As an opportunity-driven investment and asset management group, LRC is not bound by fixed investment profiles and return requirements and can assess every opportunity based on its individual merits without limitations to asset class, volume or jurisdiction.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
