import React, { useEffect, useState, useRef } from "react";
import classNames from "classnames";

import styles from './Sustainability.module.scss';
import SustainableImage from '../../Assets/Images/solar-panels.jpeg';
import CurabiturSection from './CurabiturSection/CurabiturSection';
import CurabiturBanner from './CurabiturBanner/CurabiturBanner';
import ChartView from './ChartView/ChartView';
import MaurisMaximus from './MaurisMaximus/MaurisMaximus';
import Footer from '../Layout/Footer/Footer';
import BlackHeader from '../Layout/BlackHeader/BlackHeader';
import useLocoScroll from '../../hooks/useLocoScroll';
import useOnScreen from "../../hooks/useOnScreen";
import MetaDecorator from "../Util/MetaDecorator";
import UrbenRegeneration from "../CommunityPage/UrbenRegeneration/UrbenRegeneration";
export default function Sustainability() {
    useLocoScroll(true)
    const ref = useRef(null);

    const [reveal, setReveal] = useState(false);
    const onScreen = useOnScreen(ref);

    useEffect(() => {
        if (onScreen) setReveal(onScreen);
    }, [onScreen]);
    return (
        <>

            <div className="sticky-header">
                <BlackHeader />
            </div>
            <div id="main-container" data-scroll-container>
                <MetaDecorator title={"LRC Group | Sustainability"} description={"LRC ensures its actions have the lowest environmental footprint possible by critically examining the resources, direction of investments and the technology used within company protocols. "} />
                <div className={classNames("long-container")}>
                    <div className={styles.SustainabilityPageAlignment} data-scroll-section>
                        <div className={styles.pageTitle}>
                            <h1
                                className="c-header_title o-h1"
                                data-scroll data-scroll-speed="1" data-scroll-position="top" data-scroll-repeat
                            >
                                <span
                                    className={classNames("c-header_title_line", {
                                        "is-reveal": reveal,
                                    })}
                                >
                                    <span
                                        data-scroll
                                        data-scroll-speed="1"
                                        data-scroll-repeat
                                        data-scroll-position="top"
                                    >
                                        Transforming real estate through{" "}
                                    </span>
                                    <span className="italic-class" data-scroll data-scroll-speed="1" data-scroll-position="top" data-scroll-repeat>Sustainability</span>
                                </span>
                            </h1>
                        </div>
                    </div>
                </div>
                <section data-scroll-section className={classNames(styles.SustainabilityMobileAlignment, "long-container")}>
                    <div className={styles.SustainabilityPageBanner}>
                        <img src={SustainableImage} alt="SustainableImage" className="flipImage" data-scroll data-scroll-repeat />
                    </div>
                </section>
                <>
                    <CurabiturSection />
                </>
                <>
                    <ChartView />
                </>
                <>
                    <CurabiturBanner />
                </>
                <>
                    <MaurisMaximus />
                </>
                <>
                    <UrbenRegeneration />
                </>
                <>
                    <Footer />
                </>
                <div className="footerBottomAlignment"></div>
            </div>
        </>
    )
}
