import React, { useEffect, useState, useRef } from "react";
import styles from './TechnologyImprove.module.scss';
import ManImage from '../../../Assets/Images/man-image.png';
import useOnScreen from "../../../hooks/useOnScreen";
import classNames from "classnames";
export default function TechnologyImprove() {
    const ref = useRef(null);

    const [reveal, setReveal] = useState(false);
    const onScreen = useOnScreen(ref);

    useEffect(() => {
        if (onScreen) setReveal(onScreen);
    }, [onScreen]);
    return (
        <div data-scroll-section>
            <section className={styles.TechnologyImproveAlignment}>
                <div className='container'>
                    <div className={styles.grid}>

                        <div className={styles.gridItems}>
                            <h1
                                className="c-header_title o-h1"
                                data-scroll data-scroll-speed="0.5" data-scroll-repeat
                            >
                                <span
                                    className={classNames("c-header_title_line", {
                                        "is-reveal": reveal,
                                    })}
                                >
                                    <span
                                        data-scroll
                                        data-scroll-speed="1"
                                        data-scroll-repeat
                                        data-scroll-position="top"

                                    >
                                        Technology as a way to{" "}
                                    </span>
                                    <span className='italic-class' data-scroll data-scroll-speed="1" data-scroll-position="top" data-scroll-repeat>improve the journey </span>
                                    <span data-scroll data-scroll-speed="1" data-scroll-position="top" data-scroll-repeat>for both tenants and landlords</span>
                                </span>
                            </h1>

                            <p data-scroll data-scroll-speed="1">
                                LRC estimates that its innovation contributes a susbstantial uplift to gross rental income. The Vonder app allows tenants to enjoy value-added services such as booking amenities within their properties, reserving spots in community events, and communicating directly with staff regarding any issues. More features include opening doors with smartlocks and a digital concierge. Additionally, the app allows users to book apartments with a fully digital on-boarding process.
                            </p>
                        </div>
                        <div className={styles.gridItems}>
                            <img src={ManImage} alt="ManImage" className="flipImage" data-scroll data-scroll-repeat />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
