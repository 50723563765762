import React, { useEffect, useState, useRef } from "react";
import cn from "classnames";
import styles from './Wellbeing.module.scss';
import BlackHeader from '../Layout/BlackHeader/BlackHeader';
import WellbeingBanner from '../../Assets/Images/wellbeing-banner.png';
import WellbeingBannerMobile from '../../Assets/Images/wellbeing-banner-mobile.png';
import SpaceEnvironments from './SpaceEnvironments/SpaceEnvironments';
import WellbeingEcosystem from './WellbeingEcosystem/WellbeingEcosystem';
import EnhancingTenansts from './EnhancingTenansts/EnhancingTenansts';
import UrbenRegeneration from '../CommunityPage/UrbenRegeneration/UrbenRegeneration';
import Footer from '../Layout/Footer/Footer';
import useLocoScroll from '../../hooks/useLocoScroll';
import useOnScreen from "../../hooks/useOnScreen";
import MetaDecorator from "../Util/MetaDecorator";
export default function Wellbeing() {
    useLocoScroll(true)
    const ref = useRef(null);

    const [reveal, setReveal] = useState(false);
    const onScreen = useOnScreen(ref);

    useEffect(() => {
        if (onScreen) setReveal(onScreen);
    }, [onScreen]);
    return (
        <>
            <div className="sticky-header">
                <BlackHeader />
            </div>
            <div id="main-container" data-scroll-container>
                <MetaDecorator title={"LRC Group | Wellbeing"} description={"Tenant wellbeing is a priority within LRC projects. Offering spaces which support personal growth and development has been key to the company’s strategy. Integrating wellbeing platforms and technologies within the Group’s properties offer a glimpse into the power of property innovation."} />
                <section className={styles.WellbeingAlignment} data-scroll-section>
                    <div className='long-container'>
                        <h1 className="c-header_title o-h1" data-scroll data-scroll-speed="3" data-scroll-position="top" data-scroll-repeat>
                            <span className={cn("c-header_title_line", { "is-reveal": reveal })}>
                                <span data-scroll data-scroll-speed="3" data-scroll-position="top" data-scroll-repeat>Transforming Real Estate through </span>
                                <span className="italic-class" data-scroll data-scroll-speed="3" data-scroll-position="top" data-scroll-repeat>Wellbeing </span>

                            </span>
                        </h1>
                    </div>
                </section>
                <section className={styles.WellbeingBanner} data-scroll-section>
                    <div className='long-container'>
                        <div className={styles.bannerDesign}>
                            {/* <img src={WellbeingBanner} alt="WellbeingBanner" className="flipImage" data-scroll data-scroll-repeat /> */}
                            <img className={cn(styles.communityBannerMobileHidden, "flipImage")} src={WellbeingBanner} alt="WellbeingBanner" data-scroll data-scroll-repeat />
                            <img className={cn(styles.communityBannerMobileShow, "flipImage")} src={WellbeingBannerMobile} alt="WellbeingBannerMobile" data-scroll data-scroll-repeat />
                        </div>
                    </div>
                </section>
                <>
                    <SpaceEnvironments />
                </>
                <>
                    <WellbeingEcosystem />
                </>
                <>
                    <EnhancingTenansts />
                </>
                <>
                    <UrbenRegeneration />
                </>
                <>
                    <Footer />
                </>
                <div className="footerBottomAlignment"></div>
            </div>
        </>
    )
}
