import React from 'react';
import styles from './VideoPlayer.module.scss';
import VideoImage from '../../../Assets/Images/video-image.png';
import PlayIcon from '../../../Assets/Images/player-icon.svg';
import NewVideo from '../../../Assets/Video/video1.mp4';
export default function VideoPlayer() {
  return (
    <div data-scroll-section>
      <section className={styles.videoPlayerAlignment}>
        <img src={VideoImage} alt="video-image" />
        {/* <video loop autoPlay muted src={NewVideo} >
        </video> */}
      </section >
    </div >
  );
}
