import React from 'react'
import styles from './Cookies.module.scss';
import { useCookie } from "../../hooks/useCookie.js";
export default function Cookies() {
    const [cookie, setCookie] = useCookie("allow-cookie", {
        expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
    });

    if (cookie) return null;
    return (
        <div>
            <div className={styles.CookiesAlignment}>
                <div className={styles.cookiesBox}>
                    <div className={styles.cookieGrid}>
                        <div className={styles.cookieGridItems}>
                            <p>We use cookies to improve user experience and analyse website traffic. By clicking <span>“Accept“</span>, you agree to our website's cookie use as described in our <span style={{ textDecoration: "underline" }}><a href="/disclaimer-policy" target="_blank" rel="noopener noreferrer">Privacy & Cookie Policy.</a></span></p>
                        </div>
                        <div className={styles.cookieGridItems}>
                            <button onClick={() => setCookie("true")}>Accept</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
