import React, { useEffect, useState, useRef } from "react";
import cn from "classnames";
import Header from '../../Layout/Header';
import useOnScreen from "../../../hooks/useOnScreen";
// import RightArrow from '../../../Assets/Images/right-arrow.svg';
import { NavLink } from "react-router-dom";
import VideoPlay from '../../../Assets/Video/video2.mp4';
// import MobileVideo from '../../../Assets/Video/mobile-video.mp4';
import HeroMobileImage from '../../../Assets/Images/new-hero-banner.jpg';
import styles from './HeroVideo.module.scss';
export default function HeroVideo() {
    const ref = useRef(null);

    const [reveal, setReveal] = useState(false);
    const onScreen = useOnScreen(ref);

    useEffect(() => {
        if (onScreen) setReveal(onScreen);
    }, [onScreen]);
    return (
        <div data-scroll-section style={{ position: "relative" }}>
            <section>
            </section>
            {/* <video className={styles.mobileViewVideoNone} loop autoPlay muted src={VideoPlay} playsinline disablePictureInPicture="true">
            </video> */}
            <video className={styles.mobileViewVideoNone} loop autoPlay muted playsInline>
                <source src={VideoPlay} type="video/mp4" />
            </video>
            {/* <video className={styles.mobileViewVideoShow} loop autoPlay muted playsinline>
                <source src={MobileVideo} type="video/mp4" />
            </video> */}
            {/* <video className={styles.mobileViewVideoShow} loop autoPlay muted src={MobileVideo} playsinline disablePictureInPicture="true">
            </video> */}
            <div className={styles.heroMobileImage}>
                <img src={HeroMobileImage} alt="HeroMobileImage" />
            </div>

            <div className={styles.videoBlur}></div>
            <div>
                <div className={styles.headerTopAlignPage}>
                    <Header />
                </div>
                <div className={styles.heroTextVideoAlignment}>
                    <div className="long-container">
                        <div className={styles.heroTextStyleAlignment}>
                            <div className=''>
                                <div className={styles.grid}>
                                    <div className={styles.gridItems}>
                                        <h1 className="c-header_title o-h1" data-scroll data-scroll-speed="3" data-scroll-position="top" data-scroll-repeat>
                                            <span className={cn("c-header_title_line", { "is-reveal": reveal })}>
                                                Building the<br />Real Estate<br /><span className="italic-class">Ecosystem</span>
                                            </span>



                                        </h1>
                                    </div>
                                    <div className={styles.gridItems}>
                                        <div className={styles.subGrid}>
                                            <div className={styles.subGridItems}>
                                                <p data-scroll data-scroll-delay="0.1" data-scroll-speed="1.6">
                                                    Experienced in generating attractive returns through optimised performance and dedication to social, economic, and environmental sustainability.
                                                </p>
                                                <div className={styles.buttonAlignment} data-scroll data-scroll-delay="0.1" data-scroll-speed="1.6">
                                                    <NavLink to="/expertise">
                                                        <button className={styles.whiteButtonDesign}>
                                                            <span>Our Expertise</span>
                                                            <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M7.244 0.410093C7.56943 0.0846564 8.09707 0.0846564 8.42251 0.410093L13.4225 5.41009C13.5788 5.56637 13.6666 5.77834 13.6666 5.99935C13.6666 6.22036 13.5788 6.43232 13.4225 6.58861L8.42251 11.5886C8.09707 11.914 7.56943 11.914 7.244 11.5886C6.91856 11.2632 6.91856 10.7355 7.244 10.4101L10.8214 6.83268L1.16659 6.83268C0.706348 6.83268 0.333252 6.45959 0.333252 5.99935C0.333252 5.53911 0.706348 5.16602 1.16659 5.16602L10.8214 5.16602L7.244 1.5886C6.91856 1.26317 6.91856 0.73553 7.244 0.410093Z" fill="#333B49" />
                                                            </svg>
                                                        </button>
                                                    </NavLink>
                                                    <NavLink to="/urbanregeneration">
                                                        <button className={styles.heroButtonOutline}>
                                                            <span>Case Studies</span>
                                                            <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M7.24473 0.412046C7.57016 0.0866095 8.0978 0.0866095 8.42324 0.412046L13.4232 5.41205C13.5795 5.56833 13.6673 5.78029 13.6673 6.0013C13.6673 6.22232 13.5795 6.43428 13.4232 6.59056L8.42324 11.5906C8.0978 11.916 7.57016 11.916 7.24473 11.5906C6.91929 11.2651 6.91929 10.7375 7.24473 10.412L10.8221 6.83464L1.16732 6.83464C0.70708 6.83464 0.333984 6.46154 0.333984 6.0013C0.333984 5.54107 0.70708 5.16797 1.16732 5.16797L10.8221 5.16797L7.24473 1.59056C6.91929 1.26512 6.91929 0.737483 7.24473 0.412046Z" fill="white" />
                                                            </svg>
                                                        </button>
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
