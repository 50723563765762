import React, { useEffect, useState, useRef } from "react";
import classNames from "classnames";
import useOnScreen from "../../../hooks/useOnScreen";
import styles from './OurHistory.module.scss';
import OurHistoryImage from '../../../Assets/Images/our-history.png';
export default function OurHistory() {
  const ref = useRef(null);

  const [reveal, setReveal] = useState(false);
  const onScreen = useOnScreen(ref);

  useEffect(() => {
    if (onScreen) setReveal(onScreen);
  }, [onScreen]);
  return (
    <div data-scroll-section>
      <section className={styles.OurHistoryAlignment}>
        <div className='container'>
          <div className={styles.grid}>
            <div className={styles.gridItems}>
              <img src={OurHistoryImage} alt="OurHistoryImage" className="flipImage" data-scroll data-scroll-repeat />
            </div>
            <div className={styles.gridItems}>
              <div>
                <a>LRC’s history</a>
              </div>
              <h1
                className="c-header_title o-h1"
                data-scroll data-scroll-speed="0.5" data-scroll-repeat
              >
                <span
                  className={classNames("c-header_title_line", {
                    "is-reveal": reveal,
                  })}
                >Over 25 years of{" "}<span className='italic-class' >expertise</span>
                </span>
              </h1>
              <div data-scroll data-scroll-speed="1">
                <p>
                  The LRC Group was founded in 1995 in London and has expanded throughout the years. The group currently operates from 8 international
                  offices in <span className="italic-class">London, Berlin, Dubai, Dublin, Warsaw, Luxembourg, Malta and Cyprus.</span>
                </p>
                <p>
                  With €6bn of assets under management, over 10,000 residential units worldwide and 500,000 sqm of commercial space, the Group keeps increasing its
                  global portfolio while maintaining a 78% tenant retention rate which demonstrates a high level of customer satisfaction.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
