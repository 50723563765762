import React, { useEffect, useState, useRef } from "react";
import styles from './AboutGroup.module.scss';
import RightArrow from '../../../Assets/Images/outline-right.svg';
import AtosImage from '../../../Assets/Images/atos.jpg';
import classNames from 'classnames';
import useOnScreen from "../../../hooks/useOnScreen";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
export default function AboutGroup() {
  const ref = useRef(null);

  const [reveal, setReveal] = useState(false);
  const onScreen = useOnScreen(ref);

  useEffect(() => {
    if (onScreen) setReveal(onScreen);
  }, [onScreen]);
  return (
    <div data-scroll-section>
      <div>
        <section className={styles.aboutGroupAlignment} >
          <div className='container'>
            <div className={styles.whoWeAreGrid}>
              <div className={styles.whoWeAreGridItems}>
                <div data-scroll data-scroll-speed="1.2">
                  <a>who we are</a>
                </div>
                {/* <h1><span className="italic-class">About</span>
                <br /> THE LRC GROUP</h1> */}
                <h1
                  className="c-header_title o-h1"
                  data-scroll data-scroll-speed="1" data-scroll-repeat
                >
                  <span
                    className={classNames("c-header_title_line", {
                      "is-reveal": reveal,
                    })}
                  >
                    <span
                      data-scroll
                      data-scroll-speed="1"
                      data-scroll-repeat
                      data-scroll-position="top"
                      className="italic-class"
                    >
                      About<br />
                    </span>
                    <span data-scroll data-scroll-speed="1" data-scroll-position="top" data-scroll-repeat>THE LRC GROUP</span>
                  </span>
                </h1>
                <div data-scroll data-scroll-speed="1">
                  <p >
                    The LRC Group is a privately held real estate investment and fund management firm. LRC specialises in structuring and executing value-add impact investments. The group enhances efficiencies through experienced asset management teams.
                  </p>
                  <NavLink to="/aboutus">
                    <button>
                      Learn More
                      <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.24473 0.412046C7.57016 0.0866095 8.0978 0.0866095 8.42324 0.412046L13.4232 5.41205C13.5795 5.56833 13.6673 5.78029 13.6673 6.0013C13.6673 6.22232 13.5795 6.43428 13.4232 6.59056L8.42324 11.5906C8.0978 11.916 7.57016 11.916 7.24473 11.5906C6.91929 11.2651 6.91929 10.7375 7.24473 10.412L10.8221 6.83464L1.16732 6.83464C0.70708 6.83464 0.333984 6.46154 0.333984 6.0013C0.333984 5.54107 0.70708 5.16797 1.16732 5.16797L10.8221 5.16797L7.24473 1.59056C6.91929 1.26512 6.91929 0.737483 7.24473 0.412046Z" fill="white" />
                      </svg>
                    </button>
                  </NavLink>
                </div>
              </div>
              <div className={styles.whoWeAreGridItems}>
                <div className={styles.textBottomAlignment}>

                  <h3
                    className="c-header_title o-h1"
                    data-scroll data-scroll-speed="0.5" data-scroll-repeat
                  >
                    <span
                      className={classNames("c-header_title_line", {
                        "is-reveal": reveal,
                      })}
                    >
                      <span
                        data-scroll
                        data-scroll-speed="1"
                        data-scroll-repeat
                        data-scroll-position="top"
                        className="italic-class"
                      >
                        €6bn AUM
                      </span>
                    </span>
                  </h3>
                  <h6>Across Europe, the US and the Middle East</h6>
                </div>
                <div>

                  <h3
                    className="c-header_title o-h1"
                    data-scroll data-scroll-speed="0.5" data-scroll-repeat
                  >
                    <span
                      className={classNames("c-header_title_line", {
                        "is-reveal": reveal,
                      })}
                    >
                      <span
                        data-scroll
                        data-scroll-speed="1"
                        data-scroll-repeat
                        data-scroll-position="top"
                        className="italic-class"
                      >
                        10,000+
                      </span>
                    </span>
                  </h3>
                  <h6 >Residential Units</h6>
                </div>
              </div>
            </div>
            {/* <div className={styles.aboutGrid}>
                      <div className={styles.aboutGridItems} data-scroll data-scroll-direction="horizontal" data-scroll-speed="-1.5" data-scroll-position="top">
                          <a>who we are</a>
                      </div>
                      <div className={styles.aboutGridItems}>

                          <h1
                    className="c-header_title o-h1"
                    data-scroll data-scroll-speed="0.5" data-scroll-repeat
                  >
                    <span
                      className={classNames("c-header_title_line", {
                        "is-reveal": reveal,
                      })}
                    >
                      <span
                        data-scroll
                        data-scroll-speed="1"
                        data-scroll-repeat
                        data-scroll-position="top"
                        className="italic-class"
                      >
                        About{" "}<br/>
                      </span>
                      <span  data-scroll data-scroll-speed="1" data-scroll-position="top" data-scroll-repeat>LRC GROUP</span>
                    </span>
                    </h1>
                          <p data-scroll data-scroll-speed="1">
                            The LRC Group is a privately held investment and management firm. LRC specialises in structuring and executing value-add impact investments, and
                            enhancing efficiency through an experienced in-house asset management team.
                          </p>

                      </div>
                  </div> */}

          </div>
        </section>
        <section className={styles.groupNumberAlignment} >
          <div className={classNames(styles.mobileViewgroupNumberAlignment, 'container')}>
            <div className={styles.grid}>
              <div className={styles.gridItems}>
                <h1
                  className="c-header_title o-h1"
                  data-scroll data-scroll-speed="0.5" data-scroll-repeat
                >
                  <span
                    className={classNames("c-header_title_line", {
                      "is-reveal": reveal,
                    })}
                  >
                    <span
                      data-scroll
                      data-scroll-speed="1"
                      data-scroll-repeat
                      data-scroll-position="top"
                    >
                      The Group{" "}
                    </span>
                    <span className="italic-class" data-scroll data-scroll-speed="1" data-scroll-position="top" data-scroll-repeat>in Numbers</span>
                  </span>
                </h1>
                <div className={styles.listAlignment} data-scroll data-scroll-position="top" data-scroll-repeat>
                  <ul data-scroll data-scroll-speed="1">
                    <li ><span>1995</span> Founded</li>
                    <li><span>8</span> International Offices</li>
                    <li><span>500k sqm</span> Commercial Space</li>
                    <li><span>78%</span> Tenant Retention Rate</li>
                  </ul>
                </div>
              </div>
              <div className={styles.gridItems}>
                <div className={styles.cardImage}>
                  <img src={AtosImage} alt="AtosImage" className="flipImage" data-scroll data-scroll-repeat />
                </div>
                <div className={styles.cardDetails}>
                  <p data-scroll data-scroll-speed="1">Vonder: LRC's tech-enabled lifestyle platform for extended stays.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
