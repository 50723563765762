import React, { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import useOnScreen from '../../../hooks/useOnScreen';
import styles from './OurTeam.module.scss';
import OurTeamImage from '../../../Assets/Images/our-team.png';
export default function OurTeam() {
	const ref = useRef(null);

	const [reveal, setReveal] = useState(false);
	const onScreen = useOnScreen(ref);

	useEffect(() => {
		if (onScreen) setReveal(onScreen);
	}, [onScreen]);
	return (
		<div data-scroll-section>
			<section className={styles.ourTeamBanner}>
				<div className='container'>
					<div className={styles.grid}>
						<div className={styles.gridItems}>
							<a data-scroll data-scroll-speed='0.5'>
								LRC’s Management team
							</a>

							<h1
								className='c-header_title o-h1'
								data-scroll
								data-scroll-speed='0.5'
								data-scroll-repeat
							>
								<span
									className={classNames('c-header_title_line', {
										'is-reveal': reveal,
									})}
								>
									Experienced &{' '}
									<span className='italic-class'>
										dynamic in-house management team
									</span>
								</span>
							</h1>
						</div>
						<div className={styles.gridItems}>
							<div data-scroll data-scroll-speed='1'>
								<p>
									The LRC Group is led by a results-oriented management team
									with a wealth of industry knowledge. LRC’s principals have
									been active in the European real estate markets for numerous
									decades and bring a combined experience of over 125 years in
									the international property and finance markets to the table.
								</p>
								<p>
									Its dynamic leadership allows the company to successfully
									navigate the real estate sector and market trends with a
									strong focus on innovation. The team is continually
									introducing new technologies to develop sustainable business
									methods.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}
