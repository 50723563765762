import React, { useEffect, useState, useRef } from "react";
import cn from "classnames";
import styles from '../home/home.module.scss';
import RightArrow from '../../Assets/Images/right-arrow.svg';
import OutlineArrow from '../../Assets/Images/outline-right.svg';
import Header from '../Layout/Header';
import MissionAndVision from './MissionAndVision/MissionAndVision';
import AboutGroup from './AboutGroup/AboutGroup';
import Pioneering from './Pioneering/Pioneering';
import VideoPlayer from './VideoPlayer/VideoPlayer';
import HospitalityExperience from './HospitalityExperience/HospitalityExperience';
import FamilyOfFunds from './FamilyOfFunds/FamilyOfFunds';
import LrcGroups from './LrcGroups/LrcGroups';
import Research from './Research/Research';
import EnquiriesForm from './EnquiriesForm/EnquiriesForm';
import Footer from '../Layout/Footer/Footer';
import useLocoScroll from '../../hooks/useLocoScroll';
import useOnScreen from "../../hooks/useOnScreen";
import HeroVideo from "./HeroVideo/HeroVideo";
import MetaDecorator from "../Util/MetaDecorator.jsx";
export default function Home() {
    useLocoScroll(true)
    const ref = useRef(null);

    const [reveal, setReveal] = useState(false);
    const onScreen = useOnScreen(ref);

    useEffect(() => {
        if (onScreen) setReveal(onScreen);
    }, [onScreen]);
    return (
        <>

            <div id="main-container" data-scroll-container>
                <MetaDecorator title={"LRC Group | Building the Real Estate Ecosystem"} description={"Experienced in generating attractive returns through optimised performance and dedication to social, economic, and environmental sustainability."} />
                <>
                    <HeroVideo />
                </>
                <>
                    <MissionAndVision />
                </>
                <>
                    <AboutGroup />
                </>
                <>
                    <Pioneering />
                </>
                <>
                    <VideoPlayer />
                </>
                <>
                    <HospitalityExperience />
                </>
                <>
                    <FamilyOfFunds />
                </>
                <>
                    <LrcGroups />
                </>

                <>
                    <EnquiriesForm />
                </>
                <>
                    <Footer />
                </>
            </div >
        </>
    )
}
