import React from "react";
import BlackHeader from '../Layout/BlackHeader/BlackHeader';
import styles from './Sfdr.module.scss';
import Footer from '../Layout/Footer/Footer';

import {NavLink} from "react-router-dom";

export default function Sfdr() {

    return (
        <>
            <div className="sticky-header">
                <BlackHeader/>
            </div>
            <div id="main-container">
                <section className={styles.DisclaimerAlignment}>
                    <div className='container'>
                        <div className={styles.boxCenterAlignment}>
                            <div className={styles.boxWidth}>
                                <div className={styles.pageTitle}>
                                    <h1>SFDR Disclosure</h1>
                                </div>
                                <div className={styles.subTextAlignment}>
                                    <h2>Approach to the integration of sustainability risks &ndash; Article 3
                                        Disclosure</h2>
                                    <p>This disclosure sets out information about the LRC Group and its AIFM&rsquo;s
                                        approach to the integration of &ldquo;Sustainability Risks&quot; as defined and
                                        required by the SFDR (that is an environmental, social, or governance event or
                                        condition that, if it occurs, could have a material negative impact on the value
                                        of
                                        the investment).</p>
                                    <p>LRC integrates sustainability risks into investment decisions. As LRC recognises
                                        that
                                        sustainability risks can have a material effect on investment performance, and
                                        consequently, the integration of sustainability risk assessments is fundamental
                                        to
                                        our investment decision-making and management process.</p>
                                    <p>LRC has identified sustainability risks in relation to the investments and
                                        reviews
                                        them periodically to ensure they are up to date. Those risks are principally in
                                        relation to transition and physical risks. The risks that are particular to LRC
                                        real
                                        estate investments are transition and physical risks;
                                    </p>
                                    <p>
                                        <strong><em>Transition Risks</em></strong><em>; Transitioning to a lower-carbon
                                        economy may entail extensive policy, legal, technology, and market changes to
                                        address mitigation and adaptation requirements related to climate change.
                                        Depending
                                        on the nature, speed, and focus of these changes, transition risks may pose
                                        varying
                                        levels of financial and reputational risk to organizations such as LRC.</em>
                                    </p>
                                    <p><strong><em>Physical Risks</em></strong><em>; Physical risks resulting from
                                        climate
                                        change can be event driven (acute) or longer-term shifts (chronic) in climate
                                        patterns. Physical risks may have financial implications for organizations, such
                                        as
                                        direct damage to assets and indirect impacts from supply chain disruption.
                                        Organizations&rsquo; financial performance may also be affected by changes in
                                        water
                                        availability, sourcing, and quality; food security; and extreme temperature
                                        changes
                                        affecting organizations&rsquo; premises, operations, supply chain, transport
                                        needs,
                                        and employee safety.</em>
                                    </p>
                                    <p>LRC has committed to incorporating significant sustainability risks into the
                                        stages
                                        of its investment cycle. Before making any investment decisions, a thorough
                                        sustainability due diligence process is conducted for potential real estate
                                        assets.
                                        This includes assessing sustainability risks. The findings from the due
                                        diligence
                                        are included in the investment proposal and Investment Committee papers,
                                        ensuring
                                        that sustainability risks are minimized and factored into the investment
                                        strategy
                                        when they have the potential to impact long-term risk-adjusted returns.&nbsp;
                                    </p>
                                    <p>We are also in the process of updating our Risk Policy to ensure the
                                        incorporation
                                        and governance of sustainability risk throughout the investment lifecycle. Once
                                        finalized, this policy will provide clear guidance on the identification,
                                        evaluation, and management of sustainability risks.
                                    </p>
                                </div>
                                <div className={styles.subTextAlignment}>

                                    <h2>Sustainability Risk Remuneration Policy &ndash; Article 5 Disclosure
                                    </h2>
                                    <p>LRC has implemented a remuneration policy that incorporates sustainability risk
                                        in
                                        investments. Remuneration is aligned with risk management and staff following
                                        policies, processes, and procedures set out by LRC, which includes
                                        sustainability
                                        risk.&nbsp;
                                    </p>
                                </div>
                                <div className={styles.subTextAlignment}>

                                    <h2>Statement on principal adverse impacts of investment decisions on sustainability
                                        factors &ndash; Article 4 Disclosure
                                    </h2>
                                    <p>Article 4 of the SFDR provides a framework to facilitate transparency in relation
                                        to
                                        the adverse impacts of investment decisions on sustainability factors. This
                                        Article
                                        contains the requirements for financial market participants disclose on their
                                        site
                                        they consider principal adverse impacts of investment decisions on
                                        sustainability
                                        factors. In that context, LRC considers the principal adverse impacts of its
                                        investment decisions on sustainability factors. Starting in 2024, by June 30th
                                        annually, LRC will publish a report on these adverse impacts regarding
                                        sustainability factors, following the specified template in Annex I of the
                                        Delegated
                                        Regulation (EU) that complements the SFDR with regulatory technical standards
                                        (RTS).
                                    </p>

                                </div>

                            </div>

                        </div>
                    </div>
                </section>
                <>
                    <Footer/>
                </>
            </div>
        </>
    )
}
