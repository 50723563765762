import React, { useEffect, useState, useRef } from 'react';
import './Carousel.css';
import Slider from 'react-slick';
import SliderLeftRightArrow from '../../../Assets/Images/slider-next.svg';
import SliderLeftLeftArrow from '../../../Assets/Images/slider-right.svg';
import styles from './FounderSection.module.scss';
import YehudaBarashiImage from '../../../Assets/team/YehudaBarashi.webp';
import NadavZoharImage from '../../../Assets/team/NadavZohar.webp';
import ChrisDimitriadisImage from '../../../Assets/team/ChrisDimitriadis.webp';
import MattGrefsheimImage from '../../../Assets/team/MattGrefsheim.webp';
import ErezBonielImage from '../../../Assets/team/ErezBoniel.webp';
import DenisLavrutImage from '../../../Assets/team/DenisLavrut.webp';
import LironHadadImage from '../../../Assets/team/LironHadad.webp';
import AhmedTayaneImage from '../../../Assets/team/AhmedTayane.webp';
import DaryaLevinImage from '../../../Assets/team/DaryaLevin.webp';
import DavidLandwehr from '../../../Assets/team/DavidLandwehr.webp';
import JaniceFenechImage from '../../../Assets/team/JaniceFenech.webp';
import JudithMuellerImage from '../../../Assets/team/JudithMueller.webp';
import KennethCamilleriImage from '../../../Assets/team/KennethCamilleri.webp';
import SoullaFotiadouImage from '../../../Assets/team/SoullaFotiadou.webp';
import JustineOMahoneyImage from '../../../Assets/team/JustineOMahoney.webp';

function SampleNextArrow(props) {
	const { onClick } = props;
	return (
		<div className='slider-arrow arrow-right' onClick={onClick}>
			<img src={SliderLeftLeftArrow} alt='LeftArrow' />
		</div>
	);
}

function SamplePrevArrow(props) {
	const { onClick } = props;
	return (
		<div className='slider-arrow arrow-left' onClick={onClick}>
			<img src={SliderLeftRightArrow} alt='RightArrow' />
		</div>
	);
}

export default function FounderSection() {
	const [show, setShow] = useState(false);
	const [nav1, setNav1] = useState(null);
	const [nav2, setNav2] = useState(null);
	const slider1 = useRef(null);
	const slider2 = useRef(null);
	useEffect(() => {
		setNav1(slider1.current);
		setNav2(slider2.current);
	}, []);

	const slickSettingsVerticalNav = {
		arrows: true,
		vertical: false,
		slidesToShow: 1,
		swipeToSlide: true,
		speed: 400,
		focusOnSelect: true,
		asNavFor: nav2,
		ref: slider1,
		nextArrow: <SampleNextArrow />,
		prevArrow: <SamplePrevArrow />,
	};

	const slickSettingsVerticalMain = {
		arrows: false,
		slidesToShow: 2,
		speed: 400,
		asNavFor: nav1,
		ref: slider2,
		responsive: [
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					initialSlide: 1,
				},
			},
		],
	};

	return (
		<div>
			<section className={styles.FounderSectionAlignment} data-scroll-section>
				<div className='container slider-container'>
					<div className='slider-left'>
						<Slider {...slickSettingsVerticalNav}>
							{TEAM_MEMBERS.map((text, i) => (
								<div className='slide-nav' key={i}>
									<p className='team-member-position'>{text.position}</p>
									<p className='team-member-name'>{text.name}</p>
									<p
										className={
											show
												? 'team-member-about-show'
												: 'team-member-about-hidden'
										}
									>
										{text.about}
									</p>
									<span className='show-more' onClick={() => setShow(!show)}>
										{!show ? 'Show More' : 'Show Less'}
									</span>
								</div>
							))}
						</Slider>
					</div>

					<div className='slider-right'>
						<Slider {...slickSettingsVerticalMain}>
							{TEAM_MEMBERS.map((item, i) => (
								<div className='slide-main' key={i}>
									<img src={item.img} alt='' />
								</div>
							))}
						</Slider>
					</div>
				</div>
			</section>
		</div>
	);
}

const TEAM_MEMBERS = [
	{
		img: YehudaBarashiImage,
		position: 'Founder',
		name: 'Yehuda Barashi',
		about:
			'Yehuda is the founder of the LRC Group. He oversees the business activities of the group focusing on new business development and investor relations. He specialises in structuring acquisitions and disposals of commercial and residential real estate while also leading strategic negotiations with key tenants across the portfolios. Yehuda has over 28 years of experience in European real estate across a wide variety of disciplines.',
	},
	{
		img: NadavZoharImage,
		position: 'Chairman',
		name: 'Nadav Zohar',
		about:
			'Nadav has 20 years of experience in Real Estate & investment banking, M&A and advisory work. He was Morgan Stanley’s head of the Israel investment banking office and served as the European Banking operations officer in London. Nadav was also the COO of Delek Global Real Estate plc. He served as the Chairman of Soluto Ltd. and continues to serve as a board member on a number of companies’ boards, public and private.  Nadav holds an MSc in Finance from the London Business School and an LLB in Law.',
	},
	{
		img: ChrisDimitriadisImage,
		position: 'Chief Executive Officer',
		name: 'Chris Dimitriadis',
		about:
			'Chris has nearly 25 years of experience in European real estate with a strong focus on acquisitions and asset management. He joined LRC in 2006 and had a significant role in setting up the operations in Germany before returning to the UK in 2009. He has been instrumental in the firm’s acquisition of dozens of successful projects across the UK. Chris’s experience includes the management of more than €1bn in hotel properties across the European continent and the UK and the restructuring of Amaris and a £750million portfolio of hotels in Ireland and the UK. Chris holds an MA in Strategic Financial Management and a BSc in International Business Management.',
	},
	{
		img: MattGrefsheimImage,
		position: 'Chief Operating Officer',
		name: 'Matt Grefsheim',
		about:
			'Matt has more than 34 years of experience in loan management/restructuring, real estate asset management and development as well as people and process management. Over that time Matt has actively managed all types of properties including more than €3bn in hotel portfolios in the US and Europe. Prior to joining LRC was the Director of European loan asset management for at Hatfield Philips for 8 years leading a team of up to 25 persons underwriting and restructuring more than €30bn of secured loans in all sectors across the UK and the European continent. Before Hatfield Philips, Matt was an Executive Director at Morgan Stanley UK for 4 years and an asset manager, developer and investor in the US since 1989.',
	},
	{
		img: DaryaLevinImage,
		position: 'Principal',
		name: 'Darya Levin',
		about:
			"Darya has over 17 years of experience in Real Estate & financial advisory. In her prior role as global head of real estate acquisitions at a major institution with over $100 billion AUM, she supervised a team of 6 dedicated professionals, leading the sourcing, underwriting, and execution of real estate transactions. During her 7-year tenure, she successfully executed real estate transactions totalling over $3 billion across various sectors and capital structures. She played a pivotal role in expanding the institution's subordinated debt platform and establishing strategic partnerships and joint ventures. Prior to that, she held positions in Buy-Side equity research within leading financial groups and gained expertise in transaction advisory at one of the BIG 4 firms. Darya holds an MSc in Finance and a BSc in Economics & Accounting",
	},
	{
		img: DavidLandwehr,
		position: 'Executive Director',
		name: 'David Landwehr',
		about:
			"David brings over 17 years of diverse real estate experience, with a track record servicing real estate of all asset classes across Europe and overseeing transactions exceeding €6 billion. His expertise includes distressed/value-add and core/core+ investments. Initially joining LRC in 2006, he was pivotal in expanding LRC's German operations and entering new European markets during his 15-year tenure. Later, he led asset management for one of Switzerland’s largest multifamily developers overseeing a portfolio with a GDV of CHF2.5 billion. Returning to LRC in 2023, he oversees German operations, manages acquisitions across continental Europe, and drives business development. David holds an Executive MBA, a BA in Business Studies, and is an accredited professional member of the International Compliance Association (ICA), emphasizing his commitment to industry ethics and compliance.",
	},
	{
		img: JudithMuellerImage,
		position: 'Chief of Staff',
		name: 'Judith Mueller',
		about:
			'Judith has 15+ years of experience spanning a corporate finance/management consulting environment at Deloitte, Private Equity at a Starwood Capital subsidiary and several years of entrepreneurship. Having started her first business while studying at Oxford University, she has acquired experience in every stage of a company’s lifecycle in the process: from purchase and initial set-up to the turnaround and growth stages through to eventual exits by trade sales. Judith holds degrees from Oxford University and the London School of Economics.',
	},
	{
		img: JustineOMahoneyImage,
		position: 'Head of Portfolio Management',
		name: 'Justine O’Mahoney',
		about:
			'Justine has over 12 years of experience in property finance and portfolio management.  Prior to LRC, Justine worked for an alternative CRE lender responsible for underwriting and portfolio management.  She has held various other roles across the banking sector including managing a portfolio of NAMA loans.  Justine is an accountant and holds a Masters in Real Estate Finance and Investment and a Masters in International Business. ',
	},
	{
		img: LironHadadImage,
		position: 'Senior Finance Director',
		name: 'Liron Hadad',
		about:
			"Liron has over 12 years of experience in finance, auditing and accounting. Prior to joining LRC, Liron was an auditor at Ernst and Young, with experience in public real estate companies traded on the TASE and NYSE. He has a lot of experience with real estate companies, IPO processes for equity raising and bond raising to the tune of several billion USD. Since 2018, Liron has been the Head of LRC's Hospitality Platform, Amaris. He manages LRC’s Real Estate Fund’s finance operation and investor Relations, leading acquisition processes of €3bn of residential, office and hospitality properties. Liron holds an MBA in Accountancy and Finance from The College of Management and a Real Estate Management Diploma from the Technion.",
	},
	{
		img: ErezBonielImage,
		position: 'Executive Director',
		name: 'Erez Boniel',
		about:
			"Prior to joining LRC, Erez was the Chief Financial Officer and an Executive member of the management board of GTC Group, a leading real estate investor and developer in CEE. Erez was in charge on GTC S.A.'s global capital markets activity, whereby he has led the company's numerous equity raising, bond raising, investment loans and acquisitions and disposals to the tune of several billion EUR. Erez has acted as a member of the supervisory board, Head of the Audit Committee and Member of the Investment Committee of Orbis S.A. (Accor Hotels Group) for several years.",
	},

	{
		img: AhmedTayaneImage,
		position: 'General Counsel',
		name: 'Ahmed Tayane',
		about:
			'Prior to joining LRC, Ahmed was a corporate and finance litigation lawyer specialised in insolvency and restructuring. He mainly represented financial sponsors, loan servicers, corporate borrowers and issuers and was recognised as a next generation lawyer by Legal500 EMEA. Being a Fulbright alumni and Michigan Grotius fellow, he was also active in the academic field at a Belgian University. Ahmed holds a Masters law degree from the University of Antwerp and an LL.M. from the University of Michigan Law School.',
	},
	{
		img: KennethCamilleriImage,
		position: 'Executive Director, Malta',
		name: 'Kenneth Camilleri',
		about:
			'Kenneth has over 20 years of experience in international taxation, investment migration and wealth structuring. Starting his career in tax with PricewaterhouseCoopers, Kenneth moved on to occupy senior positions of both the General Manager and CFO in the hospitality industry in Malta. Kenneth went on to join an international law firm as a Senior Director, for a span of fifteen years, whereby, he led the Tax Advisory and Tax Compliance units and was highly involved in corporate structuring. He has also lectured in both the Fundamentals and Advanced ACCA papers in taxation. Kenneth is a qualified accountant and tax advisor. He holds a Bachelors of Accountancy (Hons) degree. He is a professional member of the Malta Institute of Accountants, Malta Institute of Taxation, Malta Institute of Management and the Institute of Financial Services Practitioners.',
	},

	{
		img: JaniceFenechImage,
		position: 'Fund Management, Malta',
		name: 'Janice Fenech',
		about:
			'Janice has over ten years of experience in Corporate Advisory and Tax Compliance. She has worked with an international law firm whereby she was the youngest manager leading a team with a portfolio of over three hundred clients. Through her responsibilities, she has gained versatility in dealing with customers and a wealth of knowledge in managing regulated areas. Within LRC, Janice is primarily involved in handling the general operations of LRC Fund Management Limited which is regulated by the MFSA.',
	},
	{
		img: SoullaFotiadouImage,
		position: 'Director',
		name: 'Soulla Fotiadou',
		about:
			'Soulla has over 20 years of experience in accounting. Prior to LRC, Soulla worked as an in-house Accounts Manager for large companies in Cyprus, delegating with banks and governmental departments. Soulla joined LRC in 2015 and became director of the Cyprus office in 2017. She oversees all project phases of LRC’s Cyprus companies and provides assistance to other associates. Soulla holds a Business Administration Degree in Marketing Management and a Diploma in Accounting & Finance.',
	},
];
