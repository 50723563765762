import React from "react";
import styles from "./NewsDetailsText.module.scss";
export default function NewsDetailsText() {
  return (
    <div data-scroll-section>
      <section className={styles.NewsDetailsTextAlignment}>
        <div className="container">
          <div className={styles.boxCenterAlignment}>
            <div className={styles.boxWidth}>
              <div className={styles.pageTitle}>
                <h1>
                  by <span>LRC Group</span>
                </h1>
                <h6>02 December 2021</h6>
              </div>
              <div className={styles.childTextStyle}>
                <p>
                  Integer pretium, sem et aliquet fringilla, justo felis
                  pellentesque urna, non feugiat ligula nisl a dolor. Mauris
                  interdum sem ipsum, eu lobortis velit vestibulum eget. Nullam
                  pretium velit ac ornare efficitur. Donec nibh lorem, sagittis sed
                  lorem in, tristique scelerisque elit. Cras ullamcorper ullamcorper
                  risus, aliquam fringilla justo dictum eu. Cras gravida
                  sollicitudin turpis ac volutpat. Nulla nulla neque, posuere sit
                  amet ullamcorper id, pharetra ac urna. Integer sit amet vestibulum
                  mauris, sit amet consectetur augue.
                </p>
                <p>
                  Sed non tortor luctus lacus dictum cursus. Nunc auctor, ex at
                  semper euismod, orci orci pretium elit, vitae laoreet diam nulla
                  eget arcu. Nunc suscipit magna lorem, sed luctus leo dictum non.
                  Pellentesque nulla neque, venenatis ac imperdiet non, lacinia sit
                  amet ex. Suspendisse lacus augue, tristique non ex in, facilisis
                  convallis lorem. Quisque quam tellus, pulvinar ac sapien
                  venenatis, congue aliquet odio. Maecenas lacus leo, dignissim nec
                  molestie in, malesuada ac lacus. Pellentesque eu orci
                  sollicitudin, venenatis est id, faucibus dui.
                </p>
                <p>
                  Proin turpis felis, gravida ut nisi eu, euismod semper magna.
                  Maecenas aliquam efficitur nulla eget varius. Nulla ligula urna,
                  finibus pharetra porta eu, auctor non justo. Vivamus convallis,
                  libero sit amet commodo iaculis, magna nibh accumsan arcu, eu
                  tincidunt nunc metus non lacus. Nam suscipit lorem quis tellus
                  euismod egestas. Donec hendrerit, purus at egestas cursus, augue
                  lectus rhoncus tortor, ut pharetra massa turpis vitae risus. Ut
                  commodo diam eget tellus sagittis laoreet. Maecenas congue
                  bibendum mi ut semper.
                </p>
                <p>
                  Proin sagittis dignissim mattis. Pellentesque commodo justo id
                  nisi tincidunt posuere. Aenean at rutrum neque. Donec et tincidunt
                  enim, a pellentesque libero. Suspendisse sit amet nisl sapien.
                  Vestibulum eu eleifend libero, quis lobortis lectus. Lorem ipsum
                  dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar
                  tincidunt enim, sed bibendum odio pellentesque sit amet.
                  Pellentesque habitant morbi tristique senectus et netus et
                  malesuada fames ac turpis egestas.
                </p>
                <p>
                  Vestibulum faucibus in purus venenatis dignissim. Fusce accumsan
                  nisl sem, quis tristique nisi dapibus quis. Nulla eu nunc sed dui
                  blandit elementum at in lacus. Vivamus malesuada, neque nec
                  faucibus luctus, dui neque dictum quam, vel blandit urna nibh sit
                  amet tortor. Duis scelerisque velit non sollicitudin consequat.
                  Suspendisse sed risus elementum, tincidunt tortor in, tempor est.
                  Cras sodales elit vel dictum sollicitudin.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
