import React, { useEffect, useState, useRef } from "react";
import styles from './Pioneering.module.scss';
import RightArrow from '../../../Assets/Images/outline-right.svg'
import PioneeringImage from '../../../Assets/Images/pioneering.png'
import { NavLink } from "react-router-dom";
import WhatWeDo from '../../../Assets/Images/aerial-city.png'
import classNames from 'classnames';
import useOnScreen from "../../../hooks/useOnScreen";
export default function Pioneering() {
  const ref = useRef(null);

  const [reveal, setReveal] = useState(false);
  const onScreen = useOnScreen(ref);

  useEffect(() => {
    if (onScreen) setReveal(onScreen);
  }, [onScreen]);
  return (
    <div data-scroll-section>
      <section className={styles.pioneeringSectionAlignment}>
        <div className={classNames(styles.containerSpaceRemove, "container")}>
          <div className={styles.textGrid}>
            <div className={styles.textGridItems}>
              <div data-scroll data-scroll-speed="1">
                <a>what we do</a>
              </div>
              <h1
                className="c-header_title o-h1"
                data-scroll data-scroll-speed="0.5" data-scroll-repeat
              >
                <span
                  className={classNames("c-header_title_line", {
                    "is-reveal": reveal,
                  })}
                >

                  Pioneering{" "}

                  <span className="italic-class">Urban <br />Regeneration</span>
                </span>
              </h1>
              <p data-scroll data-scroll-speed="1">
                LRC’s focus is on real estate impact investments in areas of strong demand and low supply. We are ahead of the curve by transforming under-utilised assets as part of a conscientious urban renewal process.
              </p>
              <NavLink to="/urbanregeneration">
                <button>
                  <span>Learn More</span>
                  <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.24473 0.412046C7.57016 0.0866095 8.0978 0.0866095 8.42324 0.412046L13.4232 5.41205C13.5795 5.56833 13.6673 5.78029 13.6673 6.0013C13.6673 6.22232 13.5795 6.43428 13.4232 6.59056L8.42324 11.5906C8.0978 11.916 7.57016 11.916 7.24473 11.5906C6.91929 11.2651 6.91929 10.7375 7.24473 10.412L10.8221 6.83464L1.16732 6.83464C0.70708 6.83464 0.333984 6.46154 0.333984 6.0013C0.333984 5.54107 0.70708 5.16797 1.16732 5.16797L10.8221 5.16797L7.24473 1.59056C6.91929 1.26512 6.91929 0.737483 7.24473 0.412046Z" fill="white" />
                  </svg>
                </button>
              </NavLink>
            </div>
            <div className={styles.textGridItems}>
              <img src={PioneeringImage} alt="PioneeringImage" className="flipImage" data-scroll data-scroll-repeat />
            </div>
          </div>
          <div className={styles.urbanregenerationMobileImage}>
            <img src={PioneeringImage} alt="PioneeringImage" className="flipImage" data-scroll data-scroll-repeat />
          </div>

        </div>
        <div className={styles.withoutContainerAlignment}>
          <div className={styles.techImageGrid}>
            <div className={styles.techImageGridItems}>
              <img src={WhatWeDo} alt="WhatWeDo" className="flipImage" data-scroll data-scroll-repeat />
            </div>
            <div className={styles.techImageGridItems}>
              <div className={styles.newTextGrid}>
                <div className={styles.newTextGridItems}>
                  <h4
                    className="c-header_title o-h1"
                    data-scroll data-scroll-speed="1"
                  >
                    <span
                      className={classNames("c-header_title_line", {
                        "is-reveal": reveal,
                      })}
                    >
                      Technology

                    </span>
                  </h4>
                  <p data-scroll data-scroll-speed="1">LRC’s advanced technology allows us to operate efficiently and provide a smooth experience to landlords and tenants alike.</p>
                </div>
                <div className={styles.newTextGridItems}>
                  <h4
                    className="c-header_title o-h1"
                    data-scroll data-scroll-speed="1"
                  >
                    <span
                      className={classNames("c-header_title_line", {
                        "is-reveal": reveal,
                      })}
                    >

                      Scale


                    </span>
                  </h4>
                  <p data-scroll data-scroll-speed="1">LRC deploys significant capital to achieve scalable investment strategies, focusing on fast growth in key cities.</p>
                </div>
                <div className={styles.newTextGridItems}>
                  <h4
                    className="c-header_title o-h1"
                    data-scroll data-scroll-speed="1"
                  >
                    <span
                      className={classNames("c-header_title_line", {
                        "is-reveal": reveal,
                      })}
                    >

                      Sustainability


                    </span>
                  </h4>
                  <p data-scroll data-scroll-speed="1">LRC is committed to supporting sustainable practices that generate positive environmental and social impacts.</p>
                </div>
                <div className={styles.newTextGridItems}>
                  <h4
                    className="c-header_title o-h1"
                    data-scroll data-scroll-speed="1"
                  >
                    <span
                      className={classNames("c-header_title_line", {
                        "is-reveal": reveal,
                      })}
                    >

                      Communities


                    </span>
                  </h4>
                  <p data-scroll data-scroll-speed="1">LRC’s platforms are built around communities by integrating tenants with local businesses and neighbourhoods.</p>
                </div>
                <div className={styles.newTextGridItems}>
                  <h4
                    className="c-header_title o-h1"
                    data-scroll data-scroll-speed="1"
                  >
                    <span
                      className={classNames("c-header_title_line", {
                        "is-reveal": reveal,
                      })}
                    >

                      Wellbeing


                    </span>
                  </h4>
                  <p data-scroll data-scroll-speed="1">LRC partners with market leading specialist firms in the field to enhance tenants’ personal growth and wellbeing.</p>
                </div>
              </div>
              <div className="container">
                <div className={styles.mobileViewPioneeringButtonShow}>
                  <NavLink to="/urbanregeneration">
                    <button>
                      <span>Learn More</span>
                      <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.24473 0.412046C7.57016 0.0866095 8.0978 0.0866095 8.42324 0.412046L13.4232 5.41205C13.5795 5.56833 13.6673 5.78029 13.6673 6.0013C13.6673 6.22232 13.5795 6.43428 13.4232 6.59056L8.42324 11.5906C8.0978 11.916 7.57016 11.916 7.24473 11.5906C6.91929 11.2651 6.91929 10.7375 7.24473 10.412L10.8221 6.83464L1.16732 6.83464C0.70708 6.83464 0.333984 6.46154 0.333984 6.0013C0.333984 5.54107 0.70708 5.16797 1.16732 5.16797L10.8221 5.16797L7.24473 1.59056C6.91929 1.26512 6.91929 0.737483 7.24473 0.412046Z" fill="white" />
                      </svg>
                    </button>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
